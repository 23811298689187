import React from 'react';
import { Button, notification, Table } from 'antd';
import { userUseCoupon } from '../../../Components/Columns/service';

import { TableContainer, TableWrapper } from './useList.styled';

export default class UseCouponList extends React.PureComponent {

    // onRow = (row, index) => {
    //     return {
    //         onClick: () => {
    //             this.props.history.push({
    //                 pathname: `/coupon/info/${row._id}`,
    //                 state: {
    //                     id: row._id,
    //                 },
    //             });
    //         },
    //     };
    // };

    render() {
        // console.log(this.props.app.useCoupon);
        // console.log(this.props.app);
        return (
            <>
                <TableWrapper>
                    <TableContainer>
                        <Table
                            rowKey='id'
                            dataSource={this.props.app.useCoupon.data}
                            columns={userUseCoupon}
                            onRow={this.onRow}
                            pagination={{
                                pageSize: 10
                            }}
                        />

                        {/* <Button
                            type='primary'
                            onClick={() => {
                                this.props.history.push('/coupon/add');
                            }}
                        >
                            새 쿠폰 등록
                        </Button> */}
                    </TableContainer>
                </TableWrapper>
            </>
        );
    }
}
