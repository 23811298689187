import React from 'react';
import { connect } from 'react-redux';
import StyledListContainer from './list.styled';
import List from './list';
import Title from '../../../Components/Common/pageTitle';
import { getReserveList } from '../../../Reducer/Modules/service';

class ListContainer extends React.PureComponent {
    componentDidMount = () => {
        this.props.fetchList();
    };

    render() {
        return (
            <StyledListContainer>
                <Title title='해피케어 - 병원동행 서비스 신청목록' />
                <List {...this.props} />
            </StyledListContainer>
        );
    }
}

const mapStateToProps = ({ app, auth, service }) => ({
    app,
    service,
    auth,
});

const mapDispatchToProps = dispatch => ({
    fetchList: () => dispatch(getReserveList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ListContainer);
