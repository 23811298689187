import React from 'react';
import { connect } from 'react-redux';

// component
import List from './list';
import Title from '../../Components/Common/pageTitle';

// styles
import ListStyledContainer from './list.styled';

// actions
import { editApplyPage, getApplyList } from '../../Reducer/Modules/app';
import { deleteMateApply } from '../../Reducer/Modules/app';

class ListContainer extends React.PureComponent {
    componentDidMount = () => {
        this.props.fetchList();
    };

    render() {
        return (
            <ListStyledContainer>
                <Title title='메이트 신청서 목록' />
                <List {...this.props} />
            </ListStyledContainer>
        );
    }
}

const mapStateToProps = ({ app, auth }) => ({
    auth,
    app,
});

const mapStateToDispatch = dispatch => ({
    fetchList: () => dispatch(getApplyList()),
    editPage: (page) => dispatch(editApplyPage(page)),
    deleteMateApply: ({ applyId }) => dispatch(deleteMateApply({ applyId })),
});

export default connect(mapStateToProps, mapStateToDispatch)(ListContainer);
