import React from 'react';
import { Table, Input, Button, message } from 'antd';

// components
import { Container, Label, InputWrapper } from './create.styled';

// utils
import axios from '../../../Lib/axios';

class Create extends React.PureComponent {
    state = {
        addr1: '',
        addr2: '',
        addr3: '',
        name: '',
        manager: '',
        contact: null,
    };

    onAdd = async () => {
        const { addr1, addr2, addr3, name, contact, manager } = this.state;
        if (!addr1 || !addr2 || !name || !contact) {
            return message.info('필수값을 모두 입력해주세요.');
        }

        const { data } = await axios.post('/v1/corp/add', {
            addr1,
            addr2,
            addr3,
            name,
            manager,
            contact,
        });

        if (!data) {
            return message.error('서버에러');
        }

        if (data === 'check parameters') {
            return message.warning('입력값 오류');
        }

        if (data === 'success') {
            message.success('추가 완료');
            this.props.history.goBack();
        }
    };

    render() {
        const { addr1, addr2, addr3, name, manager, contact } = this.state;

        return (
            <Container>
                <InputWrapper>
                    <Label>주소 - 특별시/도*</Label>
                    <Input
                        typ='text'
                        value={addr1}
                        placeholder={'e.g 특별시/도'}
                        onChange={ev => {
                            this.setState({ addr1: ev.target.value });
                        }}
                    />
                </InputWrapper>

                <InputWrapper>
                    <Label>주소 - 시/군*</Label>
                    <Input
                        typ='text'
                        value={addr2}
                        placeholder={'e.g 시/군'}
                        onChange={ev => {
                            this.setState({ addr2: ev.target.value });
                        }}
                    />
                </InputWrapper>

                <InputWrapper>
                    <Label>기관명*</Label>
                    <Input
                        typ='text'
                        value={name}
                        placeholder={'기관명'}
                        onChange={ev => {
                            this.setState({ name: ev.target.value });
                        }}
                    />
                </InputWrapper>

                <InputWrapper>
                    <Label>대표 연락처*</Label>
                    <Input
                        typ='number'
                        value={contact}
                        placeholder={'대표 연락처'}
                        onChange={ev => {
                            this.setState({ contact: ev.target.value });
                        }}
                    />
                </InputWrapper>

                <InputWrapper>
                    <Label>주소 - 읍/면/동</Label>
                    <Input
                        typ='text'
                        value={addr3}
                        placeholder={'e.g 읍/면/동'}
                        onChange={ev => {
                            this.setState({ addr3: ev.target.value });
                        }}
                    />
                </InputWrapper>

                <InputWrapper>
                    <Label>담당자</Label>
                    <Input
                        typ='text'
                        value={manager}
                        placeholder={'담당자'}
                        onChange={ev => {
                            this.setState({ manager: ev.target.value });
                        }}
                    />
                </InputWrapper>
                <Button type='primary' onClick={this.onAdd}>
                    추가하기
                </Button>
            </Container>
        );
    }
}
export default Create;
