import React from 'react';
import { Input, message, notification, Table } from 'antd';
import { apply } from '../../Components/Columns/user';

export default class HomePage extends React.PureComponent {
    state = {
        keyword: '',
    };

    onRow = (row, index) => {
        return {
            onClick: () => {
                this.props.history.push({
                    pathname: `/apply/info`,
                    state: {
                        info: row,
                    },
                });
            },
        };
    };

    onChangePage = page => {
        // console.log(page);
        this.props.editPage(page);
    };

    onMateApplyDelete = ({ _id }) => {
        const { id } = this.props.auth;
        console.log('_id: ', _id);

        const confirmed = window.confirm('해당 메이트 지원서를 삭제하시겠습니까?');
        if (!confirmed) return null;

        this.props
            .deleteMateApply({ applyId: _id })
            .then(resolve => {
                if (resolve && resolve === 'success') {
                    notification.open({
                        message: '메이트 지원서를 삭제하였습니다.',
                        description: '메이트 지원서를 삭제하였습니다.',
                        type: 'success',
                    });
                    window.location.reload();
                }
            })
            .catch(error => {
                message.error('서버장애가 발생하였습니다. 관리자에게 문의해주세요.');
            });
    };

    getList = () => {
        // const { applyList } = this.props.app;
        const applyList = this.props.app.applyList;
        // console.log(applyList);
        const { keyword } = this.state;

        let list = [];

        if (Array.isArray(applyList)) {
            list = applyList.filter(
                item =>
                    (item && item.form && item.form.info[0] && item.form.info[0].value.toLowerCase().includes(keyword)) ||
                    (item && item.form && item.form.info[10] && item.form.info[10].value.toLowerCase().includes(keyword)) ||
                    (item && item.form && item.form.info[2] && item.form.info[2].value.toLowerCase().includes(keyword)),
            );
        }

        return list;
    };

    render() {
        const list = this.getList();
        console.log(this.props.app.applyList);
        return (
            <div className='container'>
                <div className='table'>
                    <Input
                        id='search-input'
                        value={this.state.keyword}
                        onChange={ev => {
                            const el = document.getElementById('search-input');
                            const event = new KeyboardEvent('keypress', { isTrusted: true, key: 'Enter', code: 'Enter', keyCode: 13 });

                            const res = el.dispatchEvent(event);

                            this.setState({ keyword: ev.target.value });
                        }}
                        placeholder='이름, 연락처, 이메일로 검색하세요.'
                        style={{ marginBottom: '24px' }}
                    />

                    <Table
                        rowKey='_id'
                        dataSource={list}
                        columns={apply({ onDelete: this.onMateApplyDelete })}
                        onRow={this.onRow}
                        pagination={{
                            pageSize: 10,
                            current: this.props.app.applyPage,
                            onChange: this.onChangePage,
                        }}
                        scroll={{ x: 600 }}
                    />
                </div>
            </div>
        );
    }
}
