import React from 'react';
import ReactJson from 'react-json-view';

class PaymentItem extends React.PureComponent {
    render() {
        const { serviceRequestInfo } = this.props.app;
        return (
            <div className='container'>
                <ReactJson src={serviceRequestInfo ? serviceRequestInfo[0] : ''} />
            </div>
        );
    }
}
export default PaymentItem;
