import React from 'react';
import { Switch, Route } from 'react-router-dom';

// pages
import Create from './Create';
import List from './List';
import Info from './Info';
import Subtract from './Subtract';

class Service extends React.PureComponent {
    render() {
        return (
            <Switch>
                <Route exact path='/point/create' component={Create} />
                <Route exact path='/point/subtract' component={Subtract} />
                <Route exact path='/point/list' component={List} />
                <Route exact path='/point/info' component={Info} />
            </Switch>
        );
    }
}
export default Service;
