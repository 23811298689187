import React from 'react';
import { withRouter } from 'react-router-dom';
const NotFound = ({ history }) => (
    <div
        style={{
            height: '100vh',
            padding: '100px',
        }}
    >
        <h1>Not found</h1>
        <p
            onClick={() => {
                history.push('/auth');
            }}
        >
            로그인 화면으로
        </p>
    </div>
);
export default withRouter(NotFound);
