import React from 'react';
import { connect } from 'react-redux';
import Title from '../../../../Components/Common/pageTitle';
import StyledTodoContainer from './todo.styled';
import Todo from './todo';
import { getUserList, editPagingUser, editNewReserve, serviceReserve, getMateList } from '../../../../Reducer/Modules/app';

class TodoContainer extends React.PureComponent {
    componentDidMount = () => {
        const { id } = this.props.auth;
        this.props.fetchList(id);
        this.props.fetchMateList(id);
    };

    render() {
        return (
            <StyledTodoContainer>
                <Title title='예약 대행' />
                <Todo {...this.props} />
            </StyledTodoContainer>
        );
    }
}

const mapStateToProps = ({ app, auth }) => ({
    app,
    auth,
});

const mapDispatchToProps = dispatch => ({
    fetchList: ({ page }) => dispatch(getUserList({ page })),
    fetchMateList: id => dispatch(getMateList({ id })),
    onClear: () => dispatch(editPagingUser()),
    onEdit: ({ type, value }) => dispatch(editNewReserve({ type, value })),
    onServiceReserve: ({ userId, info, mateId }) => dispatch(serviceReserve({ userId, info, mateId })),
});

export default connect(mapStateToProps, mapDispatchToProps)(TodoContainer);
