import styled from 'styled-components';
import { bgColor } from '../../theme';

const AuthStyledContainer = styled.div`
    .auth-container {
        display: flex;
        height: 100vh;
        width: 100vw;
        justify-content: center;
        align-items: center;

        .auth-innerContainer {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;

            .auth-form {
                box-shadow: 4px 12px 25px #dedede;

                .title {
                    padding: 20px 28px;
                    // background-color: ${bgColor};
                    text-align: center;

                    .logo {
                        width: 150px;
                        height: auto;
                        margin-top: 10px;
                        margin-bottom: 40px;
                    }

                    .login-title {
                        margin: 0px;
                        font-size: 34px;
                        font-weight: bold;
                        color: black;
                    }
                }

                .form-box {
                    background-color: white;
                    padding: 20px 28px;

                    .form-row {
                        margin-bottom: 16px;

                        label {
                            display: inline-block;
                            color: black;
                            width: 120px;
                            margin-bottom: 5px;
                        }

                        input {
                            display: inline-block;
                            width: 100%;
                            height: 40px;
                            border-radius: 10px;
                        }
                    }

                    .button-wrapper {
                        margin-top: 30px;

                        .login-btn {
                            margin-top: 4rem;
                            width: 100%;
                            height: 40px;
                            border-radius: 30px;
                            font-weight: bold;
                            font-size: 18px;
                        }
                    }
                }

                .alert {
                    color: red;
                    text-align: center;
                }
            }
        }
    }
`;

export default AuthStyledContainer;
