import React from 'react';
import { Input, Checkbox, Select, message, Button, Radio } from 'antd';
import Title from '../../../Components/Common/pageTitle';
import { TableWrapper, TableContainer, Label, ValueBox } from './info.styled';

import axios from '../../../Lib/axios';
import TextArea from 'antd/lib/input/TextArea';
import { onEditNewsEvent } from '../../../Lib/Api/app';

export default class NewsEventManage extends React.PureComponent {
    state = {
        data: {},
    };

    componentDidMount = () => {
        const { state } = this.props.history.location;
        if (!state || !state.id) return this.props.history.goBack();

        this.fetchInfo(state.id);
    };

    fetchInfo = async id => {
        const { data } = await axios.get('/v1/newsEvent/list/info', {
            params: {
                newsEventId: id,
            },
        });

        if (!data || data === 'check parameters') {
            return this.props.history.goBack();
        }

        if (data === 'invalid newEvent') {
            return message.warning('잘못된 게시물 정보입니다.');
        }

        this.setState({ ...data });
    };

    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    onUpdate = async () => {
        const { title, content, photo, url, url2, urlSource, urlSource2 } = this.state;
        if (!title) return message.warning('제목을 입력해주세요.');
        if (!content) return message.warning('내용을 입력해주세요.');

        let img = photo;
        if (typeof photo !== 'string') {
            const formData = new FormData();
            formData.append('photo', photo);
            const header = { headers: { 'Content-Type': 'multipart/form-data' } };
            const { data: image } = await axios.post('/v1/newsEvent/image/upload', formData, header);
            img = image;
        }

        const info = { title, content, photo: img, url, url2, urlSource, urlSource2 };

        onEditNewsEvent({ newsEventId: this.state._id, info })
            .then(resolve => {
                if (resolve && resolve.data === 'success') {
                    message.success('News & Event 업데이트 완료');
                    setTimeout(() => {
                        this.props.history.goBack();
                    }, 600);
                }
            })
            .catch(error => {
                message.error('서버장애가 발생하였습니다. 관리자에게 문의해주세요.');
            });
    };

    render() {
        return (
            <>
                <Title title='News & Event 정보' />
                <TableWrapper>
                    <TableContainer>
                        {this.state && (
                            <>
                                <ValueBox>
                                    <Label style={{ fontSize: '14px' }}>
                                        <b>제목</b>
                                    </Label>
                                    <Input placeholder='제목을 입력해주세요.' value={this.state.title} name='title' onChange={this.handleChange} />
                                </ValueBox>

                                <ValueBox>
                                    <Label style={{ fontSize: '14px' }}>
                                        <b>내용</b>
                                    </Label>
                                    <TextArea
                                        placeholder='내용을 입력해주세요.'
                                        value={this.state.content}
                                        name='content'
                                        onChange={this.handleChange}
                                        style={{ height: '200px' }}
                                    />
                                </ValueBox>

                                <ValueBox>
                                    <Label style={{ fontSize: '14px' }}>
                                        <b>사진</b>
                                    </Label>
                                    {this.state.photo && (
                                        <div>
                                            <img
                                                style={{ width: '300px', height: '300px', objectFit: 'contain' }}
                                                src={typeof this.state.photo === 'string' ? this.state.photo : URL.createObjectURL(this.state.photo)}
                                                alt='사진'
                                            />
                                        </div>
                                    )}
                                    <input
                                        type='file'
                                        accept='image/*'
                                        placeholder='사진을 추가해주세요.'
                                        name='photo'
                                        onChange={ev => {
                                            console.log(ev.target.files);
                                            this.setState({ photo: ev.target.files && ev.target.files.length > 0 ? ev.target.files[0] : '' });
                                        }}
                                    />
                                </ValueBox>

                                <ValueBox>
                                    <Label style={{ fontSize: '14px' }}>
                                        <b>URL</b>
                                    </Label>
                                    <Input
                                        placeholder='뉴스기사 출처를 입력해주세요.'
                                        value={this.state.urlSource}
                                        name='urlSource'
                                        onChange={this.handleChange}
                                    />
                                    <Input placeholder='https://를 제외한 URL을 입력해주세요.' value={this.state.url} name='url' onChange={this.handleChange} />
                                    <br />
                                    <br />
                                    <Input
                                        placeholder='뉴스기사 출처를 입력해주세요.'
                                        value={this.state.urlSource2}
                                        name='urlSource2'
                                        onChange={this.handleChange}
                                    />
                                    <Input
                                        placeholder='https://를 제외한 URL을 입력해주세요.'
                                        value={this.state.url2}
                                        name='url2'
                                        onChange={this.handleChange}
                                    />
                                </ValueBox>

                                <ValueBox>
                                    <Button type='primary' onClick={this.onUpdate}>
                                        현재 상태로 변경 및 저장
                                    </Button>
                                </ValueBox>
                            </>
                        )}
                    </TableContainer>
                </TableWrapper>
            </>
        );
    }
}
