import React from 'react';
import { Table, Input } from 'antd';
import { mate } from '../../../../Components/Columns/mate';

import { BlockWrapper } from './mateList.styled';

class MateList extends React.PureComponent {
    state = {
        keyword: '',
    };

    onRow = (row, index) => {
        return {
            onClick: () => {
                this.props.history.push({
                    pathname: `/user/mate/info/${row._id}`,
                    state: {
                        info: row,
                    },
                });
            },
        };
    };

    onChangePage = page => {
        // console.log(page);
        this.props.editPage(page);
    };

    getFilteredList = () => {
        const { mate } = this.props.app;
        const { keyword } = this.state;

        if (!mate) return [];

        return mate.filter(m => {
            if (m.name && keyword && m.name.toLowerCase().includes(keyword.toLowerCase())) return true;
            if (m.contact && keyword && m.contact.toLowerCase().includes(keyword.toLowerCase())) return true;
            return false;
        });
    };

    render() {
        const { keyword } = this.state;
        const filteredList = !keyword ? this.props.app.mate : this.getFilteredList();
        // console.log(filteredList);
        return (
            <div className='container'>
                <BlockWrapper>
                    <Input
                        placeholder='이름/연락처로 메이트를 검색해보세요.'
                        value={keyword}
                        onChange={ev => {
                            this.setState({ keyword: ev.target.value });
                        }}
                    />
                </BlockWrapper>
                <div className='table'>
                    <Table
                        rowKey='_id'
                        dataSource={filteredList}
                        columns={mate}
                        onRow={this.onRow}
                        pagination={{
                            pageSize: 10,
                            current: this.props.auth.matePage,
                            onChange: this.onChangePage,
                        }}
                        scroll={{ x: 550 }}
                    />
                </div>
            </div>
        );
    }
}
export default MateList;
