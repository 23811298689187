import styled from 'styled-components';

export const TableWrapper = styled.div`
    padding: 24px;
`;

export const TableContainer = styled.div`
    padding: 24px;
    background-color: #ffffff;
    border-radius: 4px;
`;

const StyledPointListContainer = styled.div`
`;


export default StyledPointListContainer;