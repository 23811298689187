import styled from 'styled-components';

export const TableWrapper = styled.div`
    padding: 24px;
`;

export const TableContainer = styled.div`
    padding: 24px;
    margin-bottom: 10px;
    background-color: #ffffff;
    border-radius: 4px;
`;

export const Label = styled.p`
    text-align: left;
    font-size: 11px;
    margin-bottom: 4px;
    color: #565656;
`;

export const ValueBox = styled.div`
    margin-bottom: 16px;
`;

export const Wrapper = styled.div`
    background-color: #ffffff;
    padding: 20px;

    // margin: 20px;

    > div {
        margin-bottom: 12px;
        padding-bottom: 12px;
        border-bottom: 1px solid #e9e9e9;

        img {
            width: 150px;
            object-fit: contain;
        }
    }
`;

export const FlexWrapper = styled(Wrapper)`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    > div:last-child {
        margin-right: 0px;
    }
`;

export const FlexBox = styled.div`
    flex: 1;
    margin-right: 20px;
    background-color: #ffffff;

    > input {
        margin-bottom: 8px;
    }
`;

const StyledMateApplyInfoContainer = styled.div``;

export default StyledMateApplyInfoContainer;
