import React from 'react';
import axios from '../../../../Lib/axios';
import _ from 'lodash';
import styled from 'styled-components';
import { Input, Button, Select, TimePicker, DatePicker, message, Checkbox } from 'antd';
import moment from 'moment';
import { InfoBlock } from '../../../../Components/Common/block';

import { UNEMER, ASSIST, HAPPY_CARE } from '../../../../Lib/variables';

import PatientProfileListModal from './patientListModal';
import ReserveListModal from './reserveListModal';
import { fetchPatient, fetchReserve } from '../../../../Lib/Api/app';
import { Label } from 'recharts';

const { Option } = Select;

const HAPPY_CARE_ADDRESS = '경기 고양시 일산동구 일산로 323 국립암센터';

class Todo extends React.PureComponent {
    state = {
        services: [],
        form: {},
        patientList: [],
        patientId: '',
        reserve: [],
        reserveId: '',
        reserveList: [],
    };

    patientProfileListModalOff = () => {
        this.setState({
            patientProfileListModal: false,
        });
    };

    reserveListModalOff = () => {
        this.setState({
            reserveListModal: false,
        });
    };

    componentDidMount = () => {
        this.fetchService();
    };

    fetchService = async () => {
        const { data } = await axios.get('/v1/product/service/list');
        if (data && Array.isArray(data)) {
            this.setState({ services: data });
        }
    };

    onReserve = async () => {
        const { buyerId, mateId, form } = this.state;
        console.log(buyerId, mateId);

        const {
            shortStarting,
            destination = '',
            shortDestination,
            comment,
            service,
            addTime,
            starting,
            contact,
            guardContact,
            reserveDate,
            meetDate,
            patient,
            relate,
            payment,
            sex,
            transport,
            product,
            environment,
            caution,
            cardName,
            cardNum,
            year,
            month,
            installment,
        } = form;

        if (!buyerId) return message.warning('사용자를 선택해주세요.');
        if (!product) return message.warning('서비스를 선택해주세요.');
        if (!service) return message.warning('세부 서비스 타입을  선택해주세요.');
        if (!sex) return message.warning('환자의 성별을 선택해주세요.');
        if (!addTime) return message.warning('예상 이용시간을 입력해주세요.');
        if (service === 'unemergency' && !transport) return message.warning('이동수단을 선택해주세요.');
        if (!destination) return message.warning('목적지를 입력해주세요.');
        if (service === 'unemergency' && !starting) return message.warning('자택 주소를 입력해주세요.');
        if (!contact) return message.warning('환자분의 연락처를 입력해주세요.');
        if (!guardContact) return message.warning('보호자분의 연락처를 입력해주세요.');
        if (!reserveDate) return message.warning('진료 일정을 설정해주세요,.');
        if (!meetDate) return message.warning('병원 도착 / 자택 도착 희망 시간을 설정해주세요');
        if (!patient) return message.warning('환자분의 성함을 입력해주세요');
        if (!relate) return message.warning('환자분과의 관계를 선택해주세요');
        if (!payment) return message.warning('결제 방법을 선택해주세요.');

        if (!environment) return message.warning('거주환경을 선택해주세요.');
        if (!caution) return message.warning('메이트 주의사항을 입력해주세요.');

        // 예약
        const { data } = await axios.post('/v2/service/reserve/new', {
            userId: buyerId,
            mateId,
            info: form,
        });

        if (!data) return message.error('서버 장애가 발생하였습니다. 잠시후 다시 시도해주세요.');
        if (data === 'check parameters') return message.warning('빠트린 정보가 없는지 확인해주세요.');
        if (data === 'invalid user') return message.warning('유효하지 않는 회원정보입니다.');
        if (data === 'invalid product') return message.warning('유효하지 않는 상품정보입니다.');
        if (data === 'over using point') return message.warning('보유 포인트 이상을 사용하려합니다');

        if (data === 'success') {
            message.success('예약 완료');
            setTimeout(() => {
                this.props.history.push('/service/list');
            }, 250);
        }
    };

    onReserve2 = async () => {
        const { buyerId, mateId, form } = this.state;
        console.log(buyerId, mateId);

        const {
            shortStarting,
            destination = '',
            shortDestination,
            comment,
            service,
            addTime,
            starting,
            contact,
            guardContact,
            reserveDate,
            meetDate,
            patient,
            relate,
            payment,
            sex,
            transport,
            product,
            environment,
            caution,
            cardName,
            cardNum,
            year,
            month,
            installment,
        } = form;

        if (!buyerId) return message.warning('사용자를 선택해주세요.');
        if (!product) return message.warning('서비스를 선택해주세요.');
        if (!service) return message.warning('세부 서비스 타입을  선택해주세요.');
        if (!sex) return message.warning('환자의 성별을 선택해주세요.');
        if (!addTime) return message.warning('예상 이용시간을 입력해주세요.');
        if (service === 'unemergency' && !transport) return message.warning('이동수단을 선택해주세요.');
        if (!destination) return message.warning('목적지를 입력해주세요.');
        if (service === 'unemergency' && !starting) return message.warning('자택 주소를 입력해주세요.');
        if (!contact) return message.warning('환자분의 연락처를 입력해주세요.');
        if (!guardContact) return message.warning('보호자분의 연락처를 입력해주세요.');
        if (!reserveDate) return message.warning('진료 일정을 설정해주세요,.');
        if (!meetDate) return message.warning('병원 도착 / 자택 도착 희망 시간을 설정해주세요');
        if (!patient) return message.warning('환자분의 성함을 입력해주세요');
        if (!relate) return message.warning('환자분과의 관계를 선택해주세요');
        if (!payment) return message.warning('결제 방법을 선택해주세요.');

        if (!environment) return message.warning('거주환경을 선택해주세요.');
        if (!caution) return message.warning('메이트 주의사항을 입력해주세요.');

        // 예약
        const { data } = await axios.post('/v2/service/reserve/mate/new', {
            userId: buyerId,
            mateId,
            info: form,
        });

        if (!data) return message.error('서버 장애가 발생하였습니다. 잠시후 다시 시도해주세요.');
        if (data === 'check parameters') return message.warning('빠트린 정보가 없는지 확인해주세요.');
        if (data === 'invalid user') return message.warning('유효하지 않는 회원정보입니다.');
        if (data === 'invalid product') return message.warning('유효하지 않는 상품정보입니다.');
        if (data === 'over using point') return message.warning('보유 포인트 이상을 사용하려합니다');

        if (data === 'success') {
            message.success('예약 완료');
            setTimeout(() => {
                this.props.history.push('/service/list');
            }, 250);
        }
    };

    onStarting = () => {
        new window.daum.Postcode({
            oncomplete: data => {
                const { sido, sigungu, bname, roadAddress, buildingName } = data;
                this.setState(prevState => ({
                    form: {
                        ...prevState['form'],
                        starting: `${roadAddress} (${buildingName})`,
                        shortStarting: `${sido} ${sigungu} ${bname}`,
                    },
                }));
            },
        }).open();
    };

    onDestination = () => {
        new window.daum.Postcode({
            oncomplete: data => {
                const { sido, sigungu, bname, roadAddress, buildingName } = data;
                this.setState(prevState => ({
                    form: {
                        ...prevState['form'],
                        destination: `${roadAddress} (${buildingName})`,
                        shortDestination: `${sido} ${sigungu} ${bname}`,
                    },
                }));
            },
        }).open();
    };

    handleChange = e => {
        const key = e.target.name;
        const value = e.target.value;
        this.setState(prevState => ({
            form: {
                ...prevState['form'],
                [key]: value,
            },
        }));
    };

    getFilteredList = () => {
        const { user } = this.props.app;
        const { keyword } = this.state;

        if (!user) return [];

        return user.filter(m => {
            if (m.name && keyword && m.name.toLowerCase().includes(keyword.toLowerCase())) return true;
            if (m.contact && keyword && m.contact.toLowerCase().includes(keyword.toLowerCase())) return true;
            return false;
        });
    };

    getFilteredMateList = () => {
        const { mate } = this.props.app;
        const { search } = this.state;

        if (!mate) return [];

        return mate.filter(m => {
            if (m.name && search && m.name.toLowerCase().includes(search.toLowerCase())) return true;
            if (m.contact && search && m.contact.toLowerCase().includes(search.toLowerCase())) return true;
            return false;
        });
    };

    componentDidUpdate = prevProps => {
        const { params: prevParams } = prevProps.match;
        const { params } = this.props.match;
        if (JSON.stringify(prevParams) !== JSON.stringify(params)) {
            this.setState({ step: params.step });
        }
    };

    onPrevSetting = () => {
        fetchPatient({ valid: true }).then(resolve => {
            // console.log(resolve)
            if (resolve && resolve.data && resolve.data.message === 'success') {
                this.setState({ patientList: resolve.data.data, patientProfileListModal: true });
            } else {
                message.info('조회되는 환자 목록이 없습니다.');
            }
        });
    };

    onPrevSetting2 = () => {
        fetchReserve({ valid: true }).then(resolve => {
            // console.log(resolve)
            if (resolve && resolve.data && resolve.data.message === 'success') {
                this.setState({ reserveList: resolve.data.data, reserveListModal: true });
            } else {
                message.info('조회되는 최근 예약목록이 없습니다.');
            }
        });
    };

    onUpdatePatient = data => {
        this.setState(prevState => ({
            form: {
                ...prevState['form'],
                patient: data.name,
                age: data.age,
                contact: data.contact,
                relate: data.relate,
                sex: data.sex,
                starting: data.starting,
                destination: '',
                environment: data.environment,
                caution: data.caution,
            },
        }));
        // console.log(data);
    };

    onUpdateReserve = data => {
        this.setState(prevState => ({
            form: {
                ...prevState['form'],
                patient: data.patient,
                age: data.age,
                contact: data.contact,
                guardContact: data.guardContact,
                relate: data.relate,
                sex: data.sex,
                starting: data.starting,
                destination: data.destination,
                environment: data.environment,
                caution: data.caution,
                payment: data.payment,
                cardName: data.cardName,
                cardNum: data.cardNum,
                year: data.year,
                month: data.month,
                installment: data.installment,
                comment: data.comment,
                service: data.service,
                mateId: data.mateId,
                buyerId: data.buyerId,
                product: data.productId,
            },
            mateId: data.mateId,
            buyerId: data.buyerId,
        }));
        // console.log(data);
    };

    render() {
        const { user, mate } = this.props.app;
        const { form, services, keyword = '', search = '', buyerName, mateName } = this.state;
        // console.log(this.props);
        // console.log('user: ', user);

        const filteredList = !keyword ? this.props.app.user : this.getFilteredList();
        const filteredMateList = !search ? this.props.app.mate : this.getFilteredMateList();

        return (
            <div className='reservation-container container'>
                <div className='table inner-container'>
                    <InfoBlock label='최근 예약목록'>
                        <UnderLineText onClick={this.onPrevSetting2}>최근 예약목록 불러오기</UnderLineText>
                        <ReserveListModal
                            list={this.state.reserveList}
                            visible={this.state.reserveListModal}
                            onClose={this.reserveListModalOff}
                            onUpdateReserve={this.onUpdateReserve}
                        />
                    </InfoBlock>

                    <InfoBlock label='사용자'>
                        <p className='selected-user'>
                            선택된 사용자 {'>'} <span>{buyerName || '선택안됨'}</span>
                        </p>
                        <Input
                            placeholder='이름/연락처로 사용자를 검색해보세요.'
                            value={keyword}
                            onChange={ev => {
                                this.setState({ keyword: ev.target.value });
                            }}
                        />
                        <div className='user-container'>
                            {!_.isEmpty(filteredList)
                                ? filteredList.map((item, i) => (
                                      <User
                                          active={item._id === form.buyerId}
                                          onClick={() => {
                                              this.setState({ buyerId: item._id, buyerName: item.name });
                                          }}
                                          key={i.toString()}
                                          name={item.name}
                                          phone={item.contact}
                                      />
                                  ))
                                : '-'}
                        </div>
                    </InfoBlock>

                    <InfoBlock label='메이트 지정'>
                        <p className='selected-user'>
                            선택된 메이트 {'>'} <span>{mateName || '선택안됨'}</span>
                        </p>
                        <Input
                            placeholder='이름/연락처로 메이트를 검색해보세요.'
                            value={search}
                            onChange={ev => {
                                this.setState({ search: ev.target.value });
                            }}
                        />
                        <div className='user-container'>
                            {!_.isEmpty(filteredMateList)
                                ? filteredMateList.map((m, i) => (
                                      <Mate
                                          active={m._id === form.mateId}
                                          onClick={() => {
                                              this.setState({ mateId: m._id, mateName: m.name });
                                          }}
                                          key={i.toString()}
                                          name={m.name}
                                          phone={m.contact}
                                      />
                                  ))
                                : '-'}
                        </div>
                    </InfoBlock>

                    <InfoBlock label='서비스'>
                        <Select
                            style={{ width: '100%' }}
                            placeholder='서비스를 먼저 지정해주세요.'
                            value={(form && form.product) || undefined}
                            onChange={value => {
                                const productIndex = services.findIndex(s => s.id === value);

                                this.setState(prevState => ({
                                    form: {
                                        ...prevState['form'],
                                        product: value,
                                        reservedDestination: services[productIndex].reservedDestination || '',
                                        destination: services[productIndex].reservedDestination || '',
                                        shortDestination: services[productIndex].reservedShortDestination || '',
                                        reservedStarting: services[productIndex].reservedStarting || '',
                                        starting: services[productIndex].reservedStarting || '',
                                        shortStarting: services[productIndex].reservedShortStarting || '',
                                    },
                                }));
                            }}
                        >
                            {services &&
                                services.length > 0 &&
                                services
                                    .filter(service => service.valid)
                                    .map(service => (
                                        <Select.Option value={service.id} key={service.id}>
                                            {service.name}
                                        </Select.Option>
                                    ))}
                        </Select>
                    </InfoBlock>

                    {form && form.product && (
                        <>
                            <InfoBlock label='서비스 타입'>
                                <Select
                                    style={{ width: '100%' }}
                                    value={form.service || undefined}
                                    placeholder='예약할 서비스 타입을 선택해주세요.'
                                    onChange={value => {
                                        this.setState(prevState => ({
                                            form: {
                                                ...prevState['form'],
                                                service: value,
                                            },
                                        }));
                                    }}
                                >
                                    <Select.Option value='assist'>Basic</Select.Option>
                                    <Select.Option value='unemergency'>Pro</Select.Option>
                                </Select>
                            </InfoBlock>
                            <InfoBlock label='환자 목록'>
                                <UnderLineText onClick={this.onPrevSetting}>환자 목록 불러오기</UnderLineText>
                                <PatientProfileListModal
                                    list={this.state.patientList}
                                    visible={this.state.patientProfileListModal}
                                    onClose={this.patientProfileListModalOff}
                                    onUpdatePatient={this.onUpdatePatient}
                                />
                            </InfoBlock>
                            <InfoBlock label='환자 성명'>
                                <Input onChange={this.handleChange} name='patient' value={form.patient || null} placeholder='이용자 성함' />
                            </InfoBlock>
                            <InfoBlock label='관계'>
                                <Select
                                    style={{ width: '100%' }}
                                    value={form.relate || undefined}
                                    placeholder='환자분과의 관계를 선택해주세요'
                                    onChange={value => {
                                        this.setState(prevState => ({
                                            form: {
                                                ...prevState['form'],
                                                relate: value,
                                            },
                                        }));
                                    }}
                                >
                                    <Option value='본인'>본인</Option>
                                    <Option value='부모'>부모</Option>
                                    <Option value='자매'>자매</Option>
                                    <Option value='친구'>친구</Option>
                                    <Option value='지인'>지인</Option>
                                    <Option value='기타'>기타</Option>
                                </Select>
                            </InfoBlock>
                            <InfoBlock label='보호자 연락처'>
                                <Input
                                    maxLength={14}
                                    value={form.guardContact || null}
                                    name='guardContact'
                                    onChange={this.handleChange}
                                    placeholder='01012341234'
                                />
                            </InfoBlock>
                            <InfoBlock label='연락처'>
                                <Input maxLength={14} value={form.contact || null} name='contact' onChange={this.handleChange} placeholder='01012341234' />
                            </InfoBlock>
                            <InfoBlock label='성별'>
                                <Select
                                    value={form.sex || undefined}
                                    placeholder='성별을 선택해주세요'
                                    onChange={value => {
                                        this.setState(prevState => ({
                                            form: {
                                                ...prevState['form'],
                                                sex: value,
                                            },
                                        }));
                                    }}
                                    style={{ width: '100%' }}
                                >
                                    <Option value='남자'>남자</Option>
                                    <Option value='여자'>여자</Option>
                                </Select>
                            </InfoBlock>
                            <InfoBlock label='도착지 (병원)'>
                                <Input
                                    disabled={form.reservedDestination || false}
                                    onClick={this.onDestination}
                                    value={form.destination || null}
                                    placeholder='ex) 분당구 황새울로 35'
                                />
                            </InfoBlock>
                            {form.service === 'unemergency' && (
                                <InfoBlock label='출발지 (자택)'>
                                    <Input
                                        disabled={form.service === HAPPY_CARE || form.reservedStarting || false}
                                        onClick={this.onStarting}
                                        value={form.starting || null}
                                        placeholder='ex) 분당구 황새울로 10'
                                    />
                                </InfoBlock>
                            )}
                            <InfoBlock label='병원진료 예약일자'>
                                <Input
                                    type='datetime-local'
                                    value={form.reserveDate || undefined}
                                    format='YYYY-MM-DD'
                                    style={{ width: '100%' }}
                                    onChange={e => {
                                        const date = e.target.value;
                                        this.setState(prevState => ({
                                            form: {
                                                ...prevState['form'],
                                                ['reserveDate']: date,
                                            },
                                        }));
                                    }}
                                />
                            </InfoBlock>
                            <InfoBlock label='병원도착 희망일자 / 자택도착 희망일자'>
                                <Input
                                    type='datetime-local'
                                    value={form.meetDate || undefined}
                                    format='YYYY-MM-DD'
                                    style={{ width: '100%' }}
                                    onChange={e => {
                                        const date = e.target.value;
                                        this.setState(prevState => ({
                                            form: {
                                                ...prevState['form'],
                                                ['meetDate']: date,
                                            },
                                        }));
                                    }}
                                />
                            </InfoBlock>
                            <InfoBlock label='예상 이용시간'>
                                <Input
                                    maxLength={3}
                                    name='addTime'
                                    onChange={this.handleChange}
                                    value={form.addTime || null}
                                    placeholder='3 (숫자만 입력해주세요)'
                                />
                            </InfoBlock>
                            <InfoBlock label='결제 수단'>
                                <Select
                                    value={form.payment || undefined}
                                    placeholder='결제 수단을 선택해주세요'
                                    style={{ width: '100%' }}
                                    onChange={value => {
                                        this.setState(prevState => ({
                                            form: {
                                                ...prevState['form'],
                                                payment: value,
                                            },
                                        }));
                                    }}
                                >
                                    <Option value='card'>카드결제</Option>
                                    <Option value='transfer'>계좌이체</Option>
                                </Select>
                            </InfoBlock>
                            {form.payment === 'card' ? (
                                <InfoBlock label='결제카드 정보'>
                                    <a>결제카드사</a>
                                    <Select
                                        name='cardName'
                                        placeholder='카드사를 선택하세요'
                                        value={form.cardName || undefined}
                                        style={{ width: '100%' }}
                                        onChange={value => {
                                            this.setState(prevState => ({
                                                form: {
                                                    ...prevState['form'],
                                                    cardName: value,
                                                },
                                            }));
                                        }}
                                    >
                                        <Option value='하나카드'>하나카드</Option>
                                        <Option value='KB국민카드'>KB국민카드</Option>
                                        <Option value='삼성카드'>삼성카드</Option>
                                        <Option value='현대카드'>현대카드</Option>
                                        <Option value='우리카드'>우리카드</Option>
                                        <Option value='BC카드'>BC카드</Option>
                                        <Option value='신한카드'>신한카드</Option>
                                        <Option value='롯데카드'>롯데카드</Option>
                                        <Option value='NH농협카드'>NH농협카드</Option>
                                        <Option value='씨티카드'>씨티카드</Option>
                                        <Option value='카카오뱅크카드'>카카오뱅크카드</Option>
                                        <Option value='토스뱅크카드'>토스뱅크카드</Option>
                                    </Select>

                                    <br />
                                    <br />
                                    <a>결제카드 번호</a>
                                    <Input name='cardNum' onChange={this.handleChange} value={form.cardNum || null} placeholder='카드번호를 입력하세요.' />
                                    <br />
                                    <br />
                                    <a>유효기간(MM/YY)</a>
                                    <div style={{ flex: 1 }}>
                                        <Input
                                            name='month'
                                            onChange={this.handleChange}
                                            value={form.month || null}
                                            placeholder='월'
                                            style={{ width: '100px', marginRight: '20px' }}
                                        />
                                        <Input name='year' onChange={this.handleChange} value={form.year || null} placeholder='년' style={{ width: '100px' }} />
                                    </div>
                                    <br />
                                    <a>유효기간(MM/YY)</a>
                                    <Select
                                        value={form.installment || undefined}
                                        placeholder='할부기간을 선택해주세요'
                                        style={{ width: '100%' }}
                                        onChange={value => {
                                            this.setState(prevState => ({
                                                form: {
                                                    ...prevState['form'],
                                                    installment: value,
                                                },
                                            }));
                                        }}
                                    >
                                        <Option value='일시불'>일시불</Option>
                                        <Option value='2개월'>2개월</Option>
                                        <Option value='3개월'>3개월</Option>
                                        <Option value='4개월'>4개월</Option>
                                        <Option value='5개월'>5개월</Option>
                                        <Option value='6개월'>6개월</Option>
                                        <Option value='7개월'>7개월</Option>
                                        <Option value='8개월'>8개월</Option>
                                        <Option value='9개월'>9개월</Option>
                                        <Option value='10개월'>10개월</Option>
                                        <Option value='11개월'>11개월</Option>
                                        <Option value='12개월'>12개월</Option>
                                    </Select>
                                </InfoBlock>
                            ) : null}
                            {form && (form.service === 'unemergency' || form.service === HAPPY_CARE) && (
                                <InfoBlock label='이동수단'>
                                    {form.service === HAPPY_CARE ? (
                                        <>
                                            <Checkbox
                                                checked={form && form.transport === 'withcar'}
                                                value='withcar'
                                                onChange={() => {
                                                    this.setState(prevState => ({
                                                        form: {
                                                            ...prevState['form'],
                                                            transport: 'withcar',
                                                        },
                                                    }));
                                                }}
                                            >
                                                차량호출
                                            </Checkbox>
                                        </>
                                    ) : (
                                        <>
                                            <Checkbox
                                                checked={form && form.transport === 'own'}
                                                value='own'
                                                onChange={() => {
                                                    this.setState(prevState => ({
                                                        form: {
                                                            ...prevState['form'],
                                                            transport: 'own',
                                                        },
                                                    }));
                                                }}
                                            >
                                                자차
                                            </Checkbox>
                                            <Checkbox
                                                checked={form && form.transport === 'common'}
                                                value='common'
                                                onChange={() => {
                                                    this.setState(prevState => ({
                                                        form: {
                                                            ...prevState['form'],
                                                            transport: 'common',
                                                        },
                                                    }));
                                                }}
                                            >
                                                대중교통
                                            </Checkbox>
                                        </>
                                    )}
                                </InfoBlock>
                            )}
                            <InfoBlock label='선호하는 메이트 성별'>
                                <>
                                    <Checkbox
                                        checked={form && form.mateGender === '남'}
                                        value='own'
                                        onChange={() => {
                                            this.setState(prevState => ({
                                                form: {
                                                    ...prevState['form'],
                                                    mateGender: '남',
                                                },
                                            }));
                                        }}
                                    >
                                        남
                                    </Checkbox>
                                    <Checkbox
                                        checked={form && form.mateGender === '녀'}
                                        value='common'
                                        onChange={() => {
                                            this.setState(prevState => ({
                                                form: {
                                                    ...prevState['form'],
                                                    mateGender: '녀',
                                                },
                                            }));
                                        }}
                                    >
                                        녀
                                    </Checkbox>
                                    <Checkbox
                                        checked={form && form.mateGender === ''}
                                        value='common'
                                        onChange={() => {
                                            this.setState(prevState => ({
                                                form: {
                                                    ...prevState['form'],
                                                    mateGender: '',
                                                },
                                            }));
                                        }}
                                    >
                                        상관없음
                                    </Checkbox>
                                </>
                            </InfoBlock>
                            <InfoBlock label='사용할 포인트'>
                                <Input
                                    type='number'
                                    maxLength={5}
                                    name='usingPoint'
                                    onChange={this.handleChange}
                                    value={form.usingPoint || null}
                                    placeholder='3 (숫자만 입력해주세요)'
                                />
                            </InfoBlock>
                            <InfoBlock label='거주 환경(계단이용 여부)'>
                                <>
                                    <Checkbox
                                        checked={form && form.environment === '네'}
                                        value='네'
                                        onChange={() => {
                                            this.setState(prevState => ({
                                                form: {
                                                    ...prevState['form'],
                                                    environment: '네',
                                                },
                                            }));
                                        }}
                                    >
                                        네
                                    </Checkbox>
                                    <Checkbox
                                        checked={form && form.environment === '아니요, 엘레베이터가 있어요'}
                                        value='아니요, 엘레베이터가 있어요'
                                        onChange={() => {
                                            this.setState(prevState => ({
                                                form: {
                                                    ...prevState['form'],
                                                    environment: '아니요, 엘레베이터가 있어요',
                                                },
                                            }));
                                        }}
                                    >
                                        아니요, 엘레베이터가 있어요
                                    </Checkbox>
                                    <Checkbox
                                        checked={form && form.environment === '1층이라 괜찮아요'}
                                        value='1층이라 괜찮아요'
                                        onChange={() => {
                                            this.setState(prevState => ({
                                                form: {
                                                    ...prevState['form'],
                                                    environment: '1층이라 괜찮아요',
                                                },
                                            }));
                                        }}
                                    >
                                        1층이라 괜찮아요
                                    </Checkbox>
                                </>
                            </InfoBlock>
                            <InfoBlock label='메이트 주의사항'>
                                <Input name='caution' onChange={this.handleChange} value={form.caution || null} placeholder='메이트 주의사항을 입력해주세요' />
                            </InfoBlock>
                            <InfoBlock label='요청사항'>
                                <Input.TextArea
                                    type='text'
                                    placeholder='요청사항을 입력해주세요'
                                    name='comment'
                                    value={form && form.comment ? form.comment : null}
                                    onChange={this.handleChange}
                                />
                            </InfoBlock>
                            {/* width: 100% */}
                            &nbsp;
                            {mateName ? (
                                <>
                                    <Button type='primary' style={{ width: '100%', backgroundColor: '#565656' }} onClick={this.onReserve2}>
                                        예약하기 - 메이트 지정
                                    </Button>
                                </>
                            ) : (
                                <Button type='primary' style={{ width: '100%', backgroundColor: '#565656' }} onClick={this.onReserve}>
                                    예약하기
                                </Button>
                            )}
                        </>
                    )}
                </div>
            </div>
        );
    }
}

const User = ({ name, phone, onClick, active }) => (
    <div className={`user-wrapper ${active ? 'active' : undefined}`} onClick={onClick}>
        <p>
            {name} ({phone})
        </p>
    </div>
);

const Mate = ({ name, phone, onClick, active }) => (
    <div className={`user-wrapper ${active ? 'active' : undefined}`} onClick={onClick}>
        <p>
            {name} ({phone})
        </p>
    </div>
);

export default Todo;

const UnderLineText = styled.span`
    cursor: pointer;

    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.42;
    letter-spacing: -0.1px;
    text-align: left;
    color: #3bc1ff;

    text-decoration: underline;
`;
