import styled from 'styled-components';

export const InfoContainer = styled.div`
    background-color: #f1f1f1;

    p {
        margin-bottom: 4px;
    }
`;

export const Wrapper = styled.div`
    background-color: #ffffff;
    padding: 20px;

    margin: 20px;

    > div {
        margin-bottom: 12px;
        padding-bottom: 12px;
        border-bottom: 1px solid #e9e9e9;

        img {
            width: 150px;
            object-fit: contain;
        }
    }
`;

export const FlexWrapper = styled(Wrapper)`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    > div:last-child {
        margin-right: 0px;
    }
`;

export const FlexBox = styled.div`
    flex: 1;
    margin-right: 20px;
    background-color: #ffffff;

    > input {
        margin-bottom: 8px;
    }
`;
