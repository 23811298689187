import React from 'react';
import axios from '../../../Lib/axios';
import { Button, Input, Typography, notification } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

export default class RegisterPage extends React.PureComponent {
    state = {
        showPassword: false,
        email: '',
        password: '',
    };

    componentDidMount() {}

    toggleShowPassword = () => {
        this.setState({ showPassword: !this.state.showPassword });
    };

    handleClick = e => {
        this.props.history.push('/auth');
    };

    handleChange = e => {
        this.setState({ [e.target.title]: e.target.value });
    };

    onRegister = async () => {
        const { email, password, confirmPassword, name } = this.state;

        if (password !== confirmPassword) {
            notification.error({
                message: '비밀번호 불일치',
                description: '비밀번호와 확인 비밀번호가 일치하지 않습니다. 다시 확인해주세요.',
            });
            return;
        }

        try {
            const { data } = await axios.post('/v1/admin/create', {
                email,
                password,
                name,
            });

            console.log(data);

            if (data.message === 'success') {
                notification.open({
                    message: '회원가입 성공',
                    description: '위드메이트 관리자 계정이 생성되었습니다.',
                    type: 'success',
                });
                setTimeout(() => {
                    window.location.href = '/auth';
                }, 2000);
            }
        } catch (error) {
            console.error('회원가입 에러:', error.message);
        }
    };

    render() {
        return (
            <div className='auth-container'>
                <div className='auth-innerContainer'>
                    <div className='auth-form'>
                        <Typography className='title'>
                            <img className='logo' src='https://s3.ap-northeast-2.amazonaws.com/withmate.photo/withmate_new_logo.png' alt='logo' />
                            <p className='login-title'>회원가입</p>
                        </Typography>
                        <div className='form-box'>
                            <div className='form-row'>
                                <label>이메일</label>
                                <Input title='email' value={this.state.email} autoComplete='on' autoSave='on' onChange={this.handleChange} />
                            </div>

                            <div className='form-row'>
                                <label>비밀번호</label>
                                <Input.Password
                                    type='password'
                                    title='password'
                                    value={this.state.password}
                                    autoComplete='on'
                                    autoSave='on'
                                    iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                    onChange={this.handleChange}
                                />
                            </div>

                            <div className='form-row'>
                                <label>비밀번호 확인</label>
                                <Input.Password
                                    type='password'
                                    title='confirmPassword'
                                    value={this.state.confirmPassword}
                                    autoComplete='on'
                                    autoSave='on'
                                    iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                    onChange={this.handleChange}
                                />
                            </div>

                            <div className='form-row'>
                                <label>이름</label>
                                <Input title='name' value={this.state.name} autoComplete='on' autoSave='on' onChange={this.handleChange} />
                            </div>

                            <div className='form-bottom'>
                                <a className='forgot-pw' onClick={this.handleClick} style={{ color: 'red', fontSize: '15px' }}>
                                    이미 계정이 있으신가요?
                                </a>
                            </div>

                            <div className='button-wrapper'>
                                <Button className='login-btn' onClick={this.onRegister}>
                                    가입하기
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
