import React from 'react';
import moment from 'moment';
import { Button, Modal, Input, message, Select, notification } from 'antd';
import _ from 'lodash';
import axios from '../../../../Lib/axios';
import { InfoBlock, InfoBlock_ } from '../../../../Components/Common/block';

import { modifyReserve } from '../../../../Lib/Api/app';

const { Option } = Select;

class ServiceItem extends React.PureComponent {
    state = {
        form: {},
        services: [],
        modal: false,
        refundInfo: {},
        rewardModal: false,
        rewardInfo: {},
        mateModal: false,
        mateInfo: {},
        modifyReserveModal: false,
        reserveInfo: {},
        userInfo: {},
    };

    onExecute = status => {
        const { reserveInfo } = this.props.app;
        if (status !== 'refund') {
            this.props.onExecute({ callId: reserveInfo.callId, status });
        } else {
            const { refundInfo } = this.state;
            if (!refundInfo.price) return null;

            this.props.onExecute({ callId: reserveInfo.callId, status, refundInfo });
        }
    };

    onReward = () => {
        const { reserveInfo } = this.props.app;
        const { rewardInfo } = this.state;

        if (rewardInfo.price) return null;
        this.props.onReward({ callId: reserveInfo.callId, info: rewardInfo });
    };

    onPaid = () => {
        const { reserveInfo } = this.props.app;
        this.props.onPaid({ reserveId: reserveInfo._id, callId: reserveInfo.callId, status: reserveInfo.paidStatus === true ? false : true });
    };

    onCheckCallStatus = () => {
        const { reserveInfo } = this.props.app;

        if (!reserveInfo.call) return false;
        if (!reserveInfo.call.mateId) return false;
        if (!reserveInfo.call.status) return false;
        if (
            reserveInfo.call.status === '접수대기' ||
            reserveInfo.call.status === '예약접수' ||
            reserveInfo.call.status === '접수취소' ||
            reserveInfo.call.status === '서비스 종료'
        )
            return false;

        return true;
    };

    onResendMessage = async status => {
        const valid = this.onCheckCallStatus();
        if (!valid) return message.warning('메시지 전송이 불가한 상태입니다. (ex. 메이트 지정안됨 / 접수대기, 예약접수, 접수취소, 서비스 종료 상태)');

        const { reserveInfo } = this.props.app;
        const { _id, callId, call, buyerId } = reserveInfo;
        const { data } = await axios.post('/v1/admin/resend/mate/process', {
            reserveId: _id,
            callId,
            mateId: call.mateId,
            userId: buyerId,
            status,
        });

        if (data === 'wrong request') {
            message.warning('잘못된 요청입니다.');
        }

        if (data === 'success') {
            message.success(`"${status}" 메시지 전송 완료`);
        }
    };

    onCancelByWithmate = () => {
        const {
            reserveInfo: { _id, callId },
        } = this.props.app;

        axios
            .post('/v2/service/cancel/reseve/by/admin', {
                reserveId: _id,
                callId,
            })
            .then(resolve => {
                const { data } = resolve;
                if (!data) return message.error('요청 실패');
                else if (data === 'wrong request') return message.warning('잘못된 요청입니다.');
                else if (data === 'already cancel') return message.warning('접수 취소된 요청입니다.');
                else if (data === 'already done') return message.warning('서비스 종료된 요청입니다.');
                else if (data === 'success') {
                    message.success('취소 완료');
                    setTimeout(() => {
                        this.props.history.goBack();
                    }, 250);
                    return null;
                }
            });
    };

    // 메이트 수락 - 사용자 요청(보호자 대신 수락)
    onAllowMate = () => {
        const {
            reserveInfo: { _id, callId, mateId },
        } = this.props.app;
        console.log(_id, callId, mateId);

        axios.put('/v2/service/allow/mate/call', { reserveId: _id, callId, mateId }).then(resolve => {
            const { data } = resolve;
            if (!data) return message.error('요청 실패');
            else if (data === 'wrong request') return message.warning('잘못된 요청입니다.');
            else if (data === 'already cancel') return message.warning('접수 취소된 요청입니다.');
            else if (data === 'already done') return message.warning('서비스 종료된 요청입니다.');
            else if (data === 'wrong status') return message.warning('이미 매칭 완료된 요청입니다.');
            else if (data === 'success') {
                message.success('메이트 수락 요청 완료');
                setTimeout(() => {
                    this.props.history.goBack();
                }, 250);
                return null;
            }
        });
    };

    // 메이트에게 재매칭 요청
    onRematchMate = () => {
        const {
            reserveInfo: { _id, callId },
        } = this.props.app;

        axios.post('/v2/service/rematch/mate', { reserveId: _id, callId }).then(resolve => {
            const { data } = resolve;
            if (!data) return message.error('요청 실패');
            else if (data === 'wrong request') return message.warning('잘못된 요청입니다.');
            else if (data === 'already cancel') return message.warning('접수 취소된 요청입니다.');
            else if (data === 'already done') return message.warning('서비스 종료된 요청입니다.');
            else if (data === 'success') {
                message.success('재매칭 요청 완료');
                setTimeout(() => {
                    this.props.history.goBack();
                }, 250);
                return null;
            }
        });
    };

    onRematchByMate = () => {
        const {
            reserveInfo: { _id, callId },
        } = this.props.app;

        axios
            .post('/v2/service/cancel/reseve/by/mate', {
                reserveId: _id,
                callId,
            })
            .then(resolve => {
                const { data } = resolve;
                if (!data) return message.error('요청 실패');
                else if (data === 'wrong request') return message.warning('잘못된 요청입니다.');
                else if (data === 'already cancel') return message.warning('접수 취소된 요청입니다.');
                else if (data === 'already done') return message.warning('서비스 종료된 요청입니다.');
                else if (data === 'success') {
                    message.success('재매칭 요청 완료');
                    setTimeout(() => {
                        this.props.history.goBack();
                    }, 250);
                    return null;
                }
            });
    };

    onRematchByUser = () => {
        const {
            reserveInfo: { _id, callId },
        } = this.props.app;

        axios
            .post('/v2/service/cancel/reseve/by/user', {
                reserveId: _id,
                callId,
            })
            .then(resolve => {
                const { data } = resolve;
                console.log(data);
                if (!data) return message.error('요청 실패');
                else if (data === 'wrong request') return message.warning('잘못된 요청입니다.');
                else if (data === 'already cancel') return message.warning('접수 취소된 요청입니다.');
                else if (data === 'already done') return message.warning('서비스 종료된 요청입니다.');
                else if (data === 'success') {
                    message.success('재매칭 요청 완료');
                    setTimeout(() => {
                        this.props.history.goBack();
                    }, 250);
                    return null;
                }
            });
    };

    onCancelMeet = () => {
        const {
            reserveInfo: { _id, callId, status },
        } = this.props.app;
        console.log(status);

        axios
            .post('/v2/service/cancel/meet', {
                reserveId: _id,
                callId,
                status,
            })
            .then(resolve => {
                const { data } = resolve;
                if (!data) return message.error('요청 실패');
                else if (data === 'already cancel meet') return message.warning('이미 접선이 취소되었습니다.');
                else if (data === 'wrong request') return message.warning('잘못된 요청입니다.');
                else if (data === 'already cancel') return message.warning('접수 취소된 요청입니다.');
                else if (data === 'already done') return message.warning('서비스 종료된 요청입니다.');
                else if (data === 'success') {
                    message.success('접선 취소 완료');
                    setTimeout(() => {
                        this.props.history.goBack();
                    }, 250);
                    return null;
                }
            });
    };

    onCancelReserve = () => {
        const {
            reserveInfo: { _id, callId },
        } = this.props.app;

        axios
            .put('/v2/service/cancel/reserve/by/user', {
                reserveId: _id,
                callId,
            })
            .then(resolve => {
                const { data } = resolve;
                if (!data) return message.error('요청 실패');
                else if (data === 'wrong request') return message.warning('잘못된 요청입니다.');
                else if (data === 'already cancel') return message.warning('접수 취소된 요청입니다.');
                else if (data === 'already done') return message.warning('서비스 종료된 요청입니다.');
                else if (data === 'success') {
                    message.success('취소 처리 완료');
                    setTimeout(() => {
                        this.props.history.goBack();
                    }, 250);
                    return null;
                }
            });
    };

    prevStep = () => {
        this.props.history.goBack();
    };

    componentDidMount = () => {
        const { id } = this.props.match.params;

        if (id !== undefined) {
            this.fetchInfo();
        }

        this.fetchService();
    };

    fetchInfo = async () => {
        const { id } = this.props.match.params;

        if (id === undefined) {
            return;
        }

        try {
            const a = await axios.get('/v1/admin/service/reserve/info', { params: { reserveId: id } });
            const { data: mate } = await axios.get('/v2/user/mate/info/detail', { params: { mateId: a.data.mateId } });
            this.setState({ review: mate.review });
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    fetchService = async () => {
        const { data } = await axios.get('/v1/product/service/list');
        if (data && Array.isArray(data)) {
            this.setState({ services: data });
        }
    };

    onEdit = e => {
        const type = e.target.name;
        const value = e.target.value;
        this.setState(prevState => ({
            form: {
                ...prevState.form,
                [type]: value,
            },
        }));
    };

    onModify = async () => {
        const {
            reserveInfo: { _id },
        } = this.props.app;
        const { form, reserveId } = this.state;
        const { service, productId, patient, guardContact, contact, reserveDate, meetDate } = form;

        const info = { service, productId, patient, guardContact, contact, reserveDate, meetDate };

        modifyReserve({ reserveId: _id, info }).then(resolve => {
            if (resolve && resolve.data === 'success') {
                notification.open({
                    message: '예약내역 수정완료',
                    description: '예약내역을 수정하였습니다.',
                    type: 'success',
                });
                window.location.reload().catch(error => {
                    message.error('서버장애가 발생하였습니다. 관리자에게 문의해주세요.');
                });
            }
        });
    };

    render() {
        // const { state } = this.props.location;
        const { reserveInfo } = this.props.app;
        // console.log(reserveInfo);
        const { mateInfo, form, services, review } = this.state;
        const status = reserveInfo && !_.isEmpty(reserveInfo.call) ? reserveInfo.call.status : '-';

        if (_.isEmpty(reserveInfo.call) || _.isEmpty(reserveInfo.call.reserveId))
            return (
                <div className='container'>
                    <div>
                        <p>업데이트 전 발생한 비정상적인 접수내역입니다.(~ 2021.2.10)</p>
                    </div>
                </div>
            );

        return (
            <div className='container'>
                {
                    <div className='table'>
                        <Button type='light' onClick={this.prevStep} style={{ float: 'right', marginRight: '10px' }}>
                            목록으로
                        </Button>
                        {status === '예약완료' || '접수대기' || '예약접수' ? (
                            <>
                                <Button
                                    type='light'
                                    onClick={() => {
                                        this.setState({ modifyReserveModal: true });
                                    }}
                                    style={{ float: 'right', marginRight: '10px' }}
                                >
                                    수정하기
                                </Button>
                            </>
                        ) : null}
                        <InfoBlock label='예약번호' value={reserveInfo && reserveInfo.reserveCode ? reserveInfo.reserveCode : '-'} />
                        <InfoBlock label='진행상태' value={status} />
                        <InfoBlock
                            label='서비스 타입'
                            value={
                                reserveInfo.productInfo && reserveInfo.productInfo.name
                                    ? `${reserveInfo.productInfo.name} - ${reserveInfo.service === 'assist' ? 'Basic' : 'Pro'}`
                                    : '-'
                            }
                        />
                        {/* <InfoBlock label='담당 메이트'>
                            <label
                                style={{ color: 'black', paddingTop: '10px' }}
                                onClick={() => {
                                    this.setState({ mateModal: true });
                                }}
                            >
                                {_.isEmpty(reserveInfo.call.mate) ? '-' : reserveInfo.call.mate.name}
                            </label>
                        </InfoBlock>
                        <InfoBlock label='담당 메이트 연락처' value={_.isEmpty(reserveInfo.call.mate) ? '-' : reserveInfo.call.mate.contact} /> */}

                        <p style={{ fontWeight: 'bold' }}>메이트 정보</p>
                        <div style={{ display: 'flex', paddingBottom: '20px' }}>
                            <div style={{ flex: 1, paddingBottom: '10px', marginBottom: '10px', borderBottom: '1px solid #e6e6e6' }}>
                                <p style={{ margin: 0, padding: 0, marginBottom: '8px', color: 'rgba(0,0,0,0.4)', fontSize: '10px' }}>담당 메이트</p>
                                <label
                                    style={{ color: 'black', paddingTop: '10px' }}
                                    onClick={() => {
                                        this.setState({ mateModal: true });
                                    }}
                                >
                                    {_.isEmpty(reserveInfo.call.mate) ? '-' : reserveInfo.call.mate.name}
                                </label>
                            </div>
                            <div style={{ flex: 1, paddingBottom: '10px', marginBottom: '10px', borderBottom: '1px solid #e6e6e6' }}>
                                <p style={{ margin: 0, padding: 0, marginBottom: '8px', color: 'rgba(0,0,0,0.4)', fontSize: '10px' }}>담당 메이트 연락처</p>
                                <p style={{ margin: 0, padding: 0, color: 'rgba(0,0,0,0.87)', fontSize: '14px' }}>
                                    {_.isEmpty(reserveInfo.call.mate) ? '-' : reserveInfo.call.mate.contact}
                                </p>
                            </div>
                        </div>

                        <p style={{ fontWeight: 'bold' }}>예약자(보호자) 정보</p>
                        <div style={{ display: 'flex', paddingBottom: '20px' }}>
                            <div style={{ flex: 1, paddingBottom: '10px', marginBottom: '10px', borderBottom: '1px solid #e6e6e6' }}>
                                <p style={{ margin: 0, padding: 0, marginBottom: '8px', color: 'rgba(0,0,0,0.4)', fontSize: '10px' }}>예약자 성명</p>
                                <p style={{ margin: 0, padding: 0, color: 'rgba(0,0,0,0.87)', fontSize: '14px' }}>{reserveInfo.user.name}</p>
                            </div>
                            <div style={{ flex: 1, paddingBottom: '10px', marginBottom: '10px', borderBottom: '1px solid #e6e6e6' }}>
                                <p style={{ margin: 0, padding: 0, marginBottom: '8px', color: 'rgba(0,0,0,0.4)', fontSize: '10px' }}>보호자 연락처</p>
                                <p style={{ margin: 0, padding: 0, color: 'rgba(0,0,0,0.87)', fontSize: '14px' }}>{reserveInfo.guardContact}</p>
                            </div>
                        </div>
                        {/* <InfoBlock label='예약자 성명' value={reserveInfo.user.name} />
                        <InfoBlock label='보호자 연락처' value={reserveInfo.guardContact || '-'} /> */}
                        {/* <InfoBlock label='환자' value={reserveInfo.patient} />
                        <InfoBlock label='환자 나이' value={reserveInfo.age || '-'} /> */}
                        <p style={{ fontWeight: 'bold' }}>환자 정보</p>
                        <div style={{ display: 'flex' }}>
                            <div style={{ flex: 1, paddingBottom: '10px', marginBottom: '10px', borderBottom: '1px solid #e6e6e6' }}>
                                <p style={{ margin: 0, padding: 0, marginBottom: '8px', color: 'rgba(0,0,0,0.4)', fontSize: '10px' }}>환자</p>
                                <p style={{ margin: 0, padding: 0, color: 'rgba(0,0,0,0.87)', fontSize: '14px' }}>{reserveInfo.patient}</p>
                            </div>
                            <div style={{ flex: 1, paddingBottom: '10px', marginBottom: '10px', borderBottom: '1px solid #e6e6e6' }}>
                                <p style={{ margin: 0, padding: 0, marginBottom: '8px', color: 'rgba(0,0,0,0.4)', fontSize: '10px' }}>성별</p>
                                <p style={{ margin: 0, padding: 0, color: 'rgba(0,0,0,0.87)', fontSize: '14px' }}>{reserveInfo.sex}</p>
                            </div>
                            <div style={{ flex: 1, paddingBottom: '10px', marginBottom: '10px', borderBottom: '1px solid #e6e6e6' }}>
                                <p style={{ margin: 0, padding: 0, marginBottom: '8px', color: 'rgba(0,0,0,0.4)', fontSize: '10px' }}>나이</p>
                                <p style={{ margin: 0, padding: 0, color: 'rgba(0,0,0,0.87)', fontSize: '14px' }}>{reserveInfo.age}</p>
                            </div>
                        </div>

                        <InfoBlock label='환자 연락처 / (구)예약자 연락처' value={reserveInfo.contact} />
                        {/* <InfoBlock label='성별' value={reserveInfo.sex || '-'} /> */}

                        <p style={{ fontWeight: 'bold' }}>예약 정보</p>
                        <InfoBlock label='총 예약시간' value={reserveInfo.usageTime + '시간'} />
                        <InfoBlock label='병원위치' value={reserveInfo.destination} />
                        <InfoBlock label='자택주소' value={reserveInfo.starting || '-'} />
                        <InfoBlock label='진료 예약 일자' value={moment(reserveInfo.reserveDate).format('YYYY. MM. DD - HH:mm A')} />
                        <InfoBlock label='도착 희망 일자' value={reserveInfo.meetDate ? moment(reserveInfo.meetDate).format('YYYY. MM. DD - HH:mm A') : '-'} />
                        <InfoBlock label='선호 메이트 성별' value={(reserveInfo.call && reserveInfo.call.mateGender) || '-'} />
                        <InfoBlock
                            label='이동 수단'
                            value={reserveInfo.transport === 'withcar' ? '차량호출' : reserveInfo.transport === 'own' ? '자차 이용' : '대중교통 이용'}
                        />

                        <InfoBlock label='거주 환경(계단이용 여부)' value={(reserveInfo.environment && reserveInfo.environment) || '-'} />
                        <InfoBlock label='메이트 주의사항' value={(reserveInfo.caution && reserveInfo.caution) || '-'} />
                        <InfoBlock label='요청사항' value={reserveInfo.comment || '-'} />

                        <InfoBlock label='서비스 타임라인'>
                            {reserveInfo.call.mate && reserveInfo.call.mate._id && reserveInfo.call.timeline && reserveInfo.call.timeline.receipt && (
                                <p>접선시각: {moment(reserveInfo.call.timeline.receipt.time).format('YY.MM.DD, HH시 mm분 ss')}</p>
                            )}
                            {reserveInfo.call.mate && reserveInfo.call.mate._id && reserveInfo.call.timeline && reserveInfo.call.timeline.start && (
                                <p>병원출발: {moment(reserveInfo.call.timeline.start.time).format('YY.MM.DD, HH시 mm분 ss')}</p>
                            )}
                            {reserveInfo.call.mate && reserveInfo.call.mate._id && reserveInfo.call.timeline && reserveInfo.call.timeline.arrived && (
                                <p>병원도착: {moment(reserveInfo.call.timeline.arrived.time).format('YY.MM.DD, HH시 mm분 ss')}</p>
                            )}
                            {reserveInfo.call.mate && reserveInfo.call.mate._id && reserveInfo.call.timeline && reserveInfo.call.timeline.careStart && (
                                <p>진료시작: {moment(reserveInfo.call.timeline.careStart.time).format('YY.MM.DD, HH시 mm분 ss')}</p>
                            )}
                            {reserveInfo.call.mate && reserveInfo.call.mate._id && reserveInfo.call.timeline && reserveInfo.call.timeline.careDone && (
                                <p>진료종료: {moment(reserveInfo.call.timeline.careDone.time).format('YY.MM.DD, HH시 mm분 ss')}</p>
                            )}
                            {reserveInfo.call.mate && reserveInfo.call.mate._id && reserveInfo.call.timeline && reserveInfo.call.timeline.treatmentStart && (
                                <p>치료시작: {moment(reserveInfo.call.timeline.treatmentStart.time).format('YY.MM.DD, HH시 mm분 ss')}</p>
                            )}
                            {reserveInfo.call.mate && reserveInfo.call.mate._id && reserveInfo.call.timeline && reserveInfo.call.timeline.treatmentDone && (
                                <p>치료종료: {moment(reserveInfo.call.timeline.treatmentDone.time).format('YY.MM.DD, HH시 mm분 ss')}</p>
                            )}

                            {/* 진료/치료(두 번째) */}
                            {reserveInfo.call.mate && reserveInfo.call.mate._id && reserveInfo.call.timeline && reserveInfo.call.timeline.careStart_second && (
                                <p>진료시작(두 번째): {moment(reserveInfo.call.timeline.careStart_second.time).format('YY.MM.DD, HH시 mm분 ss')}</p>
                            )}
                            {reserveInfo.call.mate && reserveInfo.call.mate._id && reserveInfo.call.timeline && reserveInfo.call.timeline.careDone_second && (
                                <p>진료종료(두 번째): {moment(reserveInfo.call.timeline.careDone_second.time).format('YY.MM.DD, HH시 mm분 ss')}</p>
                            )}
                            {reserveInfo.call.mate &&
                                reserveInfo.call.mate._id &&
                                reserveInfo.call.timeline &&
                                reserveInfo.call.timeline.treatmentStart_second && (
                                    <p>치료시작(두 번째): {moment(reserveInfo.call.timeline.treatmentStart_second.time).format('YY.MM.DD, HH시 mm분 ss')}</p>
                                )}
                            {reserveInfo.call.mate &&
                                reserveInfo.call.mate._id &&
                                reserveInfo.call.timeline &&
                                reserveInfo.call.timeline.treatmentDone_second && (
                                    <p>치료종료(두 번째): {moment(reserveInfo.call.timeline.treatmentDone_second.time).format('YY.MM.DD, HH시 mm분 ss')}</p>
                                )}

                            {/* 진료/치료(세 번째) */}
                            {reserveInfo.call.mate && reserveInfo.call.mate._id && reserveInfo.call.timeline && reserveInfo.call.timeline.careStart_third && (
                                <p>진료시작(세 번째): {moment(reserveInfo.call.timeline.careStart_third.time).format('YY.MM.DD, HH시 mm분 ss')}</p>
                            )}
                            {reserveInfo.call.mate && reserveInfo.call.mate._id && reserveInfo.call.timeline && reserveInfo.call.timeline.careDone_third && (
                                <p>진료종료(세 번째): {moment(reserveInfo.call.timeline.careDone_third.time).format('YY.MM.DD, HH시 mm분 ss')}</p>
                            )}
                            {reserveInfo.call.mate &&
                                reserveInfo.call.mate._id &&
                                reserveInfo.call.timeline &&
                                reserveInfo.call.timeline.treatmentStart_third && (
                                    <p>치료시작(세 번째): {moment(reserveInfo.call.timeline.treatmentStart_third.time).format('YY.MM.DD, HH시 mm분 ss')}</p>
                                )}
                            {reserveInfo.call.mate &&
                                reserveInfo.call.mate._id &&
                                reserveInfo.call.timeline &&
                                reserveInfo.call.timeline.treatmentDone_third && (
                                    <p>치료종료(세 번째): {moment(reserveInfo.call.timeline.treatmentDone_third.time).format('YY.MM.DD, HH시 mm분 ss')}</p>
                                )}

                            {reserveInfo.call.mate && reserveInfo.call.mate._id && reserveInfo.call.timeline && reserveInfo.call.timeline.backStart && (
                                <p>자택출발: {moment(reserveInfo.call.timeline.backStart.time).format('YY.MM.DD, HH시 mm분 ss')}</p>
                            )}
                            {reserveInfo.call.mate && reserveInfo.call.mate._id && reserveInfo.call.timeline && reserveInfo.call.timeline.complete && (
                                <p>서비스종료: {moment(reserveInfo.call.timeline.complete.time).format('YY.MM.DD, HH시 mm분 ss')}</p>
                            )}
                        </InfoBlock>

                        <InfoBlock label='리포트'>
                            {reserveInfo.call && reserveInfo.call.report && reserveInfo.call.report._id && (
                                <>
                                    <p>서비스 진행 중 특이사항: {reserveInfo.call.report.description || '-'}</p>
                                    <p>진료 및 치료 내용: {reserveInfo.call.report.care || '-'}</p>
                                    <p>복약지도 사항: {reserveInfo.call.report.medicine || '-'}</p>
                                    <p>다음 진료 일정 정리: {reserveInfo.call.report.nextSchedule || '-'}</p>
                                    <p>메이트 전달 사항: {reserveInfo.call.report.mateComment || '-'}</p>
                                    <p>의료 기관 및 의사 평가: {reserveInfo.call.report.doctorComment || '-'}</p>
                                    <p>작성일자: {moment(reserveInfo.call.report.createdAt).format('YYYY. MM. DD HH:mm')}</p>
                                </>
                            )}
                        </InfoBlock>

                        {/* <InfoBlock label='사후 리포트' value={reserveInfo.report ? '사후 리포트 요청' : '요청 안함'} /> */}
                        <p style={{ fontWeight: 'bold' }}>서비스 비용 결제정보</p>
                        <InfoBlock
                            label='서비스 이용 비용 (서비스 종료후 등록됨, 사용자가 알림으로 안내받는 액수와 동일)'
                            value={((reserveInfo && reserveInfo.confirmedPrice) || '-') + '원'}
                        />
                        <InfoBlock label='사용한 포인트' value={((reserveInfo && reserveInfo.usingPoint) || '-') + '원'} />
                        <InfoBlock label='대금 지불방법' value={reserveInfo.payment === 'transfer' ? '계좌이체' : '카드결제'} />
                        {reserveInfo.payment === 'card' && (
                            <>
                                <InfoBlock label='카드사' value={reserveInfo.cardName} />
                                <InfoBlock
                                    label='카드번호'
                                    value={reserveInfo.cardNum.replace(/^(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,4})$/g, '$1-$2-$3-$4').replace(/(\-{1,2})$/g, '')}
                                />
                                <InfoBlock label='유효기간(MM/YY)' value={reserveInfo.month + ' / ' + reserveInfo.year} />
                                <InfoBlock label='할부기간' value={reserveInfo.installment} />
                            </>
                        )}
                        <InfoBlock label='대금 지불상태' value={reserveInfo.paidStatus === true ? '결제완료' : '결제전'} />
                        <InfoBlock label='환불' value={_.isEmpty(reserveInfo.refund) ? '미환불' : '환불완료'}>
                            {_.isEmpty(reserveInfo.refund) ? null : (
                                <div style={{ marginTop: '12px' }}>
                                    <p>환불 금액: {reserveInfo.refund.price}</p>
                                    <p>
                                        환불 일자:{' '}
                                        {reserveInfo.refund && reserveInfo.refund.executeDate
                                            ? moment(reserveInfo.refund.executeDate).format('YYYY. MM. DD - HH:mm A')
                                            : '-'}
                                    </p>
                                    <p>환불 계좌 은행: {reserveInfo.refund.bank}</p>
                                    <p>환불 계좌: {reserveInfo.refund.account}</p>
                                    <p>환불 계좌 소유주: {reserveInfo.refund.accountHolder}</p>
                                </div>
                            )}
                        </InfoBlock>
                        {/* <InfoBlock label='메이트 리워드' value={_.isEmpty(reserveInfo.reward) ? '보상금 미지급' : '보상금 지급 완료'}>
                            {_.isEmpty(reserveInfo.reward) ? null : (
                                <div style={{ marginTop: '12px' }}>
                                    <p>보상 금액: {reserveInfo.reward.price}</p>
                                    <p>
                                        보상 일자:{' '}
                                        {reserveInfo.reward && reserveInfo.reward.executeDate
                                            ? moment(reserveInfo.reward.executeDate).format('YYYY. MM. DD - HH:mm A')
                                            : '-'}
                                    </p>
                                    <p>보상 계좌 은행: {reserveInfo.reward.bank}</p>
                                    <p>보상 계좌: {reserveInfo.reward.account}</p>
                                    <p>보상 계좌 소유주: {reserveInfo.reward.accountHolder}</p>
                                </div>
                            )}
                        </InfoBlock> */}

                        <p style={{ fontWeight: 'bold' }}>기타정보</p>
                        <InfoBlock
                            label='추천인'
                            value={`${reserveInfo.recommender || '-'} ${reserveInfo.recommenderContact ? `(${reserveInfo.recommenderContact})` : '-'}`}
                        />
                        <InfoBlock label='매칭 거절된 메이트'>
                            <span style={{ color: 'red' }}>( 예약발생시 예약 넘긴 메이트 또는 고객에 의해 거절된 메이트 리스트 입니다. )</span>
                            {reserveInfo.call && reserveInfo.call.denyMate && (
                                <div style={{ paddingTop: '10px' }}>
                                    {reserveInfo.call.denyMate.map((denyMateValue, index) => {
                                        const matchedMate = this.props.app.mate.find(mate => mate._id === denyMateValue);

                                        return matchedMate ? (
                                            <p key={index}>
                                                ({index + 1}) {matchedMate.name}
                                            </p>
                                        ) : null;
                                    })}
                                </div>
                            )}
                        </InfoBlock>

                        <p style={{ fontWeight: 'bold' }}>알림톡 전송 버튼 목록</p>
                        <div className='button-wrapper' style={{ marginBottom: '20px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                            <details>
                                <summary
                                    style={{
                                        width: '320px',
                                        background: 'gray',
                                        color: '#fff',
                                        padding: '10px',
                                        outline: '0',
                                        borderRadius: '5px',
                                        cursor: 'pointer',
                                        transition: 'background 0.5s',
                                        textAlign: 'left',
                                        boxShadow: '1px 1px 2px gray',
                                    }}
                                >
                                    <b>메이트/사용자에게 알림톡 재전송</b>
                                </summary>
                                <br />
                                <Button
                                    style={{ marginBottom: '8px' }}
                                    type='primary'
                                    onClick={() => {
                                        this.onResendMessage('매칭 완료');
                                    }}
                                >
                                    <b>메이트 '매칭 완료' 생성해주기</b>
                                </Button>
                                <br />
                                <Button
                                    style={{ marginBottom: '8px' }}
                                    type='primary'
                                    onClick={() => {
                                        this.onResendMessage('예약 확인');
                                    }}
                                >
                                    <b>사용자 '예약 확인(당일)' 생성해주기</b>
                                </Button>
                                <br />
                                {/* <Button
                                    style={{ marginBottom: '8px' }}
                                    type='primary'
                                    onClick={() => {
                                        this.onResendMessage('서비스 종료');
                                    }}
                                >
                                    <b>메이트 '서비스 종료' 생성해주기</b>
                                </Button> */}
                            </details>
                        </div>

                        <div className='button-wrapper' style={{ marginBottom: '20px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                            <details>
                                <summary
                                    style={{
                                        width: '320px',
                                        background: '#444',
                                        color: '#fff',
                                        padding: '10px',
                                        outline: '0',
                                        borderRadius: '5px',
                                        cursor: 'pointer',
                                        transition: 'background 0.5s',
                                        textAlign: 'left',
                                        boxShadow: '1px 1px 2px gray',
                                    }}
                                >
                                    <b>[PRO] 메이트 서비스 단계별 알림톡 전송</b>
                                </summary>
                                <br />
                                <Button
                                    style={{ marginBottom: '8px' }}
                                    type='primary'
                                    onClick={() => {
                                        this.onResendMessage('접선');
                                    }}
                                >
                                    (New) 메이트 "접선 알림" 생성해주기 - Pro
                                </Button>
                                <br />
                                <Button
                                    style={{ marginBottom: '8px' }}
                                    type='primary'
                                    onClick={() => {
                                        this.onResendMessage('병원 출발');
                                    }}
                                >
                                    (New) 메이트 "병원 출발" 생성해주기 - Pro
                                </Button>
                                <br />
                                <Button
                                    style={{ marginBottom: '8px' }}
                                    type='primary'
                                    onClick={() => {
                                        this.onResendMessage('병원 도착');
                                    }}
                                >
                                    (New) 메이트 "병원 도착" 생성해주기 - Pro
                                </Button>
                                <br />
                                <Button
                                    style={{ marginBottom: '8px' }}
                                    type='primary'
                                    onClick={() => {
                                        this.onResendMessage('진료 시작');
                                    }}
                                >
                                    (New) 메이트 "진료시작 알림" 생성해주기 - Pro
                                </Button>
                                <br />
                                <Button
                                    style={{ marginBottom: '8px' }}
                                    type='primary'
                                    onClick={() => {
                                        this.onResendMessage('진료 종료');
                                    }}
                                >
                                    (New) 메이트 "진료종료 알림" 생성해주기 - Pro
                                </Button>
                                <br />
                                <Button
                                    style={{ marginBottom: '8px' }}
                                    type='primary'
                                    onClick={() => {
                                        this.onResendMessage('치료 시작');
                                    }}
                                >
                                    (New) 메이트 "치료시작 알림" 생성해주기 - Pro
                                </Button>
                                <br />
                                <Button
                                    style={{ marginBottom: '8px' }}
                                    type='primary'
                                    onClick={() => {
                                        this.onResendMessage('치료 종료');
                                    }}
                                >
                                    (New) 메이트 "치료종료 알림" 생성해주기 - Pro
                                </Button>
                                <br />
                                <Button
                                    style={{ marginBottom: '8px' }}
                                    type='primary'
                                    onClick={() => {
                                        this.onResendMessage('자택 출발');
                                    }}
                                >
                                    (New) 메이트 "귀가 시작 알림" 생성해주기 - Pro
                                </Button>
                                <br />
                                <Button
                                    style={{ marginBottom: '8px' }}
                                    type='primary'
                                    onClick={() => {
                                        this.onResendMessage('서비스 종료');
                                    }}
                                >
                                    (New) 메이트 "서비스 종료 알림" 생성해주기 - Pro
                                </Button>
                            </details>
                        </div>
                        <div className='button-wrapper' style={{ marginBottom: '20px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                            <details>
                                <summary
                                    style={{
                                        width: '320px',
                                        background: '#444',
                                        color: '#fff',
                                        padding: '10px',
                                        outline: '0',
                                        borderRadius: '5px',
                                        cursor: 'pointer',
                                        transition: 'background 0.5s',
                                        textAlign: 'left',
                                        boxShadow: '1px 1px 2px gray',
                                    }}
                                >
                                    <b>[BASIC] 메이트 서비스 단계별 알림톡 전송</b>
                                </summary>
                                <br />

                                <Button
                                    style={{ marginBottom: '8px' }}
                                    type='primary'
                                    onClick={() => {
                                        this.onResendMessage('접선');
                                    }}
                                >
                                    (New) 메이트 "접선 알림" 생성해주기 - Basic
                                </Button>
                                <br />
                                <Button
                                    style={{ marginBottom: '8px' }}
                                    type='primary'
                                    onClick={() => {
                                        this.onResendMessage('진료 시작');
                                    }}
                                >
                                    (New) 메이트 "진료 시작 알림" 생성해주기 - Basic
                                </Button>
                                <br />
                                <Button
                                    style={{ marginBottom: '8px' }}
                                    type='primary'
                                    onClick={() => {
                                        this.onResendMessage('진료 종료');
                                    }}
                                >
                                    (New) 메이트 "진료 종료 알림" 생성해주기 - Basic
                                </Button>
                                <br />
                                <Button
                                    style={{ marginBottom: '8px' }}
                                    type='primary'
                                    onClick={() => {
                                        this.onResendMessage('치료 시작');
                                    }}
                                >
                                    (New) 메이트 "치료 시작 알림" 생성해주기 - Basic
                                </Button>
                                <br />
                                <Button
                                    style={{ marginBottom: '8px' }}
                                    type='primary'
                                    onClick={() => {
                                        this.onResendMessage('치료 종료');
                                    }}
                                >
                                    (New) 메이트 "치료 종료 알림" 생성해주기 - Basic
                                </Button>
                                <br />
                                <Button
                                    style={{ marginBottom: '8px' }}
                                    type='primary'
                                    onClick={() => {
                                        this.onResendMessage('서비스 종료');
                                    }}
                                >
                                    (New) 메이트 "서비스 종료 알림" 생성해주기 - Basic
                                </Button>
                            </details>
                        </div>

                        <div style={{ border: '2px solid #e9e9e9', marginBottom: '20px' }} />

                        <div className='button-wrapper'>
                            <Button style={{ backgroundColor: '#3bc1ff', color: 'white', marginRight: '20px' }} onClick={this.onPaid}>
                                {reserveInfo.paidStatus ? '결제완료 취소' : '결제완료 처리'}
                            </Button>

                            <Button
                                style={{ backgroundColor: '#33691e', color: 'white', marginRight: '20px' }}
                                onClick={() => {
                                    this.setState({ modal: true });
                                }}
                            >
                                환불이력 등록
                            </Button>
                            <Button
                                style={{ backgroundColor: '#fbbc05', color: 'white' }}
                                onClick={() => {
                                    this.setState({ rewardModal: true });
                                }}
                            >
                                리워드 지급이력 등록
                            </Button>
                            <p style={{ fontSize: '16px', marginTop: '28px', fontWeight: 'bold' }}>※ 주의. 최종 선택한 버튼에 따라 상태가 결정됩니다.</p>
                        </div>

                        <div style={{ marginBottom: '16px' }}>
                            <Button type='primary' onClick={this.onAllowMate}>
                                메이트 수락하기
                            </Button>
                            <p>※ 보호자 대신 관리자가 메이트 대신 수락할 때 사용</p>
                        </div>
                        <div style={{ marginBottom: '16px' }}>
                            <Button type='primary' onClick={this.onRematchMate}>
                                <b>[사용자 요청] </b> 재매칭
                            </Button>
                            <p>※ 서비스를 수락한 메이트가 없어 매칭이 지연될 때 사용</p>
                        </div>
                        <br />

                        <div style={{ marginBottom: '16px' }}>
                            <Button type='primary' onClick={this.onRematchByMate}>
                                <b>[메이트 요청] </b> 매칭 취소 및 재매칭
                            </Button>
                            <p>※ 메이트에 의해 매칭된 메이트 취소 후 다시 메이트 매칭</p>
                        </div>
                        <div style={{ marginBottom: '16px' }}>
                            <Button type='primary' onClick={this.onRematchByUser}>
                                <b>[사용자 및 관리자 요청] </b> 매칭 취소 및 재매칭
                            </Button>
                            <p>※ 사용자에 의해 매칭된 메이트 취소 후 다시 메이트 매칭</p>
                        </div>
                        <br />

                        <div style={{ marginBottom: '16px' }}>
                            <Button type='primary' onClick={this.onCancelMeet}>
                                <b>[메이트 요청] </b> 접선 취소
                            </Button>
                            <p>※ 서비스 당일 메이트가 접선을 잘못 누른 경우 사용</p>
                        </div>
                        <div style={{ marginBottom: '16px' }}>
                            <Button type='primary' onClick={this.onCancelReserve}>
                                <b>[사용자 요청] </b> 예약 취소
                            </Button>
                            <p>※ 사용자에 의해 서비스 예약을 취소할 때 사용</p>
                        </div>
                        <div>
                            <Button type='primary' onClick={this.onCancelByWithmate}>
                                <b>[관리자 판단] </b> 접수 취소
                            </Button>
                            <p>※ 관리자가 판단하기에 불가능한 서비스일 경우 취소할 때 사용</p>
                        </div>
                    </div>
                }
                <Modal
                    title='환불이력 등록'
                    visible={this.state.modal}
                    onCancel={() => {
                        this.setState({ modal: false });
                    }}
                    onOk={() => {
                        this.onExecute('refund');
                    }}
                >
                    <div className='modal-info-block'>
                        <p>환불금액</p>
                        <Input
                            placeholder='10000'
                            value={this.state.refundInfo && this.state.refundInfo.price ? this.state.refundInfo.price : ''}
                            onChange={ev => {
                                this.setState({
                                    refundInfo: {
                                        ...this.state.refundInfo,
                                        price: ev.target.value,
                                    },
                                });
                            }}
                        />
                    </div>
                    <div className='modal-info-block'>
                        <p>은행</p>
                        <Input
                            placeholder='국민은행'
                            value={this.state.refundInfo && this.state.refundInfo.bank ? this.state.refundInfo.bank : ''}
                            onChange={ev => {
                                this.setState({
                                    refundInfo: {
                                        ...this.state.refundInfo,
                                        bank: ev.target.value,
                                    },
                                });
                            }}
                        />
                    </div>
                    <div className='modal-info-block'>
                        <p>계좌</p>
                        <Input
                            placeholder='10-203-11234-122'
                            value={this.state.refundInfo && this.state.refundInfo.account ? this.state.refundInfo.account : ''}
                            onChange={ev => {
                                this.setState({
                                    refundInfo: {
                                        ...this.state.refundInfo,
                                        account: ev.target.value,
                                    },
                                });
                            }}
                        />
                    </div>
                    <div className='modal-info-block'>
                        <p>소유주</p>
                        <Input
                            placeholder='홍길동'
                            value={this.state.refundInfo && this.state.refundInfo.accountHolder ? this.state.refundInfo.accountHolder : ''}
                            onChange={ev => {
                                this.setState({
                                    refundInfo: {
                                        ...this.state.refundInfo,
                                        accountHolder: ev.target.value,
                                    },
                                });
                            }}
                        />
                    </div>
                </Modal>

                <Modal
                    title='보상이력 등록'
                    visible={this.state.rewardModal}
                    onCancel={() => {
                        this.setState({ rewardModal: false });
                    }}
                    onOk={() => {
                        // this.onExecute('refund');
                    }}
                >
                    <div className='modal-info-block'>
                        <p>보상금액</p>
                        <Input
                            placeholder='10000'
                            value={this.state.rewardInfo && this.state.rewardInfo.price ? this.state.rewardInfo.price : ''}
                            onChange={ev => {
                                this.setState({
                                    rewardInfo: {
                                        ...this.state.rewardInfo,
                                        price: ev.target.value,
                                    },
                                });
                            }}
                        />
                    </div>
                    <div className='modal-info-block'>
                        <p>은행</p>
                        <Input
                            placeholder='국민은행'
                            value={this.state.rewardInfo && this.state.rewardInfo.bank ? this.state.rewardInfo.bank : ''}
                            onChange={ev => {
                                this.setState({
                                    rewardInfo: {
                                        ...this.state.rewardInfo,
                                        bank: ev.target.value,
                                    },
                                });
                            }}
                        />
                    </div>
                    <div className='modal-info-block'>
                        <p>계좌</p>
                        <Input
                            placeholder='10-203-11234-122'
                            value={this.state.rewardInfo && this.state.rewardInfo.account ? this.state.rewardInfo.account : ''}
                            onChange={ev => {
                                this.setState({
                                    rewardInfo: {
                                        ...this.state.rewardInfo,
                                        account: ev.target.value,
                                    },
                                });
                            }}
                        />
                    </div>
                    <div className='modal-info-block'>
                        <p>소유주</p>
                        <Input
                            placeholder='홍길동'
                            value={this.state.rewardInfo && this.state.rewardInfo.accountHolder ? this.state.rewardInfo.accountHolder : ''}
                            onChange={ev => {
                                this.setState({
                                    rewardInfo: {
                                        ...this.state.rewardInfo,
                                        accountHolder: ev.target.value,
                                    },
                                });
                            }}
                        />
                    </div>
                </Modal>

                <Modal
                    title='메이트 정보확인'
                    visible={this.state.mateModal}
                    footer={null}
                    onCancel={() => {
                        this.setState({ mateModal: false });
                    }}
                >
                    <div className='mate-img-wrapper'>
                        <img src={_.isEmpty(reserveInfo.call.mate) ? '-' : reserveInfo.call.mate.photo} style={{ width: '150px', height: 'auto' }} />
                    </div>
                    <br />
                    <div className='mate-info-wrapper'>
                        <div className='modal-info-block'>
                            <InfoBlock_ label='메이트 이름' value={_.isEmpty(reserveInfo.call.mate) ? '-' : reserveInfo.call.mate.name} />
                        </div>
                        <div className='modal-info-block'>
                            <InfoBlock_ label='메이트 연락처' value={_.isEmpty(reserveInfo.call.mate) ? '-' : reserveInfo.call.mate.contact} />
                        </div>
                        <div className='modal-info-block'>
                            <InfoBlock_ label='성별' value={_.isEmpty(reserveInfo.call.mate) ? '-' : reserveInfo.call.mate.sex} />
                        </div>
                        <div className='modal-info-block'>
                            <InfoBlock_
                                label='가입일'
                                value={_.isEmpty(reserveInfo.call.mate) ? '-' : moment(reserveInfo.call.mate.createdAt).format('YY.MM.DD')}
                            />
                        </div>
                        {/* <div className='modal-info-block'>
                            <InfoBlock_ label='서비스 수행목록' value={(_.isEmpty(reserveInfo.call.mate) ? '-' : reserveInfo.call.mate.doProducts).toLocaleString()} />
                        </div> */}
                        <div className='modal-info-block'>
                            <InfoBlock_ label='수행이력'>
                                <p className='value' style={{ margin: 0, padding: 0, color: 'rgba(0,0,0,0.87)', fontSize: '14px' }}>
                                    Pro 서비스 - {(review && review.unemergency) || 0}건
                                </p>
                                <p className='value' style={{ margin: 0, padding: 0, color: 'rgba(0,0,0,0.87)', fontSize: '14px' }}>
                                    Basic 서비스 - {(review && review.assist) || 0}건
                                </p>
                            </InfoBlock_>
                        </div>
                        <div>
                            <InfoBlock_ label='동행 서비스 이행 평점'>
                                <p>
                                    {review && (review.unemergency || review.assist) && review.score === '0.0'
                                        ? '4 (리뷰없음)'
                                        : review && review.score
                                        ? review.score
                                        : '-'}
                                </p>
                            </InfoBlock_>
                        </div>
                    </div>
                </Modal>

                <Modal
                    title='예약내역 수정하기'
                    visible={this.state.modifyReserveModal}
                    onCancel={() => {
                        this.setState({ modifyReserveModal: false });
                    }}
                    onOk={() => {
                        this.onModify();
                    }}
                >
                    <div className='modal-info-block'>
                        <InfoBlock label='서비스'>
                            <Select
                                style={{ width: '100%' }}
                                placeholder='서비스를 먼저 지정해주세요.'
                                value={(form && form.productId) || undefined}
                                onChange={value => {
                                    const productIndex = services.findIndex(s => s.id === value);

                                    this.setState(prevState => ({
                                        form: {
                                            ...prevState['form'],
                                            productId: value,
                                            reservedDestination: services[productIndex].reservedDestination || '',
                                            destination: services[productIndex].reservedDestination || '',
                                            shortDestination: services[productIndex].reservedShortDestination || '',
                                            reservedStarting: services[productIndex].reservedStarting || '',
                                            starting: services[productIndex].reservedStarting || '',
                                            shortStarting: services[productIndex].reservedShortStarting || '',
                                        },
                                    }));
                                }}
                            >
                                {services &&
                                    services.length > 0 &&
                                    services
                                        .filter(service => service.valid)
                                        .map(service => (
                                            <Select.Option value={service.id} key={service.id}>
                                                {service.name}
                                            </Select.Option>
                                        ))}
                            </Select>
                        </InfoBlock>
                        <InfoBlock label='서비스 타입'>
                            <Select
                                style={{ width: '100%' }}
                                value={form.service || undefined}
                                placeholder='예약할 서비스 타입을 선택해주세요.'
                                onChange={value => {
                                    this.setState(prevState => ({
                                        form: {
                                            ...prevState['form'],
                                            service: value,
                                        },
                                    }));
                                }}
                            >
                                <Select.Option value='assist'>Basic</Select.Option>
                                <Select.Option value='unemergency'>Pro</Select.Option>
                            </Select>
                        </InfoBlock>

                        <InfoBlock label='환자 성명'>
                            <Input onChange={this.onEdit} name='patient' value={form.patient || undefined} placeholder='이용자 성함' />
                        </InfoBlock>

                        <InfoBlock label='보호자 연락처'>
                            <Input onChange={this.onEdit} maxLength={14} value={form.guardContact || undefined} name='guardContact' placeholder='01012341234' />
                        </InfoBlock>

                        <InfoBlock label='환자 연락처'>
                            <Input onChange={this.onEdit} maxLength={14} value={form.contact || undefined} name='contact' placeholder='01012341234' />
                        </InfoBlock>

                        <InfoBlock label='병원진료 예약일자'>
                            <Input
                                type='datetime-local'
                                value={form.reserveDate || undefined}
                                format='YYYY-MM-DD'
                                style={{ width: '100%' }}
                                onChange={e => {
                                    const date = e.target.value;
                                    this.setState(prevState => ({
                                        form: {
                                            ...prevState['form'],
                                            ['reserveDate']: date,
                                        },
                                    }));
                                }}
                            />
                        </InfoBlock>

                        <InfoBlock label='병원도착 희망일자 / 자택도착 희망일자'>
                            <Input
                                type='datetime-local'
                                value={form.meetDate || undefined}
                                format='YYYY-MM-DD'
                                style={{ width: '100%' }}
                                onChange={e => {
                                    const date = e.target.value;
                                    this.setState(prevState => ({
                                        form: {
                                            ...prevState['form'],
                                            ['meetDate']: date,
                                        },
                                    }));
                                }}
                            />
                        </InfoBlock>
                    </div>
                </Modal>
            </div>
        );
    }
}
export default ServiceItem;
