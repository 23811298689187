import React from 'react';
import { Switch, Route } from 'react-router-dom';

// pages
import NotFound from '../NotFound';
import MateList from './UserPages/MateList';
import NormalList from './UserPages/NormalList';
import Normal from './UserPages/User';
import Mate from './UserPages/Mate';

class UserPages extends React.PureComponent {
    render() {
        const { url } = this.props.match;
        return (
            <Switch>
                <Route exact path={`${url}/normal/list`} component={NormalList} />
                <Route exact path={`${url}/mate/list`} component={MateList} />
                <Route exact path={`${url}/normal/info/:id`} component={Normal} />
                <Route exact path={`${url}/mate/info/:id`} component={Mate} />
                <Route component={NotFound} />
            </Switch>
        );
    }
}
export default UserPages;
