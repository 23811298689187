import styled from 'styled-components';

export const TableWrapper = styled.div`
    padding: 24px;
`;

export const TableContainer = styled.div`
    padding: 24px;
    background-color: #ffffff;
    border-radius: 4px;
`;

export const BlockWrapper = styled.div`
    width: 100%;
    padding: 24px;
    background-color: white;
`;

const StyledUserCouponListContainer = styled.div`
`;


export default StyledUserCouponListContainer;