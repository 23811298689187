import React from 'react';
import { Input, Checkbox, Button, message, Select } from 'antd';

import { TableWrapper, TableContainer, Label, ValueBox } from './create.styled';

import axios from '../../../Lib/axios';
import TextArea from 'antd/lib/input/TextArea';
import Title from '../../../Components/Common/pageTitle';

export default class ProductCreate extends React.PureComponent {
    state = {
        photo: '',
        title: '',
        content: '',
        url: '',
        url2: '',
        urlSource: '',
        urlSource2: '',
    };

    onAdd = async () => {
        const { title, content, photo, url, url2, urlSource, urlSource2 } = this.state;
        if (!title) return message.warning('제목을 입력해주세요.');
        if (!content) return message.warning('설명을 입력해주세요.');

        const formData = new FormData();
        formData.append('photo', photo);
        const header = { headers: { 'Content-Type': 'multipart/form-data' } };
        const { data: image } = await axios.post('/v1/newsEvent/image/upload', formData, header);

        const { data } = await axios.post('/v1/newsEvent/add', {
            title,
            content,
            photo: image,
            url,
            url2,
            urlSource,
            urlSource2,
        });

        if (!data) return message.error('죄송합니다. 일시적인 서버 장애가 발생하였습니다.');
        if (data === 'check parameters') return message.warning('필수 정보들을 입력해주세요.');
        if (data === 'success') {
            message.success('News & Event 작성완료');
            setTimeout(() => {
                this.props.history.goBack();
            }, 600);
        }
    };

    handleChange = e => {
        this.setState({ [e.target.title]: e.target.value });
    };

    render() {
        return (
            <>
                <Title title='News & Event 등록' />
                <TableWrapper>
                    <TableContainer>
                        <>
                            <ValueBox>
                                <Label style={{ fontSize: '14px' }}>
                                    <b>제목</b>
                                </Label>
                                <Input placeholder='제목을 입력해주세요.' value={this.state.title} title='title' onChange={this.handleChange} />
                            </ValueBox>

                            <ValueBox>
                                <Label style={{ fontSize: '14px' }}>
                                    <b>내용</b>
                                </Label>
                                <TextArea
                                    placeholder='내용을 입력해주세요.'
                                    value={this.state.content}
                                    title='content'
                                    onChange={this.handleChange}
                                    style={{ height: '200px', whiteSpace: 'pre-wrap' }}
                                />
                            </ValueBox>

                            <ValueBox>
                                <Label style={{ fontSize: '14px' }}>
                                    <b>사진</b>
                                </Label>
                                {this.state.photo && (
                                    <div>
                                        <img
                                            style={{ width: '300px', height: '300px', objectFit: 'contain' }}
                                            src={typeof this.state.photo === 'string' ? this.state.photo : URL.createObjectURL(this.state.photo)}
                                            alt='사진'
                                        />
                                    </div>
                                )}
                                <input
                                    type='file'
                                    accept='image/*'
                                    placeholder='사진을 추가해주세요.'
                                    title='photo'
                                    onChange={ev => {
                                        console.log(ev.target.files);
                                        this.setState({ photo: ev.target.files && ev.target.files.length > 0 ? ev.target.files[0] : '' });
                                    }}
                                />
                            </ValueBox>

                            <ValueBox>
                                <Label style={{ fontSize: '14px' }}>
                                    <b>URL</b>
                                </Label>
                                <Input
                                    placeholder='뉴스기사 출처(언론사)를 입력해주세요.'
                                    value={this.state.urlSource}
                                    title='urlSource'
                                    onChange={this.handleChange}
                                />
                                <Input placeholder='https://를 제외한 URL을 입력해주세요.' value={this.state.url} title='url' onChange={this.handleChange} />
                                <br />
                                <br />
                                <Input
                                    placeholder='뉴스기사 출처(언론사)를 입력해주세요.'
                                    value={this.state.urlSource2}
                                    title='urlSource2'
                                    onChange={this.handleChange}
                                />
                                <Input placeholder='https://를 제외한 URL을 입력해주세요.' value={this.state.url2} title='url2' onChange={this.handleChange} />
                            </ValueBox>

                            <ValueBox style={{ textAlign: 'right' }}>
                                <Button type='primary' onClick={this.onAdd}>
                                    등록하기
                                </Button>
                            </ValueBox>
                        </>
                    </TableContainer>
                </TableWrapper>
            </>
        );
    }
}
