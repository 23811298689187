import React from 'react';
import { notification, Table, Input } from 'antd';
import { card } from '../../../Components/Columns/payment';
import { reserveCard } from '../../../Components/Columns/service';

import { TableContainer, TableWrapper } from './list.styled';
import { onDeleteCard } from '../../../Lib/Api/app';

export default class CardList extends React.PureComponent {
    state = {
        keyword: '',
    };

    onRow = (row, index) => {
        return {
            onClick: () => {
                this.props.history.push({
                    pathname: `/card/info/${row._id}`,
                    state: {
                        id: row._id,
                    },
                });
            },
        };
    };

    onRowReserve = (row, index) => {
        return {
            onClick: () => {
                this.props.history.push({
                    pathname: `/service/info/${row._id}`,
                    state: 'reserve',
                });
            },
        };
    };

    onCardDelete = async ({ _id }) => {
        console.log('_id: ', _id);

        const confirmed = window.confirm('해당 게시물을 삭제하시겠습니까?');
        if (!confirmed) return null;

        onDeleteCard({ cardId: _id })
            .then(resolve => {
                if (resolve && resolve.data === 'success') {
                    notification.open({
                        message: '게시물을 삭제하였습니다.',
                        description: '게시물을 삭제하였습니다.',
                        type: 'success'
                    });
                    window.location.reload();
            }});
    };

    getList = () => {
        const { reserve } = this.props.app;
        const { keyword } = this.state;

        const list = reserve.filter(
            item =>
                (item && item.user && item.user.name.toLowerCase().includes(keyword)),
        );

        return list;
    };

    render() {
        // console.log(this.props.app.cardList);
        // console.log(this.props.app.reserve);
        // console.log(this.props.app);

        const list = this.getList();
        return (
            <>
                <TableWrapper>
                    <TableContainer>
                        <h3><b>등록된 고객 카드 목록</b></h3>
                        <Table
                            rowKey='id'
                            dataSource={this.props.app.cardList.data}
                            columns={card({ onDelete: this.onCardDelete })}
                            onRow={this.onRow}
                            pagination={{
                                pageSize: 5
                            }}
                            scroll={{ x: 600 }}
                        />
                    </TableContainer>
                    <br/><br/>
                        <TableContainer>
                            <Input
                            id='search-input'
                            value={this.state.keyword}
                            onChange={ev => {
                                const el = document.getElementById('search-input');
                                const event = new KeyboardEvent('keypress', { isTrusted: true, key: 'Enter', code: 'Enter', keyCode: 13 });
                                // console.log(event);
                                const res = el.dispatchEvent(event);
                                // console.log(res);
                                this.setState({ keyword: ev.target.value });
                            }}
                            placeholder='신청인으로 검색하세요.'
                            style={{ marginBottom: '24px' }}
                        />
                        <h3><b>예약시 입력된 고객 카드 목록</b></h3>
                        <Table
                            rowKey='id'
                            dataSource={list}
                            columns={reserveCard}
                            onRow={this.onRowReserve}
                            pagination={{
                                pageSize: 5
                            }}
                            scroll={{ x: 600 }}
                        />
                    </TableContainer>
                </TableWrapper>
            </>
        );
    }
}
