import React from 'react';
import { connect } from 'react-redux';
import { logout } from '../../Reducer/Modules/auth';
import { notification } from 'antd';

class LogoutPage extends React.PureComponent {
    componentDidMount = () => {
        const { id } = this.props.auth;
        const confirmed = window.confirm(`${this.props.auth.name}님 로그아웃 하시겠습니까?`);
        if (!confirmed) return (window.location.href = '/');

        notification.open({
            message: '로그아웃 완료',
            description: '로그아웃 되었습니다.',
            type: 'success',
        });
        if (!id) {
            localStorage.clear();
            return (window.location.href = '/');
        }
        setTimeout(() => {
            this.props.logout({ id });
        }, 1000);
    };

    render() {
        return <div></div>;
    }
}

const mapStateToProps = ({ auth }) => ({
    auth,
});
const mapDispatchToProps = dispatch => ({
    logout: ({ id, userType }) => dispatch(logout({ id, userType })),
});
export default connect(mapStateToProps, mapDispatchToProps)(LogoutPage);
