import React from 'react';
import { Table, Input, Tabs, notification, Button } from 'antd';
import { reserve as reserveColumn } from '../../../../Components/Columns/service';

import { UserOutlined } from '@ant-design/icons';

const { TabPane } = Tabs;

class ServiceList extends React.PureComponent {
    constructor(props) {
        super(props);
        // 시도할 로컬 스토리지 키 정의
        this.localStorageKey = 'selectedTab';
        // 로컬 스토리지에서 저장된 선택된 탭을 불러옴
        const selectedTab = localStorage.getItem(this.localStorageKey);

        this.state = {
            keyword: '',
            activeTab: selectedTab || 'success', // 로컬 스토리지에서 불러온 값을 기본 값으로 설정
        };
    }

    handleTabClick = key => {
        this.setState({
            showReservationSummary: false,
            reservationSummary: {},
        });

        localStorage.setItem(this.localStorageKey, key);
    };
    onRowReserve = (row, index) => {
        return {
            onClick: () => {
                this.props.history.push({
                    pathname: `/service/info/${row._id}`,
                    state: 'reserve',
                });
            },
        };
    };

    onChangePage = page => {
        // console.log(page);
        this.props.editPage(page);
    };

    // 접수대기 & 예약접수
    getReserveList = () => {
        const { reserve } = this.props.app;
        const { keyword, activeTab } = this.state;

        const filteredList = reserve.filter(item => {
            if ((item && item.call && item.call.status === '접수대기') || (item && item.call && item.call.status === '예약접수')) {
                return (
                    (item.reserveCode && item.reserveCode.toLowerCase().includes(keyword)) ||
                    (item.patient && item.patient.toLowerCase().includes(keyword)) ||
                    (item.user && item.user.name.toLowerCase().includes(keyword))
                );
            }
            return false;
        });

        return activeTab === 'reserve' ? filteredList : [];
    };

    // 예약완료
    getSuccessList = () => {
        const { reserve } = this.props.app;
        const { keyword, activeTab } = this.state;

        const filteredList = reserve.filter(item => {
            if (item && item.call && item.call.status === '예약완료') {
                return (
                    (item.reserveCode && item.reserveCode.toLowerCase().includes(keyword)) ||
                    (item.patient && item.patient.toLowerCase().includes(keyword)) ||
                    (item.user && item.user.name.toLowerCase().includes(keyword))
                );
            }
            return false;
        });

        return activeTab === 'success' ? filteredList : [];
    };

    // 서비스진행
    getSuccessList = () => {
        const { reserve } = this.props.app;
        const { keyword, activeTab } = this.state;

        const filteredList = reserve.filter(item => {
            if (item && item.call && item.call.status === '예약완료') {
                return (
                    (item.reserveCode && item.reserveCode.toLowerCase().includes(keyword)) ||
                    (item.patient && item.patient.toLowerCase().includes(keyword)) ||
                    (item.user && item.user.name.toLowerCase().includes(keyword))
                );
            }
            return false;
        });

        return activeTab === 'success' ? filteredList : [];
    };

    // 서비스취소
    getCancelList = () => {
        const { reserve } = this.props.app;
        const { keyword, activeTab } = this.state;

        const filteredList = reserve.filter(item => {
            if (item && item.call && item.call.status === '접수취소') {
                return (
                    (item.reserveCode && item.reserveCode.toLowerCase().includes(keyword)) ||
                    (item.patient && item.patient.toLowerCase().includes(keyword)) ||
                    (item.user && item.user.name.toLowerCase().includes(keyword))
                );
            }
            return false;
        });

        return activeTab === 'cancel' ? filteredList : [];
    };

    getList = () => {
        const { reserve } = this.props.app;
        const { keyword, activeTab } = this.state;

        const list = reserve.filter(
            item =>
                ((item && item.reserveCode && item.reserveCode.toLowerCase().includes(keyword)) ||
                    (item && item.patient && item.patient.toLowerCase().includes(keyword)) ||
                    (item && item.user && item.user.name.toLowerCase().includes(keyword))) &&
                (activeTab === 'success'
                    ? !(item && item.call && item.call.status && item.call.status.toLowerCase() === '예약완료')
                    : !(
                          item &&
                          item.call &&
                          item.call.status &&
                          (item.call.status.toLowerCase() === '접수대기' ||
                              item.call.status.toLowerCase() === '예약접수' ||
                              item.call.status.toLowerCase() === '예약완료' ||
                              item.call.status.toLowerCase() === '접수취소')
                      )),
        );

        return list;
    };

    render() {
        const reserveList = this.getReserveList();
        const successList = this.getSuccessList();
        const cancelList = this.getCancelList();
        const list = this.getList();

        return (
            <div className='container'>
                <Input
                    id='search-input'
                    value={this.state.keyword}
                    onChange={ev => {
                        const el = document.getElementById('search-input');
                        const event = new KeyboardEvent('keypress', { isTrusted: true, key: 'Enter', code: 'Enter', keyCode: 13 });
                        // console.log(event);
                        const res = el.dispatchEvent(event);
                        // console.log(res);
                        this.setState({ keyword: ev.target.value });
                    }}
                    placeholder='예약번호, 신청인, 대상으로 검색하세요.'
                    style={{ marginBottom: '24px' }}
                />

                <div className='table'>
                    <Tabs
                        defaultActiveKey='success'
                        activeKey={this.state.activeTab}
                        onChange={activeTab => {
                            this.setState({ activeTab });
                        }}
                        onTabClick={this.handleTabClick}
                    >
                        <TabPane tab='접수대기 & 예약접수' key='reserve'>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <h3>
                                    <b>서비스 예약접수상태 내역</b>
                                </h3>

                                {this.state.showReservationSummary && (
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                            float: 'inline-end',
                                            width: 'fit-content',
                                            border: '1px solid #9fd800',
                                        }}
                                    >
                                        <div style={{ padding: '5px 10px' }}>
                                            <p style={{ fontWeight: 'bold' }}>서비스예약 요약정보</p>
                                            <div style={{ display: 'flex' }}>
                                                <p style={{ paddingRight: '10px' }}>✅예약번호: {this.state.reservationSummary.reserveCode}</p>
                                                <p>🙎🏻‍♂️신청인: {this.state.reservationSummary.patient}</p>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>

                            <Table
                                rowKey='_id'
                                dataSource={reserveList}
                                columns={[
                                    {
                                        title: '정보확인',
                                        dataIndex: 'valid',
                                        align: 'center',
                                        width: '58px',
                                        render: (text, record) => (
                                            <Button
                                                style={{ border: 'none' }}
                                                onClick={e => {
                                                    e.stopPropagation();

                                                    this.setState({
                                                        showReservationSummary: true,
                                                        reservationSummary: {
                                                            reserveCode: record.reserveCode,
                                                            patient: record.patient,
                                                        },
                                                    });
                                                }}
                                            >
                                                <UserOutlined />
                                            </Button>
                                        ),
                                    },
                                    ...reserveColumn,
                                ]}
                                onRow={this.onRowReserve}
                                pagination={{
                                    pageSize: 10,
                                    current: this.props.service.servicePage,
                                    onChange: this.onChangePage,
                                }}
                                scroll={{ x: 600 }}
                            />
                        </TabPane>

                        <TabPane tab='예약완료' key='success'>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <h3>
                                    <b>서비스 예약완료 내역</b>
                                </h3>

                                {this.state.showReservationSummary && (
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                            float: 'inline-end',
                                            width: 'fit-content',
                                            border: '1px solid #9fd800',
                                        }}
                                    >
                                        <div style={{ padding: '5px 10px' }}>
                                            <p style={{ fontWeight: 'bold' }}>서비스예약 요약정보</p>
                                            <div style={{ display: 'flex' }}>
                                                <p style={{ paddingRight: '10px' }}>✅예약번호: {this.state.reservationSummary.reserveCode}</p>
                                                <p>🙎🏻‍♂️신청인: {this.state.reservationSummary.patient}</p>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <Table
                                rowKey='_id'
                                dataSource={successList}
                                columns={[
                                    {
                                        title: '정보확인',
                                        dataIndex: 'valid',
                                        align: 'center',
                                        width: '58px',
                                        render: (text, record) => (
                                            <Button
                                                style={{ border: 'none' }}
                                                onClick={e => {
                                                    e.stopPropagation();

                                                    this.setState({
                                                        showReservationSummary: true,
                                                        reservationSummary: {
                                                            reserveCode: record.reserveCode,
                                                            patient: record.patient,
                                                        },
                                                    });
                                                }}
                                            >
                                                <UserOutlined />
                                            </Button>
                                        ),
                                    },
                                    ...reserveColumn,
                                ]}
                                onRow={this.onRowReserve}
                                pagination={{
                                    pageSize: 10,
                                    current: this.props.service.servicePage,
                                    onChange: this.onChangePage,
                                }}
                                scroll={{ x: 600 }}
                            />
                        </TabPane>

                        <TabPane tab='예약취소' key='cancel'>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <h3>
                                    <b>서비스 예약취소 내역</b>
                                </h3>

                                {this.state.showReservationSummary && (
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                            float: 'inline-end',
                                            width: 'fit-content',
                                            border: '1px solid #9fd800',
                                        }}
                                    >
                                        <div style={{ padding: '5px 10px' }}>
                                            <p style={{ fontWeight: 'bold' }}>서비스예약 요약정보</p>
                                            <div style={{ display: 'flex' }}>
                                                <p style={{ paddingRight: '10px' }}>✅예약번호: {this.state.reservationSummary.reserveCode}</p>
                                                <p>🙎🏻‍♂️신청인: {this.state.reservationSummary.patient}</p>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <Table
                                rowKey='_id'
                                dataSource={cancelList}
                                columns={[
                                    {
                                        title: '정보확인',
                                        dataIndex: 'valid',
                                        align: 'center',
                                        width: '58px',
                                        render: (text, record) => (
                                            <Button
                                                style={{ border: 'none' }}
                                                onClick={e => {
                                                    e.stopPropagation();

                                                    this.setState({
                                                        showReservationSummary: true,
                                                        reservationSummary: {
                                                            reserveCode: record.reserveCode,
                                                            patient: record.patient,
                                                        },
                                                    });
                                                }}
                                            >
                                                <UserOutlined />
                                            </Button>
                                        ),
                                    },
                                    ...reserveColumn,
                                ]}
                                onRow={this.onRowReserve}
                                pagination={{
                                    pageSize: 10,
                                    current: this.props.service.servicePage,
                                    onChange: this.onChangePage,
                                }}
                                scroll={{ x: 600 }}
                            />
                        </TabPane>

                        <TabPane tab='서비스 진행상황' key='progress'>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <h3>
                                    <b>서비스 진행상황</b>
                                </h3>

                                {this.state.showReservationSummary && (
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                            float: 'inline-end',
                                            width: 'fit-content',
                                            border: '1px solid #9fd800',
                                        }}
                                    >
                                        <div style={{ padding: '5px 10px' }}>
                                            <p style={{ fontWeight: 'bold' }}>서비스예약 요약정보</p>
                                            <div style={{ display: 'flex' }}>
                                                <p style={{ paddingRight: '10px' }}>✅예약번호: {this.state.reservationSummary.reserveCode}</p>
                                                <p>🙎🏻‍♂️신청인: {this.state.reservationSummary.patient}</p>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <Table
                                rowKey='_id'
                                dataSource={list}
                                columns={[
                                    {
                                        title: '정보확인',
                                        dataIndex: 'valid',
                                        align: 'center',
                                        width: '58px',
                                        render: (text, record) => (
                                            <Button
                                                style={{ border: 'none' }}
                                                onClick={e => {
                                                    e.stopPropagation();

                                                    this.setState({
                                                        showReservationSummary: true,
                                                        reservationSummary: {
                                                            reserveCode: record.reserveCode,
                                                            patient: record.patient,
                                                        },
                                                    });
                                                }}
                                            >
                                                <UserOutlined />
                                            </Button>
                                        ),
                                    },
                                    ...reserveColumn,
                                ]}
                                onRow={this.onRowReserve}
                                pagination={{
                                    pageSize: 10,
                                    current: this.props.service.servicePage,
                                    onChange: this.onChangePage,
                                }}
                                scroll={{ x: 600 }}
                            />
                        </TabPane>
                    </Tabs>
                </div>
            </div>
        );
    }
}
export default ServiceList;
