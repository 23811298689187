import React from 'react';
import { connect } from 'react-redux';
import StyledPaymentListContainer from './list.styled';
import PaymentList from './list';
import Title from '../../../../Components/Common/pageTitle';
import { getPaymentList } from '../../../../Reducer/Modules/app';

class PaymentListPage extends React.PureComponent {
    componentDidMount = () => {
        this.props.fetchList();
    };

    render() {
        return (
            <StyledPaymentListContainer>
                <Title title='이용가격 목록' />
                <PaymentList {...this.props} />
            </StyledPaymentListContainer>
        );
    }
}
const mapStateToProps = ({ app, auth }) => ({
    app,
    auth,
});

const mapDispatchToProps = dispatch => ({
    fetchList: () => dispatch(getPaymentList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentListPage);
