import React from 'react';
import { connect } from 'react-redux';
import StyledCouponListContainer from './list.styled';
import CouponList from './list';
import Title from '../../../Components/Common/pageTitle';

import { getCouponList } from '../../../Reducer/Modules/app';
import { editServicePage } from '../../../Reducer/Modules/service';

class CouponListPage extends React.PureComponent {
    componentDidMount = () => {
        this.props.fetchCouponList();
    };

    render() {
        return (
            <StyledCouponListContainer>
                <Title title='쿠폰 목록' />
                <CouponList {...this.props} />
            </StyledCouponListContainer>
        );
    }
}
const mapStateToProps = ({ app, auth, service }) => ({
    app,
    auth,
    service
});

const mapDispatchToProps = dispatch => ({
    fetchCouponList: () => dispatch(getCouponList()),
    editPage: (page) => dispatch(editServicePage(page))
});

export default connect(mapStateToProps, mapDispatchToProps)(CouponListPage);
