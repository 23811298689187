import React from 'react';
import { connect } from 'react-redux';
import StyledServiceListContainer from './list.styled';
import ServiceList from './list';
import Title from '../../../../Components/Common/pageTitle';
import { getReserveList } from '../../../../Reducer/Modules/app';
import { editServicePage } from '../../../../Reducer/Modules/service';

class ServiceListPage extends React.PureComponent {
    componentDidMount = () => {
        this.props.fetchReserveList();
    };

    render() {
        return (
            <StyledServiceListContainer>
                <Title title='서비스 신청내역' />
                <ServiceList {...this.props} />
            </StyledServiceListContainer>
        );
    }
}
const mapStateToProps = ({ app, auth, service }) => ({
    app,
    auth,
    service
});

const mapDispatchToProps = dispatch => ({
    fetchReserveList: () => dispatch(getReserveList()),
    editPage: (page) => dispatch(editServicePage(page))
});

export default connect(mapStateToProps, mapDispatchToProps)(ServiceListPage);
