import React from 'react';
import { connect } from 'react-redux';
import TableContainer from './list.styled';
import PointList from './list';
import Title from '../../../Components/Common/pageTitle';

import axios from '../../../Lib/axios';

import { getPointList } from '../../../Reducer/Modules/app';
import { editServicePage } from '../../../Reducer/Modules/service';

class PointListPage extends React.PureComponent {
    componentDidMount = () => {
        this.fetchList();
    };

    fetchList = async () => {
        const { data } = await axios.get('/v2/user/users/point/history');
        if (data && Array.isArray(data)) {
            this.setState({ list: data });
        }
    };

    render() {
        return (
            <TableContainer>
                <Title title='포인트 지급 / 사용내역' />
                <PointList {...this.props} />
            </TableContainer>
        );
    }
}

const mapStateToProps = ({ app, auth, service }) => ({
    app,
    auth,
    service
});

const mapDispatchToProps = dispatch => ({
    editPage: (page) => dispatch(editServicePage(page))
});

export default connect(mapStateToProps, mapDispatchToProps)(PointListPage);
