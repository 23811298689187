import React from 'react';
import moment from 'moment';

export const mate = [
    {
        title: '이름',
        dataIndex: 'name',
        width: '120px',
        align: 'center',
    },
    {
        title: '성별',
        dataIndex: 'sex',
        width: '120px',
        align: 'center',
        render: sex => <span>{sex == '남' ? '남자' : '여자'}</span>
    },
    {
        title: '연락처',
        dataIndex: 'contact',
        align: 'center',
        width: '120px',
    },
    {
        title: '연결된 지원서 유무',
        dataIndex: 'apply',
        align: 'center',
        render: apply => <span>{apply && apply._id ? '있음' : '없음'}</span>,
    },
    {
        title: '수행 서비스 설정 여부',
        dataIndex: 'doProducts',
        align: 'center',
        render: doProducts => <span>{doProducts && doProducts.length > 0 ? '설정 완료' : '-'}</span>,
    },
    {
        title: '합격여부',
        dataIndex: 'allowed',
        align: 'center',
        render: allowed => <span>{allowed && allowed.type ? '합격' : '-'}</span>,
    },
    {
        title: '차단여부',
        dataIndex: 'blocked',
        align: 'center',
        render: blocked => <span>{blocked && blocked.state ? '차단됨' : '-'}</span>,
    },
    {
        title: '탈퇴여부',
        dataIndex: 'valid',
        align: 'center',
        render: valid => <span>{valid ? '-' : '탈퇴함'}</span>,
    },
];
