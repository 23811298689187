import React from 'react';
import moment from 'moment';
import axios from '../../../Lib/axios';
import { Container } from './item.styled';

export default class Item extends React.PureComponent {
    state = {
        data: null,
    };
    componentDidMount = () => {
        this.fetchInfo();
    };

    fetchInfo = async () => {
        const { info } = this.props.location.state;
        if (!info) return null;

        const { data } = await axios.get('/v1/message/detail', {
            params: {
                messageId: info._id,
            },
        });

        if (data && data._id) {
            this.setState({ data });
        }
    };
    render() {
        const { data } = this.state;
        if (!data || !data._id) return <></>;

        const { message, createdAt } = data;
        console.log(data);
        return (
            <Container>
                <div style={{ backgroundColor: 'white', padding: '16px', borderRadius: '16px' }}>
                    <h3>기본정보</h3>
                    <p>메시지</p>
                    <p>{message}</p>
                    <br />
                    <p>메시지 발생시각</p>
                    <p>{moment(createdAt).format('YY.MM.DD HH:mm')}</p>
                    <br />
                    <br />
                    <p>메이트 리포트</p>
                    <p>{(data.call && data.call.report) || '작성전'}</p>
                    <br />
                    <h3>연결된 예약정보 주요내용</h3>
                    {data.reserve && data.reserve._id ? (
                        <>
                            <p>예약번호</p>
                            <p>{data.reserve.reserveCode}</p>
                            <br />

                            <p>이용한 서비스</p>
                            <p>
                                {data.reserve.service === 'unemergency'
                                    ? '병원 동행 Pro'
                                    : data.reserve.service === 'assist'
                                    ? '병원 동행 Basic'
                                    : data.reserve.service === 'happycare'
                                    ? '해피케어'
                                    : '기타 서비스'}
                            </p>
                            <br />

                            <p>병원</p>
                            <p>{data.reserve.starting || '-'}</p>
                            <br />

                            <p>자택</p>
                            <p>{data.reserve.destination || '-'}</p>
                            <br />

                            <p>교통수단</p>
                            <p>{data.reserve.transport === 'common' ? '대중교통' : data.reserve.transport === 'own' ? '자차' : '위드메이트 차량'}</p>
                            <br />

                            <p>요청사항</p>
                            <p>{data.reserve.comment || '-'}</p>
                            <br />

                            <p>담당 메이트</p>
                            <p>{(data.call.mate && data.call.mate.length > 0 && data.call.mate[0].name) || '-'}</p>
                            <br />

                            <p>타임라인</p>
                            {data.call.mate && data.call.mate.length > 0 && data.call.timeline.receipt && (
                                <p>접선시각: {moment(data.call.timeline.receipt.time).format('YY.MM.DD HH:mm:ss')}</p>
                            )}
                            {data.call.mate && data.call.mate.length > 0 && data.call.timeline.start && (
                                <p>병원출발: {moment(data.call.timeline.start.time).format('YY.MM.DD HH:mm:ss')}</p>
                            )}
                            {data.call.mate && data.call.mate.length > 0 && data.call.timeline.arrived && (
                                <p>병원도착: {moment(data.call.timeline.arrived.time).format('YY.MM.DD HH:mm:ss')}</p>
                            )}
                            {data.call.mate && data.call.mate.length > 0 && data.call.timeline.careStart && (
                                <p>진료시작: {moment(data.call.timeline.careStart.time).format('YY.MM.DD HH:mm:ss')}</p>
                            )}
                            {data.call.mate && data.call.mate.length > 0 && data.call.timeline.careDone && (
                                <p>진료종료: {moment(data.call.timeline.careDone.time).format('YY.MM.DD HH:mm:ss')}</p>
                            )}
                            {data.call.mate && data.call.mate.length > 0 && data.call.timeline.treatmentStart && (
                                <p>치료시작: {moment(data.call.timeline.treatmentStart.time).format('YY.MM.DD HH:mm:ss')}</p>
                            )}
                            {data.call.mate && data.call.mate.length > 0 && data.call.timeline.treatmentDone && (
                                <p>치료종료: {moment(data.call.timeline.treatmentDone.time).format('YY.MM.DD HH:mm:ss')}</p>
                            )}

                            {/* 진료/치료(두 번째) */}
                            {data.call.mate && data.call.mate.length > 0 && data.call.timeline.careStart_second && (
                                <p>진료시작(두 번째): {moment(data.call.timeline.careStart_second.time).format('YY.MM.DD HH:mm:ss')}</p>
                            )}
                            {data.call.mate && data.call.mate.length > 0 && data.call.timeline.careDone_second && (
                                <p>진료종료(두 번째): {moment(data.call.timeline.careDone_second.time).format('YY.MM.DD HH:mm:ss')}</p>
                            )}
                            {data.call.mate && data.call.mate.length > 0 && data.call.timeline.treatmentStart_second && (
                                <p>치료시작(두 번째): {moment(data.call.timeline.treatmentStart_second.time).format('YY.MM.DD HH:mm:ss')}</p>
                            )}
                            {data.call.mate && data.call.mate.length > 0 && data.call.timeline.treatmentDone_second && (
                                <p>치료종료(두 번째): {moment(data.call.timeline.treatmentDone_second.time).format('YY.MM.DD HH:mm:ss')}</p>
                            )}

                            {/* 진료/치료(세 번째) */}
                            {data.call.mate && data.call.mate.length > 0 && data.call.timeline.careStart_third && (
                                <p>진료시작(세 번째): {moment(data.call.timeline.careStart_third.time).format('YY.MM.DD HH:mm:ss')}</p>
                            )}
                            {data.call.mate && data.call.mate.length > 0 && data.call.timeline.careDone_third && (
                                <p>진료종료(세 번째): {moment(data.call.timeline.careDone_third.time).format('YY.MM.DD HH:mm:ss')}</p>
                            )}
                            {data.call.mate && data.call.mate.length > 0 && data.call.timeline.treatmentStart_third && (
                                <p>치료시작(세 번째): {moment(data.call.timeline.treatmentStart_third.time).format('YY.MM.DD HH:mm:ss')}</p>
                            )}
                            {data.call.mate && data.call.mate.length > 0 && data.call.timeline.treatmentDone_third && (
                                <p>치료종료(세 번째): {moment(data.call.timeline.treatmentDone_third.time).format('YY.MM.DD HH:mm:ss')}</p>
                            )}

                            {data.call.mate && data.call.mate.length > 0 && data.call.timeline.backStart && (
                                <p>자택출발{moment(data.call.timeline.backStart.time).format('YY.MM.DD HH:mm:ss')}</p>
                            )}
                            {data.call.mate && data.call.mate.length > 0 && data.call.timeline.complete && (
                                <p>서비스종료{moment(data.call.timeline.complete.time).format('YY.MM.DD HH:mm')}</p>
                            )}
                        </>
                    ) : (
                        <p>없음</p>
                    )}
                </div>
            </Container>
        );
    }
}
