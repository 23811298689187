import styled from 'styled-components';
import { borderColor, mainBoldColor, mainColor, bgColor, bgDarkColor } from '../../../theme';

const NavStyledContainer = styled.div`
    .navbar-main {
        width: 260px;

        .navbar-container::-webkit-scrollbar {
            display: none;
        }

        .navbar-container {
            background-color: white;
            width: 100%;
            overflow: hidden;
            overflow-y: auto;
            height: 100vh;
            box-shadow: 1px 2px 4px #0e0e0e;
            -ms-overflow-style: none;

            .brand {
                text-align: center;
                padding: 20px;

                h2 {
                    margin: 0px;

                    .admin-logo {
                        width: 32px;
                        height: 32px;
                        margin-right: 10px;
                    }
                }
            }

            .navigation-section {
                .admin-user {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 200px;

                    .admin-info {
                        width: auto;
                        height: 96px;
                        border-radius: 50%;
                        overflow: hidden;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        .admin-img {
                            width: auto;
                            height: 50%;
                            objectfit: cover;
                        }
                    }

                    .admin-user-info {
                        margin-left: 10px;

                        .user-name {
                            margin-bottom: 5px;
                            text-align: center;
                            color: black;
                            font-size: 18px;
                            font-weight: bold;
                        }

                        .user-email {
                            font-weight: bold;
                        }
                    }
                }
            }

            .single-navigate {
                overflow: hidden;
                padding: 12px 24px;
                padding-left: 42px;

                svg {
                    width: 26px;
                    align-items: flex-start;
                    justify-content: flex-start;
                    text-align: left;
                }

                i {
                    margin-right: 6px;
                }
            }

            .inner-navigate {
                overflow: hidden;
                padding: 12px 38px;
                svg {
                    width: 26px;
                    align-items: flex-start;
                    justify-content: flex-start;
                    text-align: left;
                }
            }
        }
    }

    .ant-collapse {
        background-color: white;
        overflow: hidden;
    }

    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
        background-color: white;
        color: rgba(0, 0, 0, 0.6);
        font-weight: bold;
        padding: 12px 24px;

        text-indent: 26px;
    }

    .ant-collapse-borderless > .ant-collapse-item {
        border: 0px;
    }

    .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
        left: 6px;
    }

    .ant-collapse-content > .ant-collapse-content-box {
        padding: 0;
        text-align: left;
        padding-left: 15px;
        background-color: white;
    }

    @media (max-width: 832px) {
        .navbar-main {
            width: 0px;

            .navbar-container {
                width: 120px;

                overflow-x: hidden;
                overflow-y: scroll;

                .brand {
                    padding: 15px 5px;

                    .h2 {
                        img {
                            width: 110px;
                            height: auto;
                        }
                    }
                }

                .single-navigate {
                    padding: 15px 5px;
                    padding-left: 42px;
                }

                .inner-navigate {
                    padding: 15px 15px;
                }
            }
        }

        .ant-collapse > .ant-collapse-item > .ant-collapse-header {
            background-color: white;
            color: rgba(0, 0, 0, 0.6);
            padding: 15px 5px;

            text-indent: 26px;
        }

        .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
            left: -10px;
        }
    }
`;

export default NavStyledContainer;
