import React from 'react';
import { Switch, Route } from 'react-router-dom';

// pages
import Create from './Create';
import List from './List';
import Info from './Info';

class Service extends React.PureComponent {
    render() {
        return (
            <Switch>
                <Route exact path='/newsEvent/create' component={Create} />
                <Route exact path='/newsEvent/list' component={List} />
                <Route exact path='/newsEvent/info/:id' component={Info} />
            </Switch>
        );
    }
}
export default Service;
