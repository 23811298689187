import styled from 'styled-components';
const StyledUserContainer = styled.div`
    .button-wrapper {
        width: 80%;
        margin-top: 20px;
    }

    img {
        width: 200px;
        height: 200px;
    }
`;
export default StyledUserContainer;
