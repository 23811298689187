import React from 'react';
import { connect } from 'react-redux';

import EmailList from './email';
import Title from '../../Components/Common/pageTitle';

import StyledEmailListContainer from './email.styled';

import { getAuthCodeList } from '../../Reducer/Modules/app';

class EmailCodeListPage extends React.PureComponent {
    componentDidMount = () => {
        this.props.getAuthCodeList();
    };

    render() {
        return (
            <StyledEmailListContainer>
                <Title title='이메일 인증 코드 목록' />
                <EmailList {...this.props} />
            </StyledEmailListContainer>
        );
    }
}
const mapStateToProps = ({ app, auth }) => ({
    app,
    auth,
});

const mapDispatchToProps = dispatch => ({
    getAuthCodeList: () => dispatch(getAuthCodeList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EmailCodeListPage);