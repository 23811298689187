import React from 'react';
import { Switch, Route } from 'react-router-dom';

// pages
import List from './List';
import Info from './Info';

class Card extends React.PureComponent {
    render() {
        return (
            <Switch>
                <Route exact path='/card/list' component={List} />
                <Route exact path='/card/info/:id' component={Info} />
            </Switch>
        );
    }
}
export default Card;