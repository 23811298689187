import React from 'react';
import { Table, Input } from 'antd';
import { ETCColumn } from '../../../Components/Columns/service';

class ServiceList extends React.PureComponent {
    state = {
        keyword: '',
    };

    onRowReserve = (row, index) => {
        return {
            onClick: () => {
                this.props.history.push({
                    pathname: `/etc/service/info`,
                    state: {
                        info: row,
                    },
                });
            },
        };
    };

    render() {
        const { speicalRequests } = this.props.service;

        return (
            <div className='container'>
                <div className='table'>
                    <Table rowKey='_id' dataSource={speicalRequests} columns={ETCColumn} onRow={this.onRowReserve} />
                </div>
            </div>
        );
    }
}

export default ServiceList;
