import jwt from 'jsonwebtoken';
import * as authAPI from './Api/auth';

export function createReduxThunk(type, request) {
    const SUCCESS = `${type}_SUCCESS`;
    const FAILURE = `${type}_FAILURE`;

    return params => async dispatch => {
        dispatch({ type: 'app/LOADING_ON' });
        if (!request) {
            return dispatch({ type });
        }

        try {
            const response = await request(params);
            dispatch({
                type: SUCCESS,
                payload: response.data,
            });
            dispatch({ type: 'app/LOADING_OFF' });
            return response.data;
        } catch (e) {
            dispatch({
                type: FAILURE,
                payload: e,
                error: true,
            });
            dispatch({ type: 'app/LOADING_OFF' });
            throw e;
        }
    };
}
