import React from 'react';
import { Table, Input, Button, message } from 'antd';

import axios from '../../../Lib/axios';

import { CorpColumns } from '../../../Components/Columns/service';
import { Container, TableWrapper } from './list.styled';

class List extends React.PureComponent {
    goAddPage = () => {
        this.props.history.push('/corp/create');
    };

    onRow = row => {
        return {
            onClick: async () => {
                const confirmed = window.confirm(row.name + '을 목록에서 제거하시겠습니까?');
                if (!confirmed) return null;

                const { data } = await axios.post('/v1/corp/delete', {
                    corpId: row._id,
                });

                if (data === 'success') {
                    message.success('제거하였습니다.');

                    setTimeout(() => {
                        window.location.reload();
                    }, 1200);
                }
            },
        };
    };

    render() {
        const { corpList } = this.props.service;

        return (
            <Container>
                <TableWrapper>
                    <Table rowKey='_id' dataSource={corpList} columns={CorpColumns} onRow={this.onRow} />
                </TableWrapper>
                <Button type='primary' onClick={this.goAddPage}>
                    추가하기
                </Button>
            </Container>
        );
    }
}
export default List;
