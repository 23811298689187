import React from 'react';
import { Switch, Route } from 'react-router-dom';

// pages
import List from './List';
import Info from './Info';

class Event extends React.PureComponent {
    render() {
        return (
            <Switch>
                <Route exact path='/event/list' component={List} />
                <Route exact path='/event/info/:id' component={Info} />
            </Switch>
        );
    }
}
export default Event;
