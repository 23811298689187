import React from 'react';
import { connect } from 'react-redux';
import StyledMateApplyListContainer from './list.styled';
import MateApplyList from './list';
import Title from '../../../Components/Common/pageTitle';

import { getMateApplyList } from '../../../Reducer/Modules/app';
import { editServicePage } from '../../../Reducer/Modules/service';

class MateApplyListPage extends React.PureComponent {
    componentDidMount = () => {
        this.props.fetchMateApplyList();
    };

    render() {
        return (
            <StyledMateApplyListContainer>
                <Title title='메이트 신청서 목록' />
                <MateApplyList {...this.props} />
            </StyledMateApplyListContainer>
        );
    }
}
const mapStateToProps = ({ app, auth, service }) => ({
    app,
    auth,
    service,
});

const mapDispatchToProps = dispatch => ({
    fetchMateApplyList: () => dispatch(getMateApplyList()),
    editPage: page => dispatch(editServicePage(page)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MateApplyListPage);
