import React from 'react';
import { connect } from 'react-redux';

// component
import Home from './home';

// styles
import HomeStyledContainer from './home.styled';

import { getMateApplyList, getNewsEventList, getUserCount, getUserList, getMateCount, getReserveCount } from '../../Reducer/Modules/app';
import { getEventList } from '../../Reducer/Modules/event';

// actions
// import {} from '../../Redux/Modules/';

class AuthContainer extends React.PureComponent {
    componentDidMount = () => {
        const { id } = this.props.auth;
        this.props.fetchList(id);
        this.props.fetchMateCount();
        this.props.fetchUserCount();
        this.props.fetchMateApplyList();
        this.props.fetchEventList();
        this.props.fetchNewsEventList();
        this.props.fetchReserveCount();
    };

    render() {
        return (
            <HomeStyledContainer>
                <Home {...this.props} />
            </HomeStyledContainer>
        );
    }
}

const mapStateToProps = ({ app, auth, event }) => ({
    app,
    auth,
    event,
});

const mapStateToDispatch = dispatch => ({
    fetchList: id => dispatch(getUserList({ id })),
    fetchMateCount: () => dispatch(getMateCount()),
    fetchUserCount: () => dispatch(getUserCount()),
    fetchEventList: () => dispatch(getEventList()),
    fetchNewsEventList: () => dispatch(getNewsEventList()),
    fetchMateApplyList: () => dispatch(getMateApplyList()),
    fetchReserveCount: () => dispatch(getReserveCount()),
    somethingAction: () => dispatch('actions'),
});

// export default AuthContainer;
export default connect(mapStateToProps, mapStateToDispatch)(AuthContainer);
