import React from 'react';
import { connect } from 'react-redux';
import Title from '../../../Components/Common/pageTitle';
import { TableWrapper } from './create.styled';
import Create from './create';
import { getUserList } from '../../../Reducer/Modules/app';

class CreateContainer extends React.PureComponent {
    componentDidMount = () => {
        const { id } = this.props.auth;
        this.props.fetchList(id);
    };

    render() {
        return (
            <TableWrapper>
                <Title title='포인트 지급' />
                <Create {...this.props} />
            </TableWrapper>
        );
    }
}

const mapStateToProps = ({ app, auth }) => ({
    app,
    auth,
});

const mapDispatchToProps = dispatch => ({
    fetchList: ({ page }) => dispatch(getUserList({ page })),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateContainer);
