import React from 'react';
import { connect } from 'react-redux';
import StyledReviewCreateContainer from './create.styled';
import ReviewCreate from './create';
import Title from '../../../../Components/Common/pageTitle';
import { getUserList, getMateList } from '../../../../Reducer/Modules/app';

class ReviewCreatePage extends React.PureComponent {
    componentDidMount = () => {
        const { id } = this.props.auth;
        this.props.fetchUserList(id);
        this.props.fetchMateList(id);
    };

    render() {
        return (
            <StyledReviewCreateContainer>
                <Title title='리뷰 추가하기' />
                <ReviewCreate {...this.props} />
            </StyledReviewCreateContainer>
        );
    }
}
const mapStateToProps = ({ app, auth }) => ({
    app,
    auth,
});

const mapDispatchToProps = dispatch => ({
    fetchUserList: ({ page }) => dispatch(getUserList({ page })),
    fetchMateList: id => dispatch(getMateList({ id })),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReviewCreatePage);
