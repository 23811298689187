import React from 'react';
import moment from 'moment';
import { DeleteOutlined, SearchOutlined } from '@ant-design/icons';

export const apply = ({ onDelete }) => {
    return [
        {
            title: '지원일자',
            dataIndex: 'createdAt',
            align: 'center',
            render: createdAt => <span>{createdAt ? moment(createdAt).format('YYYY년MM월DD일 HH시MM분') : '-'}</span>,
        },
        {
            title: '지원자 이름',
            dataIndex: 'form',
            align: 'center',
            render: form => <span>{(form && form.info && form.info.length > 0 && form.info[0].value) || '-'}</span>,
        },
        {
            title: '이메일',
            dataIndex: '_id',
            align: 'center',
            render: (_id, { form }) => <span>{(form && form.info && form.info.length > 2 && form.info[2].value) || '-'}</span>,
        },
        {
            title: '연락처',
            dataIndex: 'contact',
            align: 'center',
            render: (_id, { form }) => <span>{(form && form.info && form.info.length > 2 && form.info[10].value) || '-'}</span>,
        },
        {
            title: '가입 경로',
            dataIndex: 'path',
            align: 'center',
            render: (_id, { form }) => <span>{(form && form.info && form.info.length > 2 && form.info[9].value) || '-'}</span>,
            // filters: [
            //     { text: '고양시', value: '고양시' },
            //     { text: '암센터', value: '암센터' },
            //     { text: '서대문', value: '서대문' },
            //     { text: '일자리', value: '일자리' },
            //     { text: '복지넷', value: '복지넷' },
            //     { text: '여성', value: '여성' },
            //     { text: '검색', value: '검색' },
            //     { text: '뉴스', value: '뉴스' },
            // ],
            // onFilter: (value, record) => record.form.info[9].value.indexOf(value) === 0,
            // sorter:(a, b) => a.form.info[9].value.localeCompare(b.form.info[9].value)
        },
        {
            title: '확인여부',
            dataIndex: 'checked',
            align: 'center',
            render: checked => <span>{checked === 'true' || checked === true ? '확인완료' : '-'}</span>,
        },
        {
            title: '합격여부',
            dataIndex: 'mate',
            align: 'center',
            render: mate => <span>{mate && mate.allowed && mate.allowed.type ? '합격' : '-'}</span>,
        },
        {
            title: '확인일자',
            dataIndex: 'checkedDate',
            align: 'center',
            render: checkedDate => <span>{checkedDate ? moment(checkedDate).format('YYYY년MM월DD일 HH시MM분') : '-'}</span>,
        },
        {
            title: '삭제',
            dataIndex: 'createdAt',
            align: 'center',
            width: '58px',
            render: (createdAt, { _id }) => (
                <DeleteOutlined
                    onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();

                        onDelete({ _id });
                    }}
                />
            ),
        },
    ];
};

// mate apply new
export const mateApply = ({ onDelete }) => {
    return [
        {
            title: '지원일자',
            dataIndex: 'createdAt',
            align: 'center',
            render: createdAt => <span>{createdAt ? moment(createdAt).format('YYYY년MM월DD일 HH시MM분') : '-'}</span>,
        },
        {
            title: '지원자 이름',
            dataIndex: 'name',
            align: 'center',
        },
        {
            title: '이메일',
            dataIndex: 'email',
            align: 'center',
        },
        {
            title: '연락처',
            dataIndex: 'contact',
            align: 'center',
        },
        {
            title: '가입 경로',
            dataIndex: 'connection',
            align: 'center',
            // filters: [
            //     { text: '한국직업평가진흥협회', value: '한국직업평가진흥협회' },
            //     { text: '검색', value: '검색' },
            //     { text: '광고', value: '광고' },
            //     { text: '구직사이트', value: '구직사이트' },
            //     { text: '뉴스기사', value: '뉴스기사' },
            //     { text: '대신위드유', value: '대신위드유' },
            //     { text: '에이플러스', value: '에이플러스' },
            //     { text: '복지넷', value: '복지넷' },
            //     { text: '강동 50+', value: '강동 50+' },
            //     { text: '서대문 50+', value: '서대문 50+' },
            //     { text: '국립 암센터', value: '국립 암센터' },
            //     { text: '지인', value: '지인' },
            //     { text: '카페', value: '카페•블로그' },
            //     { text: '기타', value: '기타' },
            // ],
            // onFilter: (value, record) => record.connection.indexOf(value) === 0,
            // sorter: (a, b) => a.connection.localeCompare(b.connection),
        },
        {
            title: '확인여부',
            dataIndex: 'checked',
            align: 'center',
            render: checked => <span>{checked === 'true' || checked === true ? '확인완료' : '-'}</span>,
        },
        {
            title: '합격여부',
            dataIndex: 'mate',
            align: 'center',
            render: mate => <span>{mate && mate.allowed && mate.allowed.type ? '합격' : '-'}</span>,
        },
        {
            title: '확인일자',
            dataIndex: 'checkedDate',
            align: 'center',
            render: checkedDate => <span>{checkedDate ? moment(checkedDate).format('YYYY년MM월DD일 HH시MM분') : '-'}</span>,
        },
        {
            title: '삭제',
            dataIndex: 'valid',
            align: 'center',
            width: '58px',
            render: (valid, { _id }) => (
                <DeleteOutlined
                    onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();

                        onDelete({ _id });
                    }}
                />
            ),
        },
    ];
};

export const user = [
    {
        title: '이름',
        dataIndex: 'name',
        width: '120px',
        align: 'center',
    },
    {
        title: '연락처',
        dataIndex: 'contact',
        align: 'center',
        width: '120px',
    },
    {
        title: '보유 포인트',
        dataIndex: 'point',
        align: 'center',
        width: '120px',
        render: point => <span>{point ? point + '원' : '-'}</span>,
    },
    {
        title: '차단여부',
        dataIndex: 'blocked',
        align: 'center',
        width: '100px',
        render: blocked => <span>{blocked.state ? '차단됨' : '-'}</span>,
    },
    {
        title: '탈퇴여부',
        dataIndex: 'valid',
        align: 'center',
        width: '100px',
        render: valid => <span>{valid ? '-' : '탈퇴함'}</span>,
    },
    {
        title: '가입경로',
        dataIndex: 'connection',
        align: 'center',
        width: '120px',
    },
    {
        title: '가입일자',
        dataIndex: 'createdAt',
        align: 'center',
        width: '120px',
        render: createdAt => <span>{moment(createdAt).format('YYYY년 MM월 DD일')}</span>,
    },
];

export const code = [
    {
        title: '이메일',
        dataIndex: 'email',
        align: 'center',
    },
    {
        title: '인증 코드',
        dataIndex: 'code',
        align: 'center',
    },
    {
        title: '인증 날짜',
        dataIndex: 'registeredAt',
        align: 'center',
        render: registeredAt => <span>{registeredAt ? moment(registeredAt).format('YYYY년MM월DD일 HH시MM분') : '-'}</span>,
    },
];
