import React from 'react';
import { connect } from 'react-redux';
import StyledServiceContainer from './service.styled';
import ServicePages from './service';

class ServiceRoutes extends React.PureComponent {
    render() {
        return (
            <StyledServiceContainer>
                <ServicePages {...this.props} />
            </StyledServiceContainer>
        );
    }
}
const mapStateToProps = ({}) => ({});
const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ServiceRoutes);
