import React from 'react';
import { connect } from 'react-redux';
import StyledContainer from './list.styled';
import List from './list';
import Title from '../../../Components/Common/pageTitle';
import { getShareList } from '../../../Reducer/Modules/service';

class ShareListPage extends React.PureComponent {
    componentDidMount = () => {
        this.props.fetchList();
    };

    render() {
        return (
            <StyledContainer>
                <Title title='셔틀 셰어링 목록' />
                <List {...this.props} />
            </StyledContainer>
        );
    }
}
const mapStateToProps = ({ app, auth, service }) => ({
    app,
    auth,
    service
});

const mapDispatchToProps = dispatch => ({
    fetchList: () => dispatch(getShareList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShareListPage);
