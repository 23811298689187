import React from 'react';
import { Table, Input } from 'antd';
import { code } from '../../Components/Columns/user';

class ServiceList extends React.PureComponent {
    onChangePage = page => {
        // console.log(page);
        this.props.editPage(page);
    };

    render() {
        // console.log(this.props.app.codeList.data)

        return (
            <div className='container'>
                <div className='table'>
                    <Table
                        rowKey='_id'
                        dataSource={this.props.app.codeList.data}
                        columns={code}
                        pagination={{
                            pageSize: 10,
                        }}
                        scroll={{ x: 500 }}
                    />
                </div>
            </div>
        );
    }
}
export default ServiceList;
