import React from 'react';
import { connect } from 'react-redux';

// component
import Auth from './auth';

// styles
import AuthStyledContainer from './auth.styled';

// actions
import { onEditAuthForm, login } from '../../Reducer/Modules/auth';

class AuthContainer extends React.PureComponent {
    render() {
        return (
            <AuthStyledContainer>
                <Auth {...this.props} />
            </AuthStyledContainer>
        );
    }
}

const mapStateToProps = ({ auth }) => ({
    auth,
});

const mapStateToDispatch = dispatch => ({
    editForm: info => dispatch(onEditAuthForm(info)),
    login: info => dispatch(login(info)),
});

export default connect(mapStateToProps, mapStateToDispatch)(AuthContainer);
