import React from 'react';
import { Input, Checkbox, Button, message, Select } from 'antd';

import Title from '../../../Components/Common/pageTitle';
import { TableWrapper, TableContainer, Label, ValueBox } from './create.styled';

import axios from '../../../Lib/axios';

export default class ProductCreate extends React.PureComponent {
    state = {
        value: 0,
        selected: null,
        name: '',
    };

    onAdd = async () => {
        const { selected, value, name } = this.state;
        if (!selected || !value || !name) {
            return message.warning('대상, 포인트, 포인트명을 설정해주세요.');
        }

        const code = window.prompt('지급 코드를 입력해주세요.' + '\n' + '(코드: PERFECT_WITHMATE_POINT)');
        if (code) {
            const { data } = await axios.post('/v2/user/add/point', {
                userId: selected,
                info: {
                    value,
                    name,
                    appointedCode: code,
                },
            });
            if (!data || data === 'check parameters') {
                return message.error('서버 장애');
            }

            if (data === 'wrong access') {
                return message.warning('잘못된 시도입니다.');
            }

            message.success('지급 완료');
            setTimeout(() => {
                this.props.history.goBack();
            }, 250);
        }
    };

    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    getFilteredList = () => {
        const { user } = this.props.app;
        const { keyword } = this.state;

        if (!user) return [];

        return user.filter(m => {
            if (m.name && keyword && m.name.toLowerCase().includes(keyword.toLowerCase())) return true;
            if (m.contact && keyword && m.contact.toLowerCase().includes(keyword.toLowerCase())) return true;
            return false;
        });
    };

    render() {
        const filteredList = !this.state.keyword ? this.props.app.user : this.getFilteredList();
        console.log(this.state);
        return (
            <>
                <TableWrapper>
                    <TableContainer>
                        <ValueBox>
                            <Label>지급할 대상</Label>
                            <Input type='text' name='keyword' value={this.state.keyword} onChange={this.handleChange} placeholder='지급 대상을 검색해보세요' />
                            <Select
                                style={{ width: '100%' }}
                                value={this.state.selected || undefined}
                                placeholder='지급 대상을 선정해주세요.'
                                onChange={value => {
                                    this.setState({ selected: value });
                                }}
                            >
                                {filteredList &&
                                    filteredList.length > 0 &&
                                    filteredList.map((u, index) => (
                                        <Select.Option key={'user-row-' + index.toString()} value={u._id}>
                                            {u.name}
                                        </Select.Option>
                                    ))}
                            </Select>
                        </ValueBox>

                        <ValueBox>
                            <Label>포인트 명 (ex. 웰컴 포인트)</Label>
                            <Input type='text' placeholder='포인트 명을 입력해주세요' value={this.state.name} name='name' onChange={this.handleChange} />
                        </ValueBox>

                        <ValueBox>
                            <Label>지급 금액</Label>
                            <Input type='number' placeholder='1500' value={this.state.value} name='value' onChange={this.handleChange} />
                        </ValueBox>

                        <Button type='primary' onClick={this.onAdd}>
                            지급하기
                        </Button>
                    </TableContainer>
                </TableWrapper>
            </>
        );
    }
}
