import React from 'react';
import StyledPaymentContainer from './payment.styled';
import PaymentPages from './payment';

class PaymentRoutes extends React.PureComponent {
    render() {
        return (
            <StyledPaymentContainer>
                <PaymentPages {...this.props} />
            </StyledPaymentContainer>
        );
    }
}

export default PaymentRoutes;
