import React from 'react';
import { connect } from 'react-redux';

// component
import Register from './register';

// styles
import RegisterStyledContainer from './register.styled';

// actions
import { onEditAuthForm, login } from '../../../Reducer/Modules/auth';

class RegisterContainer extends React.PureComponent {
    render() {
        return (
            <RegisterStyledContainer>
                <Register {...this.props} />
            </RegisterStyledContainer>
        );
    }
}

const mapStateToProps = ({ auth }) => ({
    auth,
});

const mapStateToDispatch = dispatch => ({
    editForm: info => dispatch(onEditAuthForm(info)),
    login: info => dispatch(login(info)),
});

export default connect(mapStateToProps, mapStateToDispatch)(RegisterContainer);
