import React from 'react';
import { Radio, Button, Input, message } from 'antd';

// component
import Title from '../../Components/Common/pageTitle';

// utils
import axios from '../../Lib/axios';

// styled
import {
    Container,
    InnerContainer,
    Wrapper,
    Label,
    Question,
    Placeholder,
    MaxLength,
    AddNewQnaWrapper,
    MainTitle,
    SubTitle,
    InputWrapper,
} from './form.styled';

class ApplyForm extends React.PureComponent {
    state = {
        info: [],
        newRow: {},
        photoFst: false,
        photoSnd: false,
        fileFst: false,
        fileSnd: false,
    };

    componentDidMount = () => {
        this.getRecentForm();
    };

    getRecentForm = async () => {
        const { data: form } = await axios.get('/v1/admin/recent/apply/form');
        this.setState({ ...form, info: JSON.parse(JSON.stringify(form.info)) });
    };

    onSaveForm = async () => {
        const { info, photoFst, photoSnd, fileFst, fileSnd } = this.state;
        if (!info || info.length === 0) {
            return message.info('질문을 하나 이상으로 설정해주세요.');
        }

        const { data } = await axios.post('/v1/admin/add/mate/apply/form', {
            info,
            photoFst,
            photoSnd,
            fileFst,
            fileSnd,
        });

        if (data === 'success') {
            return message.success('지원서 양식을 업데이트 하였습니다.');
        } else {
            return message.success('지원서 양식을 업데이트를 실패하였습니다.');
        }
    };

    onHandleForm = (type, index) => {
        const { newRow, info } = this.state;
        const { question, placeholder, maxLength } = newRow;
        const _info = JSON.parse(JSON.stringify(info)); // info를 String객체로 변환시키고 변환된 String객체를 다시 json객체로 변화시킨다

        if (type === 'add') {
            console.log(newRow);
            if (!newRow) return message.info('질문을 먼저 작성해주세요.');
            if (!question) return message.info('질문을 먼저 작성해주세요.');
            if (!placeholder) return message.info('플레이스홀더(질문 설명글)을 먼저 작성해주세요.');

            _info.push(newRow);
            this.setState({ info: _info, newRow: {} });
        }

        if (type === 'remove') {
            _info.splice(index, 1);
            this.setState({ info: _info });
        }
    };

    inputHandler = ({ type, value }) => {
        this.setState({ [type]: value });
    };

    render() {
        return (
            <>
                <Title title='메이트 신청서 관리' />
                <h4>1,2,3 번항목은 변경하지 말아주세요~</h4>
                <Container>
                    <InnerContainer>
                        {this.state.info && Array.isArray(this.state.info) && this.state.info.length > 0
                            ? this.state.info.map((item, index) => (
                                  <Wrapper key={'question-' + index + 'answer'}>
                                      <Question>
                                          질문({index + 1}): {item.question}
                                      </Question>
                                      <Placeholder>
                                          플레이스홀더({index + 1}): {item.placeholder}
                                      </Placeholder>
                                      <MaxLength>
                                          글자수제한({index + 1}): {item.maxLength}
                                      </MaxLength>
                                      <Button
                                          type='primary'
                                          onClick={() => {
                                              this.onHandleForm('remove', index);
                                          }}
                                      >
                                          질문 제거
                                      </Button>
                                  </Wrapper>
                              ))
                            : null}

                        <AddNewQnaWrapper>
                            <MainTitle>새 질문생성</MainTitle>
                            <SubTitle>질문</SubTitle>
                            <InputWrapper>
                                <Input
                                    onChange={ev => {
                                        this.inputHandler({ type: 'newRow', value: { ...this.state.newRow, question: ev.target.value } });
                                    }}
                                    value={this.state.newRow && this.state.newRow.question ? this.state.newRow.question : ''}
                                    type='text'
                                    placeholder='해당 질문의 제목을 정해주세요.'
                                />
                            </InputWrapper>
                            <SubTitle>플레이스홀더</SubTitle>
                            <InputWrapper>
                                <Input
                                    onChange={ev => {
                                        this.inputHandler({ type: 'newRow', value: { ...this.state.newRow, placeholder: ev.target.value } });
                                    }}
                                    value={this.state.newRow && this.state.newRow.placeholder ? this.state.newRow.placeholder : ''}
                                    type='text'
                                    placeholder='해당 질문을 설명할수있는 내용을 포함한, 입력창에 보여줄 정보를 정해주세요.'
                                />
                            </InputWrapper>
                            <SubTitle>글자수제한</SubTitle>
                            <InputWrapper>
                                <Input
                                    onChange={ev => {
                                        this.inputHandler({ type: 'newRow', value: { ...this.state.newRow, maxLength: ev.target.value } });
                                    }}
                                    value={this.state.newRow && this.state.newRow.maxLength ? this.state.newRow.maxLength : ''}
                                    type='number'
                                    placeholder='글자수 제한을 희망할경우 설정해주세요.'
                                />
                            </InputWrapper>
                            <Button
                                type='primary'
                                onClick={() => {
                                    this.onHandleForm('add');
                                }}
                            >
                                질문 추가하기
                            </Button>
                        </AddNewQnaWrapper>

                        <Wrapper>
                            <Label>사진(1) 사용</Label>
                            <Radio.Group
                                value={this.state.photoFst}
                                onChange={e => {
                                    this.inputHandler({ type: 'photoFst', value: e.target.value });
                                }}
                            >
                                <Radio value={true}>사용</Radio>
                                <Radio value={false}>미사용</Radio>
                            </Radio.Group>
                        </Wrapper>
                        <Wrapper>
                            <Label>사진(2) 사용</Label>
                            <Radio.Group
                                value={this.state.photoSnd}
                                onChange={e => {
                                    this.inputHandler({ type: 'photoSnd', value: e.target.value });
                                }}
                            >
                                <Radio value={true}>사용</Radio>
                                <Radio value={false}>미사용</Radio>
                            </Radio.Group>
                        </Wrapper>
                        <Wrapper>
                            <Label>파일(1) 사용</Label>
                            <Radio.Group
                                value={this.state.fileFst}
                                onChange={e => {
                                    this.inputHandler({ type: 'fileFst', value: e.target.value });
                                }}
                            >
                                <Radio value={true}>사용</Radio>
                                <Radio value={false}>미사용</Radio>
                            </Radio.Group>
                        </Wrapper>
                        <Wrapper>
                            <Label>파일(2) 사용</Label>
                            <Radio.Group
                                value={this.state.fileSnd}
                                onChange={e => {
                                    this.inputHandler({ type: 'fileSnd', value: e.target.value });
                                }}
                            >
                                <Radio value={true}>사용</Radio>
                                <Radio value={false}>미사용</Radio>
                            </Radio.Group>
                        </Wrapper>
                        <Wrapper>
                            <Button style={{ width: '100%' }} type='primary' onClick={this.onSaveForm}>
                                현재 폼으로 지원서 양식 저장
                            </Button>
                        </Wrapper>
                    </InnerContainer>
                </Container>
            </>
        );
    }
}

export default ApplyForm;
