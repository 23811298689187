import styled from 'styled-components';

export const TableWrapper = styled.div`
    padding: 24px;
`;

export const TableContainer = styled.div`
    padding: 24px;
    background-color: #ffffff;
    border-radius: 4px;
`;

export const Label = styled.p`
    text-align: left;
    font-size: 11px;
    margin-bottom: 4px;
    color: #565656;
`;

export const ValueBox = styled.div`
    margin-bottom: 16px;
`;

const StyledNewsEventContainer = styled.div`
`;


export default StyledNewsEventContainer;