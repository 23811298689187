export const BASE_URL = 'https://server.withmate.kr';
export const DEV = false;

export const COMPANY = 'WITHMATE';
export const USER = 'user';
export const MATE = 'mate';
export const LOCAL = 'local';
export const KAKAO = 'kakao';
export const ASSIST = 'assist';
export const UNEMER = 'unemergency';
export const HAPPY_CARE = 'happycare';
