import { createAction, handleActions } from 'redux-actions';
import produce from 'immer';
import * as authAPI from '../../Lib/Api/auth';

// middleware
import { createReduxThunk } from '../../Lib/middleware';

// initalState
const initialState = {
    id: '',
    email: '',
    photo: '',
    password: '',
    name: '',
    alert: '',
    userPage: 1,
    matePage: 1,
};

// type
const EDIT_AUTHFORM = 'auth/EDIT_AUTHFORM';
const LOGIN = 'auth/LOGIN';
const LOGIN_SUCCESS = 'auth/LOGIN_SUCCESS';
const LOGOUT = 'auth/LOGOUT';
const LOGOUT_SUCCESS = 'auth/LOGOUT_SUCCESS';
const CHECK_AUTH = 'auth/CHECK_AUTH';
const CHECK_AUTH_SUCCESS = 'auth/CHECK_AUTH_SUCCESS';
const USER_BLOCK = 'auth/USER_BLOCK';
const USER_BLOCK_SUCCESS = 'auth/USER_BLOCK_SUCCESS';

const RESET_PASSWORD = 'auth/RESET_PASSWORD';
const RESET_PASSWORD_SUCCESS = 'auth/RESET_PASSWORD_SUCCESS';

const EDIT_USER_PAGE = 'auth/EDIT_USER_PAGE';
const EDIT_MATE_PAGE = 'auth/EDIT_MATE_PAGE';

//action
export const onEditAuthForm = createAction(EDIT_AUTHFORM, input => input);
export const login = createReduxThunk(LOGIN, authAPI.login);
export const logout = createReduxThunk(LOGOUT, authAPI.logout);
export const checkAuth = createReduxThunk(CHECK_AUTH, authAPI.checkAuth);
export const userBlock = createReduxThunk(USER_BLOCK, authAPI.userBlock, meta => meta);

export const resetPassword = createReduxThunk(RESET_PASSWORD, authAPI.resetPassword);

export const editUserPage = createAction(EDIT_USER_PAGE, input => input);
export const editMatePage = createAction(EDIT_MATE_PAGE, input => input);

// reducer
const appReducer = handleActions(
    {
        [EDIT_USER_PAGE]: (state, { payload }) =>
            produce(state, draft => {
                draft.userPage = payload;
            }),

        [EDIT_MATE_PAGE]: (state, { payload }) =>
            produce(state, draft => {
                draft.matePage = payload;
            }),

        [EDIT_AUTHFORM]: (state, { payload }) =>
            produce(state, draft => {
                draft[payload.type] = payload.text;
            }),
        [LOGIN_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                console.log('login: ', payload);
                if (payload && payload.id) {
                    draft['id'] = payload.id;
                    draft['admin'] = payload.admin;
                    draft['name'] = payload.name;
                    draft['photo'] = payload.photo;

                    localStorage.setItem('id', payload.id);
                    localStorage.setItem('admin', payload.admin);
                    localStorage.setItem('name', payload.name);
                    localStorage.setItem('photo', payload.photo);

                    window.location.href = '/';
                }
            }),
        [LOGOUT_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                if (payload && payload === 'success') {
                    draft['id'] = '';
                    draft['photo'] = '';
                    draft['name'] = '';
                    localStorage.removeItem('admin', 'name', 'id');
                    window.location.href = '/auth';
                }
            }),
        [CHECK_AUTH_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                if (payload === 'invalid' || payload === 'not exist') {
                    localStorage.clear();
                    window.location.href = '/';
                    return null;
                }

                if (payload && payload.id) {
                    draft['id'] = payload.id;
                    draft['name'] = payload.name;
                    draft['photo'] = payload.photo;
                }
            }),
        [USER_BLOCK_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                // console.log('block: ', payload);
            }),

        [RESET_PASSWORD_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                // console.log();
            }),
    },
    initialState,
);

export default appReducer;
