import React from 'react';
import styled from 'styled-components';

export const Container = styled.div`
    padding: 20px;
`;

export const InnerContainer = styled.div`
    background-color: white;
    border-radius: 8px;
    padding: 20px;
`;

export const Label = styled.p``;

export const Wrapper = styled.div`
    margin-bottom: 12px;
    padding-bottom: 12px;
    border-bottom: 1px solid #e9e9e9;
`;

export const Question = styled.p`
    font-size: 14px;
    margin-bottom: 4px;
`;

export const MainTitle = styled.p`
    font-size: 14px;
    font-weight: bold;
    color: #161616;
`;

export const SubTitle = styled.p`
    font-size: 11px;
    color: #565656;
    margin-bottom: 0px;
`;

export const InputWrapper = styled.div`
    margin-bottom: 8px;
`;

export const Placeholder = styled.p`
    font-size: 13px;
    margin-bottom: 4px;
`;

export const MaxLength = styled.p`
    font-size: 13px;
    margin-bottom: 4px;
`;

export const AddNewQnaWrapper = styled.p`
    border: 1px solid #e9e9e9;
    border-radius: 8px;

    padding: 16px;
`;
