import React from 'react';
import { Input, Checkbox, Select, message, Button, Radio } from 'antd';
import Title from '../../../Components/Common/pageTitle';
import { TableWrapper, TableContainer, Label, ValueBox } from './info.styled';
import ReactJson from 'react-json-view';
import axios from '../../../Lib/axios';

export default class ProductManage extends React.PureComponent {
    state = {
        data: [],
    };

    componentDidMount = () => {
        const { state } = this.props.history.location;
        if (!state || !state.id) return this.props.history.goBack();

        this.fetchInfo(state.id);
    };

    fetchInfo = async id => {
        const { data } = await axios.get('/v2/user/point/history', {
            params: {
                userId: id,
            },
        });

        if (!data || data === 'check parameters') {
            return this.props.history.goBack();
        }

        this.setState({ data });
    };
    render() {
        console.log(this.state);

        return (
            <>
                <Title title='서비스 정보 및 관리' />
                <TableWrapper>
                    <TableContainer>
                        <ReactJson src={this.state.data} />
                    </TableContainer>
                </TableWrapper>
            </>
        );
    }
}
