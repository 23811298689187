import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { bggColor } from '../../theme';

export default class PageTitleComponent extends React.PureComponent {
    render() {
        return (
            <PageTitleContainer>
                <h2 className='h2'>{this.props.title}</h2>
            </PageTitleContainer>
        );
    }
}

const PageTitleContainer = styled.div`
    background-color: ${bggColor};
    padding: 20px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ececec;

    h2 {
        position: relative;
        top: 5px;
        color: #3bc1ff;
        margin-bottom: 3px;
    }

    @media (max-width: 832px) {
        h2 {
            position: relative;
            top: 5px;
            left: 70px;
            color: #3bc1ff;
            margin-bottom: 3px;
        }
    }
`;

PageTitleComponent.propTypes = {
    title: PropTypes.string,
};
