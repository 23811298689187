import React from 'react';
import UserPages from './user';
import StyledUserContainer from './user.styled';
class UserRouter extends React.PureComponent {
    render() {
        return (
            <StyledUserContainer>
                <UserPages {...this.props} />
            </StyledUserContainer>
        );
    }
}
export default UserRouter;
