import React from 'react';
import { Input, message, Button, Checkbox } from 'antd';
import Title from '../../../Components/Common/pageTitle';
import { TableWrapper, TableContainer, Label, ValueBox, FlexBox, FlexWrapper } from './info.styled';

import axios from '../../../Lib/axios';
import { InfoBlock } from '../../../Components/Common/block';

export default class MateApplyInfo extends React.PureComponent {
    state = {
        data: {},
        age: 0,
        allow: {
            name: '',
            contact: '',
            email: '',
            description: '',
            photo: '',
            sex: '남',
        },
        deny: {
            name: '',
            contact: '',
        },
    };

    componentDidMount = () => {
        const { state } = this.props.history.location;
        if (!state || !state.id) return this.props.history.goBack();

        this.fetchInfo(state.id);
    };

    fetchInfo = async id => {
        const { data } = await axios.get('/v1/user/apply/mate/info', {
            params: {
                mateApplyId: id,
            },
        });

        if (!data || data === 'check parameters') {
            return this.props.history.goBack();
        }

        if (data === 'invalid mateApply') {
            return message.warning('잘못된 게시물 정보입니다.');
        }

        this.setState({ ...data });
    };

    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    allowHandler = ({ type, value }) => {
        this.setState({
            allow: {
                ...this.state.allow,
                [type]: value,
            },
        });
    };

    denyHandler = ({ type, value }) => {
        this.setState({
            deny: {
                ...this.state.deny,
                [type]: value,
            },
        });
    };

    onAllow = async () => {
        const { info } = this.props.location.state;
        const { state } = this.props.history.location;
        const { name, contact, email, description, photo, sex } = this.state.allow;
        const { data: result } = await axios.post('/v1/admin/mate/allow/register', {
            name,
            contact,
            email,
            description,
            photo,
            applyId: state.id,
            sex,
        });

        if (result === 'duplicate') {
            return message.info('이미 존재하는 계정이 있습니다.');
        }

        if (result === 'check parameters') {
            return message.info('입력값을 확인해주세요.');
        }

        await axios.put('/v1/admin/mate/apply/check', {
            applyId: state._id,
        });

        message.success('승인완료');
        console.log('101: ', state._id);
        setTimeout(() => {
            this.props.history.push('/mateApply/list');
        }, 1000);
    };

    onDeny = async () => {
        const { info } = this.props.location.state;
        const { state } = this.props.history.location;
        const { name, contact } = this.state.deny;
        const { data: result } = await axios.post('/v1/admin/mate/deny/register', {
            name,
            contact,
        });

        await axios.put('/v1/admin/mate/apply/check', {
            applyId: state.id,
        });

        message.success('거절완료');
        setTimeout(() => {
            this.props.history.goBack();
        }, 1000);
    };

    render() {
        const birthYear = this.state.birth && this.state.birth.slice(0, 4);
        const currentYear = new Date().getFullYear();
        const age = currentYear - birthYear + 1;

        return (
            <>
                <Title title='메이트 지원자 정보' />
                <TableWrapper>
                    <TableContainer>
                        <>
                            <div className='table'>
                                <InfoBlock label='이름' value={this.state.name} />
                                <InfoBlock label='성별' value={this.state.gender} />
                                <InfoBlock label='생년월일' value={this.state.birth} />
                                <InfoBlock label='나이' value={age + ' 세'} />
                                <InfoBlock label='이메일' value={this.state.email} />
                                <InfoBlock label='연락처' value={this.state.contact} />
                                <InfoBlock label='자택 주소' value={this.state.address} />
                                <InfoBlock label='가입 경로' value={this.state.connection} />
                                {this.state.connection === '기타' ? <InfoBlock label='기타 가입 경로' value={this.state.connection_etc} /> : null}
                                <InfoBlock label='메이트 활동 가능지역' value={this.state.activity_area} />
                                <InfoBlock label='운전 가능 여부' value={this.state.drive} />
                                <InfoBlock label='치매관련 간병 경력' value={this.state.careCareer} />
                                <InfoBlock label='개인 간병 경력 사항' value={this.state.care_experience} />
                                <InfoBlock label='삼성화재 전문인 배상 책임 보험 가입 동의' value={this.state.insurance} />
                                <InfoBlock label='개인정보 수집 및 이용 동의' value={this.state.privacy} />
                                {this.state.license_photo && (
                                    <InfoBlock label='자격증 첨부사진'>
                                        <div>
                                            <img
                                                style={{ width: '400px', height: 'auto', objectFit: 'contain' }}
                                                src={
                                                    typeof this.state.license_photo === 'string'
                                                        ? this.state.license_photo
                                                        : URL.createObjectURL(this.state.license_photo)
                                                }
                                            />
                                        </div>
                                    </InfoBlock>
                                )}
                            </div>
                        </>
                    </TableContainer>
                    <FlexWrapper>
                        <FlexBox>
                            <h4>메이트 가입시키기</h4>
                            <Input
                                type='text'
                                placeholder='지원자의 이름을 입력해주세요.'
                                value={this.state.allow.name}
                                onChange={ev => {
                                    this.allowHandler({ type: 'name', value: ev.target.value });
                                }}
                            />
                            <Input
                                type='text'
                                placeholder='지원자의 연락처를 입력해주세요.'
                                value={this.state.allow.contact}
                                onChange={ev => {
                                    this.allowHandler({ type: 'contact', value: ev.target.value.trim() });
                                }}
                            />
                            <Input
                                type='text'
                                placeholder='지원자의 이메일을 입력해주세요.'
                                value={this.state.allow.email}
                                onChange={ev => {
                                    this.allowHandler({ type: 'email', value: ev.target.value.trim() });
                                }}
                            />
                            <Input
                                type='text'
                                placeholder='지원자의 (프로필)사진의 URL을 입력해주세요.'
                                value={this.state.allow.photo}
                                onChange={ev => {
                                    this.allowHandler({ type: 'photo', value: ev.target.value });
                                }}
                            />
                            <Input
                                type='text'
                                placeholder='지원자의 소개글이 있으면 입력해주세요.'
                                value={this.state.allow.description}
                                onChange={ev => {
                                    this.allowHandler({ type: 'description', value: ev.target.value });
                                }}
                            />
                            <div style={{ marginTop: '2px', marginBottom: '2px' }}>
                                <Checkbox
                                    value='남'
                                    checked={this.state.allow.sex === '남'}
                                    onClick={ev => {
                                        this.allowHandler({ type: 'sex', value: '남' });
                                    }}
                                >
                                    남
                                </Checkbox>
                                <Checkbox
                                    value='녀'
                                    checked={this.state.allow.sex === '녀'}
                                    onClick={ev => {
                                        this.allowHandler({ type: 'sex', value: '녀' });
                                    }}
                                >
                                    녀
                                </Checkbox>
                            </div>

                            <Button type='primary' onClick={this.onAllow}>
                                가입시키기
                            </Button>
                        </FlexBox>
                        <FlexBox>
                            <h4>메이트 거절하기</h4>
                            <Input
                                type='text'
                                placeholder='지원자의 이름을 입력해주세요.'
                                value={this.state.deny.name}
                                onChange={ev => {
                                    this.denyHandler({ type: 'name', value: ev.target.value });
                                }}
                            />
                            <Input
                                type='text'
                                placeholder='지원자의 연락처를 입력해주세요.'
                                value={this.state.deny.contact}
                                onChange={ev => {
                                    this.denyHandler({ type: 'contact', value: ev.target.value });
                                }}
                            />
                            <Button type='primary' onClick={this.onDeny}>
                                거절하기
                            </Button>
                        </FlexBox>
                    </FlexWrapper>
                </TableWrapper>
            </>
        );
    }
}
