import React from 'react';
import ReactJson from 'react-json-view';
import moment from 'moment';
import _ from 'lodash';
import { Button, notification, Modal, message, Input, Checkbox } from 'antd';

import axios from '../../../../Lib/axios';

class MatePage extends React.PureComponent {
    state = {
        modal: false,
        list: [],
        doProducts: [],
        info: null,
        review: null,
    };

    componentDidMount = () => {
        this.fetchInfo();
        this.setState({ info: this.props.location.state.info });
    };

    fetchInfo = async () => {
        const { info } = this.props.location.state;
        // console.log(info);
        if (info && info.doProducts) {
            this.setState({ doProducts: info.doProducts });
        }

        const { data } = await axios.get('/v1/product/service/list');
        if (data && Array.isArray(data)) {
            this.setState({ list: data });
        }

        const { data: mate } = await axios.get('/v2/user/mate/info/detail', {
            params: {
                mateId: info._id,
            },
        });

        this.setState({ review: mate.review });
    };

    resetPassword = () => {
        const { _id: id } = this.props.location.state.info;

        this.props
            .resetPassword({ id })
            .then(resolve => {
                if (resolve && resolve === 'success') {
                    message.success('비밀번호가 초기화되었습니다.');
                }
            })
            .catch(error => {
                message.error('서버장애가 발생하였습니다. 관리자에게 문의해주세요.');
            });
    };

    blockHandler = () => {
        const { id } = this.props.auth;
        const { info } = this.props.location.state;
        const { blocked } = info;

        this.props
            .blockUser({ id, targetId: info._id, userType: 'mate', status: !blocked.state })
            .then(resolve => {
                if (resolve === 'not exist') {
                    notification.open({
                        message: '유효하지 않은 사용자',
                        description: '탈퇴하였거나 존재하지 않는 사용자 입니다.',
                    });
                }
                if (resolve === 'success') {
                    message.success(`${!blocked.state === true ? '차단' : '차단해제'}를 완료하였습니다.`);
                    setTimeout(() => {
                        this.props.history.goBack();
                    }, 1000);
                }
            })
            .catch(error => {
                notification.open({
                    message: '사용자 차단 실패',
                    description: '서버상의 오류로 사용자 차단에 실패하였습니다.',
                });
            });
    };

    allow = status => {
        const { info } = this.props.location.state;

        if (status) {
            return this.props
                .allowMate({ mateId: info._id, type: true, reason: '' })
                .then(resolve => {
                    message.success('가입을 승인하였습니다.');

                    setTimeout(() => {
                        this.props.history.goBack();
                    }, 1000);
                })
                .catch(error => {
                    message.error('가입 승인에 실패하였습니다. 새로고침후 다시 시도해주세요.');
                    this.setState({ modal: false });
                });
        } else {
            this.setState({ modal: true });
        }
    };

    mateBlock = () => {
        const { info } = this.props.location.state;
        const reason = info.reason;
        return this.props
            .allowMate({ mateId: info._id, type: false, reason })
            .then(resolve => {
                this.setState({ modal: false });

                message.success('가입을 거절하였습니다.');
                setTimeout(() => {
                    this.props.history.goBack();
                }, 1000);
            })
            .catch(error => {
                message.error('가입 승인에 실패하였습니다. 새로고침후 다시 시도해주세요.');
                this.setState({ modal: false });
            });
    };

    onUpdateMateService = async () => {
        const { info } = this.props.location.state;

        const { data } = await axios.put('/v2/user/edit/mate/service', {
            services: this.state.doProducts,
            mateId: info._id,
        });

        if (data === 'success') {
            message.success('저장 완료');
        }
    };

    onSaveSex = async () => {
        const { info } = this.state;
        if (!info || !info.sex) {
            return message.warning('성별을 먼저 선택해주세요.');
        }

        const { data } = await axios.post('/v1/user/modify/info', {
            id: info._id,
            userType: 'mate',
            info: {
                sex: info.sex,
            },
        });

        if (data && data.name) {
            message.success('성별 설정 완료');
        }
    };

    render() {
        const { list, doProducts, info, review } = this.state;
        console.log(review);

        if (!info) return <></>;
        return (
            <div style={{ padding: '20px' }}>
                <div className='container' style={{ backgroundColor: 'white' }}>
                    <h3>이름</h3>
                    <p>{info.name || '-'}</p>
                    <br />

                    <h3>소개</h3>
                    <p>{info.description || '-'}</p>
                    <br />

                    <h3>연락처</h3>
                    <p>{info.contact || '-'}</p>
                    <br />

                    <h3>가입 경로</h3>
                    <p>{info.path || '-'}</p>
                    <br />

                    <h3>이미지</h3>
                    {info.photo && <img src={info.photo} style={{ width: '100px', objectFit: 'contain' }} />}
                    <br />

                    <h3>가입일</h3>
                    <p>{info.createdAt ? moment(info.createdAt).format('YY.MM.DD HH:mm') : '-'}</p>
                    <br />

                    <h3>수행이력</h3>
                    <p>
                        수행 평점:{' '}
                        {review && (review.unemergency || review.assist) && review.score === '0.0'
                            ? '4 (리뷰없음)'
                            : review && review.score
                            ? review.score
                            : '-'}
                    </p>
                    <p>pro 건: {(review && review.unemergency) || 0}건</p>
                    <p>basic(+ 내시경) 건: {(review && review.assist) || 0}건</p>
                    <br />

                    <h3>탈퇴여부</h3>
                    <p>{info.valid ? '정상' : '탈퇴함'}</p>

                    <h3>차단여부</h3>
                    <p>{info.blocked && info.blocked.state ? '차단됨' : '정상'}</p>
                    <br />

                    <h3>성별</h3>
                    <div style={{ paddingBottom: '30px' }}>
                        <div style={{ marginTop: '2px', marginBottom: '2px' }}>
                            <Checkbox
                                value='남'
                                checked={info.sex === '남'}
                                onClick={() => {
                                    this.setState(prevState => ({ info: { ...prevState['info'], sex: '남' } }));
                                }}
                            >
                                남
                            </Checkbox>
                            <Checkbox
                                value='녀'
                                checked={info.sex === '녀'}
                                onClick={() => {
                                    this.setState(prevState => ({ info: { ...prevState['info'], sex: '녀' } }));
                                }}
                            >
                                녀
                            </Checkbox>
                        </div>
                        <div>
                            <Button type='primary' onClick={this.onSaveSex}>
                                성별 저장
                            </Button>
                        </div>
                    </div>

                    <h3>서비스 수행 목록</h3>
                    <div style={{ paddingBottom: '30px' }}>
                        <div>
                            <Checkbox.Group
                                value={doProducts || []}
                                onChange={values => {
                                    this.setState({ doProducts: values });
                                }}
                            >
                                {list &&
                                    list.length > 0 &&
                                    list.map(item => (
                                        <Checkbox key={item.id} value={item.id}>
                                            {item.name}
                                        </Checkbox>
                                    ))}
                            </Checkbox.Group>
                        </div>
                        <div>
                            <Button type='primary' onClick={this.onUpdateMateService}>
                                수행 서비스 설정 저장
                            </Button>
                        </div>
                    </div>

                    <h3>비밀번호 초기화</h3>
                    <Button type='primary' onClick={this.resetPassword}>
                        비밀번호 초기화
                    </Button>
                    <br />
                    <br />
                    <br />

                    {info.apply && info.apply.form && <h3>연결된 지원서</h3>}
                    {info.apply && info.apply.form && <ReactJson src={typeof info.apply.form === 'string' ? JSON.parse(info.apply.form) : info.apply.form} />}

                    <div className='button-wrapper'>
                        <Button type='primary' style={{ width: '100%' }} onClick={this.blockHandler}>
                            {info.blocked && info.blocked.state ? '차단해제' : '차단하기'}
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

export default MatePage;
