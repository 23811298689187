import React from 'react';
import { connect } from 'react-redux';
import StyledUseCouponListContainer from './useList.styled';
import UseCouponList from './useList';
import Title from '../../../Components/Common/pageTitle';

import { getUseCouponList } from '../../../Reducer/Modules/app';
import { editServicePage } from '../../../Reducer/Modules/service';

class UseCouponListPage extends React.PureComponent {
    componentDidMount = () => {
        this.props.fetchUseCouponList();
    };

    render() {
        return (
            <StyledUseCouponListContainer>
                <Title title='고객 쿠폰 사용내역' />
                <UseCouponList {...this.props} />
            </StyledUseCouponListContainer>
        );
    }
}

const mapStateToProps = ({ app, auth, service }) => ({
    app,
    auth,
    service
});

const mapDispatchToProps = dispatch => ({
    fetchUseCouponList: () => dispatch(getUseCouponList()),
    editPage: (page) => dispatch(editServicePage(page))
});

export default connect(mapStateToProps, mapDispatchToProps)(UseCouponListPage);
