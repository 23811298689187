import { DEV } from '../variables';
import axios from '../axios';

export const getMateList = ({ id }) => {
    return axios.get('/v1/admin/mate/list', { params: { id } });
};

export const getMateInfo = ({ id }) => {
    return axios.get('/v1/user/mate/info/detail', { params: { id } });
};

export const getUserList = ({ id }) => {
    return axios.get('/v1/admin/user/list', { params: { id } });
};

export const getUserCount = () => {
    return axios.get('/v1/admin/user/count');
};

export const getMateCount = () => {
    return axios.get('/v1/admin/mate/count');
};

export const getReserveCount = () => {
    return axios.get('/v1/admin/service/reserve/count');
};

export const getUserInfo = ({ id, userType }) => {
    return axios.get(`/v1/user/${id}/${userType}`);
};

export const getPatientInfo = async ({ patientId, info }) => {
    return axios.get('/v1/auth/patient/list/info', { patientId, info });
};

export const fetchPatient = ({ id }) => {
    return axios.get('/v1/auth/patient/list2', { params: { id } });
};

export const allowMate = ({ mateId, type, reason }) => {
    return axios.put('/v1/admin/allow/mate', { mateId, type, reason });
};

export const fetchReserve = ({ id }) => {
    return axios.get('/v1/auth/reserve/list', { params: { id } });
};

export const ReserveInfo = async ({ reserveId, info }) => {
    return axios.get('/v1/auth/reserve/list/info', { reserveId, info });
};

export const getReserveList = () => {
    return axios.get('/v1/admin/service/reserve/list');
};

export const serviceReserve = ({ userId, info, mateId }) => {
    return axios.post('/v1/service/reserve', { userId, info, mateId });
};

export const getReserveInfo = ({ reserveId }) => {
    return axios.get('/v1/admin/service/reserve/info', { params: { reserveId } });
};

export const getPagingUserList = ({ page }) => {
    return axios.get('/v1/admin/user/list/page', { params: { page } });
};

export const getProductList = () => {
    return axios.get('/v1/service/product/list');
};

export const getProductInfo = ({ productId }) => {
    return axios.get('/v1/service/product/list', { params: { productId } });
};

export const getPaidList = () => {
    return axios.get('/v1/message/done/price/list');
};

export const reservePaid = ({ reserveId, callId, status }) => {
    return axios.put('/v1/admin/reserve/paid', { reserveId, callId, status });
};

export const reserveExecute = ({ status, callId, refundInfo = {} }) => {
    return axios.put('/v1/admin/reserve/execute', { status, callId, refundInfo });
};

export const reserveReward = ({ callId, info }) => {
    return axios.put('/v1/admin/reserve/reward', { callId, info });
};
// export const getServiceRequestInfo = ({ id, targetId }) => {
//     return axios.get('/v1/admin/service/request/list', { params: { id, targetId } });
// };

export const getApplyList = () => {
    return axios.get('/v1/admin/applies/list');
};

export const getReviewList = () => {
    return axios.get('/v1/admin/full/review');
};

export const getReviewInfo = () => {
    return axios.get('/v1/review/info');
};

export const deleteMateApply = async ({ applyId }) => {
    return axios.post('/v1/admin/mate/apply/delete', { applyId });
};

export const getAuthCodeList = () => {
    return axios.get('/v1/auth/code/list');
};

// news & event
export const getNewsEventList = () => {
    return axios.get('/v1/newsEvent/list');
};

export const getNewsEventInfo = async ({ newsEventId, info }) => {
    return axios.get('/v1/newsEvent/list/info', { newsEventId, info });
};

export const onEditNewsEvent = async ({ newsEventId, info }) => {
    return axios.post('/v1/newsEvent/info/edit', { newsEventId, info });
};

export const onDeleteNewsEvent = async ({ newsEventId }) => {
    return axios.post('/v1/newsEvent/info/delete', { newsEventId });
};

// 쿠폰
export const getCouponList = () => {
    return axios.get('/v1/coupon/list');
};

export const getUseCouponList = () => {
    return axios.get('/v1/coupon/use/list');
};

export const getCouponInfo = async ({ couponId, info }) => {
    return axios.get('/v1/coupon/list/info', { couponId, info });
};

export const onEditCoupon = async ({ couponId, info }) => {
    return axios.post('/v1/coupon/info/edit', { couponId, info });
};

export const onDeleteCoupon = async ({ couponId }) => {
    return axios.post('/v1/coupon/info/delete', { couponId });
};

// 장기요양 등급 신청
export const getLongTermCareList = () => {
    return axios.get('/v1/longTermCare/list');
};

export const getLongTermCareInfo = async ({ longTermCareId, info }) => {
    return axios.get('/v1/longTermCare/list/info', { longTermCareId, info });
};

export const onDeleteLongTermCare = async ({ longTermCareId }) => {
    return axios.post('/v1/longTermCare/info/delete', { longTermCareId });
};

// 고객의 신용카드 정보
export const getCardList = () => {
    return axios.get('/v1/auth/card/list2');
};

export const getCardInfo = async ({ cardId, info }) => {
    return axios.get('/v1/auth/card/list/info', { cardId, info });
};

export const onDeleteCard = async ({ cardId }) => {
    return axios.post('/v1/auth/card/info/delete', { cardId });
};

export const imageUpload = file => {
    const formData = new FormData();
    formData.append('photo', file);
    const header = { headers: { 'Content-Type': 'multipart/form-data' } };
    return axios.post('/v1/newsEvent/image/upload', formData, header);
};

export const modifyReserve = ({ reserveId, info }) => {
    return axios.post('/v1/service/admin/reserve/modify', { reserveId, info });
};

// mate apply new
export const getMateApplyList = () => {
    return axios.get('/v1/user/apply/mate/list');
};

export const getMateApplyInfo = async ({ mateApplyId, info }) => {
    return axios.get('/v1/user/apply/mate/info', { mateApplyId, info });
};

export const onDeleteMateApply_v2 = async ({ mateApplyId }) => {
    return axios.post('/v1/user/apply/mate/info/delete', { mateApplyId });
};
