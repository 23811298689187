import React from 'react';
import { Table, Input } from 'antd';

import { Container, TableContainer } from './list.styled';
import { MessageListColumn } from '../../../Components/Columns/service';

class List extends React.PureComponent {
    onRow = row => {
        return {
            onClick: () => {
                this.props.history.push({
                    state: { info: row },
                    pathname: '/message/item',
                });
            },
        };
    };
    render() {
        const { service } = this.props;
        console.log(service);
        return (
            <Container>
                <TableContainer>
                    <Table dataSource={service.messageList} columns={MessageListColumn} onRow={this.onRow} rowKey='_id' />
                </TableContainer>
            </Container>
        );
    }
}
export default List;
