import React from 'react';
import { Switch, Route } from 'react-router-dom';

// pages
import NotFound from '../NotFound';
import PaymentList from './PaymentPages/List';
import PaymentItem from './PaymentPages/Item';

class Payment extends React.PureComponent {
    render() {
        return (
            <Switch>
                <Route exact path='/payment/list' component={PaymentList} />
                <Route exact path='/payment/info/:id' component={PaymentItem} />
                <Route component={NotFound} />
            </Switch>
        );
    }
}
export default Payment;
