import React from 'react';
import { notification, Table } from 'antd';
import { porridgeEvent } from '../../../Components/Columns/event';

import { TableContainer, TableWrapper } from './list.styled';

export default class EventList extends React.PureComponent {
    onRow = (row, index) => {
        return {
            onClick: () => {
                this.props.history.push({
                    pathname: `/event/info/${row._id}`,
                    state: {
                        id: row._id,
                    },
                });
            },
        };
    };

    onDeleteEvent = async ({ _id }) => {
        const confirmed = window.confirm('해당 게시물을 삭제하시겠습니까?');
        if (!confirmed) return null;
    };

    render() {
        return (
            <>
                <TableWrapper>
                    <TableContainer>
                        <Table
                            rowKey='id'
                            dataSource={this.props.event.eventList.data}
                            columns={porridgeEvent({ onDelete: this.onDeleteEvent })}
                            onRow={this.onRow}
                            pagination={{
                                pageSize: 10,
                            }}
                        />
                    </TableContainer>
                </TableWrapper>
            </>
        );
    }
}
