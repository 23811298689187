import React from 'react';
import { connect } from 'react-redux';
import StyledMateContainer from './mate.styled';
import Mate from './mate';
import Title from '../../../../Components/Common/pageTitle';
import { getUserInfo } from '../../../../Reducer/Modules/app';
import { resetPassword, userBlock } from '../../../../Reducer/Modules/auth';
import { allowMate, editUserInfo } from '../../../../Reducer/Modules/app';

class MatePage extends React.PureComponent {
    componentDidMount = () => {};

    render() {
        return (
            <StyledMateContainer>
                <Title title='메이트 상세정보' />
                <Mate {...this.props} />
            </StyledMateContainer>
        );
    }
}

const mapStateToProps = ({ app, auth }) => ({
    app,
    auth,
});

const mapDispatchToProps = dispatch => ({
    fetchInfo: ({ id, userType }) => dispatch(getUserInfo({ id, userType })),
    resetPassword: ({ id, userType }) => dispatch(resetPassword({ id, userType })),
    blockUser: ({ id, targetId, userType, status }) => dispatch(userBlock({ id, targetId, userType, status })),
    allowMate: ({ mateId, type, reason }) => dispatch(allowMate({ mateId, type, reason })),
    editUserInfo: ({ type, value }) => dispatch(editUserInfo({ type, value })),
});

export default connect(mapStateToProps, mapDispatchToProps)(MatePage);
