import React from 'react';
import moment from 'moment';
import { Button } from 'antd';
import { DeleteOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';

export const porridgeEvent = ({ onDelete }) => {
    return [
        {
            title: '번호',
            dataIndex: 'index',
            align: 'center',
            width: '70px',
        },
        {
            title: '수령인',
            dataIndex: 'recipient',
            align: 'center',
        },
        {
            title: '자택주소',
            dataIndex: 'address',
            align: 'center',
        },
        {
            title: '종류',
            dataIndex: 'porridge_type',
            align: 'center',
            render: porridge_type => <span>{porridge_type === 'premium' ? '수제 보양죽 4가지 맛 세트' : '단호박 밤 미강죽 4개 세트'}</span>,
        },
        {
            title: '신청일',
            dataIndex: 'createdAt',
            align: 'center',
            render: createdAt => <span>{moment(createdAt).format('YY.MM.DD HH:mm') || '0'}</span>,
        },
        {
            title: '삭제',
            dataIndex: 'valid',
            align: 'center',
            width: '60px',
            render: (valid, { _id }) => (
                <DeleteOutlined
                    onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();

                        onDelete({ _id });
                    }}
                />
            ),
        },
    ];
};
