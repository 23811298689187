import React from 'react';
import ReactJson from 'react-json-view';
import moment from 'moment';
import { Button, Modal, Input, Select, message } from 'antd';
import _ from 'lodash';

import { Wrapper } from './info.styled';

import { InfoBlock } from '../../../Components/Common/block';
import { UNEMER } from '../../../Lib/variables';
import axios from '../../../Lib/axios';

class ServiceItem extends React.PureComponent {
    render() {
        const { info } = this.props.location.state;

        return (
            <Wrapper>
                <ReactJson src={info} />
            </Wrapper>
        );
    }
}
export default ServiceItem;
