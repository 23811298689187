import React from 'react';
import { Button, Collapse } from 'antd';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { slide as Menu } from 'react-burger-menu';
import './navbar.css';

const { Panel } = Collapse;

export default class NavComponent extends React.PureComponent {
    state = {
        menu: false,
        userName: '',
        userEmail: '',
    };

    componentDidMount() {
        window.addEventListener('resize', this.handleWindowSizeChange);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ width: window.innerWidth });
    };

    onMobileMenuHandler = () => {
        this.setState({ menu: !this.state.menu });
    };

    showSettings(event) {
        event.preventDefault();
    }

    render() {
        const adminName = localStorage.getItem('name');
        const adminEmail = localStorage.getItem('admin');
        const width = window.outerWidth;
        const isMobile = width <= 832;

        return (
            <div className='navbar-main'>
                {isMobile ? (
                    <>
                        <Menu>
                            <div className='navbar-container' style={{ width: '100%' }}>
                                <div className='brand' style={{ textAlign: 'center' }}>
                                    <h2 className='h2'>
                                        <NavLink to='/'>
                                            <img src='https://s3.ap-northeast-2.amazonaws.com/withmate.photo/withmate_new_logo.png' alt='logo' />
                                        </NavLink>
                                    </h2>
                                </div>
                                <div className='navigation-section'>
                                    <div className='admin-user'>
                                        <div className='admin-info'>
                                            <img className='admin-img' src='/assets/images/icons/admin-icon.png' alt='profile' />
                                        </div>
                                        <div className='admin-user-info'>
                                            <div className='user-name'>{adminName}</div>
                                            <div className='user-email'>{adminEmail}</div>
                                        </div>
                                    </div>

                                    <div
                                        style={{
                                            textAlign: 'center',
                                            color: 'black',
                                            paddingBottom: '40px',
                                        }}
                                    >
                                        <p style={{ fontWeight: 'bold', marginBottom: '-4px', fontSize: '16px' }}>SERVICE MANAGEMENT</p>
                                        <MultipleNavigateContainer name='서비스'>
                                            <SingleNavigate name='예약 / 수행이력' icon='fas fa-hospital' link='/service/list' />
                                            <SingleNavigate name='예약 대행' icon='far fa-clock' link='/reserve/todo' />
                                            <SingleNavigate name='상품 목록 및 관리' icon='fas fa-boxes' link='/product/list' />
                                            <SingleNavigate name='리뷰 목록' icon='fas fa-align-left' link='/reserve/review/list' />
                                        </MultipleNavigateContainer>
                                        <MultipleNavigateContainer name='결제 관련'>
                                            <SingleNavigate name='포인트 지급/사용 내역' icon='fas fa-coins' link='/point/list' />
                                            <SingleNavigate name='이용가격 목록' icon='fas fa-wallet' link='/payment/list' />
                                            <SingleNavigate name='회원 결제카드 목록' icon='fas fa-credit-card' link='/card/list' />
                                            {/* <SingleNavigate name='(구) 이용가격 안내 목록' icon='fas fa-wallet' link='/message/list' /> */}
                                        </MultipleNavigateContainer>
                                    </div>

                                    <div
                                        style={{
                                            textAlign: 'center',
                                            color: 'black',
                                            paddingBottom: '40px',
                                        }}
                                    >
                                        <p style={{ fontWeight: 'bold', marginBottom: '-4px', fontSize: '16px' }}>USER & MATE MANAGEMENT</p>
                                        <MultipleNavigateContainer name='회원 관리'>
                                            <InnerNaivgate name='일반사용자' link='/user/normal/list' icon='fas fa-user' />
                                            <SingleNavigate name='이메일 인증코드 목록' icon='far fa-envelope' link='/email/code' />
                                            <InnerNaivgate name='메이트' link='/user/mate/list' icon='fas fa-user-tie' />
                                            <SingleNavigate name='메이트 신청서 목록' icon='far fa-user' link='/mateApply/list' />
                                            <br />
                                            <SingleNavigate name='(구) 메이트 신청서 관리' icon='far fa-clock' link='/form/handle' />
                                            <SingleNavigate name='(구) 메이트 신청서 목록' icon='far fa-user' link='/apply/list' />
                                        </MultipleNavigateContainer>
                                    </div>

                                    <div
                                        style={{
                                            textAlign: 'center',
                                            color: 'black',
                                            paddingBottom: '40px',
                                        }}
                                    >
                                        <p style={{ fontWeight: 'bold', marginBottom: '-4px', fontSize: '16px' }}>EVENT MANAGEMENT</p>
                                        <MultipleNavigateContainer name='NewsEvent'>
                                            <SingleNavigate name='목록 및 관리' icon='fas fa-align-left' link='/newsEvent/list' />
                                            <SingleNavigate name='등록' icon='fas fa-boxes' link='/newsEvent/create' />
                                        </MultipleNavigateContainer>

                                        <MultipleNavigateContainer name='쿠폰 관리'>
                                            <SingleNavigate name='쿠폰 사용내역' icon='fas fa-coins' link='/coupon/use/list' />
                                            <SingleNavigate name='쿠폰 등록' icon='fas fa-boxes' link='/coupon/add' />
                                            <SingleNavigate name='쿠폰 목록 및 관리' icon='fas fa-align-left' link='/coupon/list' />
                                        </MultipleNavigateContainer>

                                        {/* <MultipleNavigateContainer name='이벤트'>
                                            <SingleNavigate name='이벤트 목록' icon='fas fa-align-left' link='/event/list' />
                                        </MultipleNavigateContainer> */}
                                    </div>

                                    <div
                                        style={{
                                            textAlign: 'center',
                                            color: 'black',
                                            paddingBottom: '40px',
                                        }}
                                    >
                                        <p style={{ fontWeight: 'bold', marginBottom: '-4px', fontSize: '16px' }}>ETC</p>
                                        {/* <MultipleNavigateContainer name='장기요양 상담신청'>
                                            <SingleNavigate name='신청서 목록' icon='fas fa-align-left' link='/longTermCare/list' />
                                        </MultipleNavigateContainer> */}

                                        <InnerNaivgate name='메시징 서비스' icon='fas fa-envelope' link='/messenger' />
                                    </div>

                                    <div
                                        style={{
                                            marginTop: '50px',
                                            color: 'black',
                                            textAlign: 'center',
                                        }}
                                    >
                                        <p style={{ fontWeight: 'bold' }}>기타</p>
                                        <NavLink
                                            lang='kr'
                                            exact
                                            to='/logout'
                                            className='nav-item'
                                            activeClassName='active'
                                            style={{ color: 'black', fontWeight: 'bold' }}
                                        >
                                            로그아웃
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                        </Menu>
                    </>
                ) : (
                    <>
                        <div className='navbar-container' style={{ width: '100%' }}>
                            <div className='brand' style={{ textAlign: 'center' }}>
                                <h2 className='h2'>
                                    <NavLink to='/'>
                                        <img src='https://s3.ap-northeast-2.amazonaws.com/withmate.photo/withmate_new_logo.png' alt='logo' />
                                    </NavLink>
                                </h2>
                            </div>
                            <div className='navigation-section'>
                                <div className='admin-user'>
                                    <div className='admin-info'>
                                        <img className='admin-img' src='/assets/images/icons/admin-icon.png' alt='profile' />
                                    </div>
                                    <div className='admin-user-info'>
                                        <div className='user-name'>{adminName}</div>
                                        <div className='user-email'>{adminEmail}</div>
                                    </div>
                                </div>

                                <div
                                    style={{
                                        textAlign: 'center',
                                        color: 'black',
                                        paddingBottom: '40px',
                                    }}
                                >
                                    <p style={{ fontWeight: 'bold', marginBottom: '-4px', fontSize: '16px' }}>SERVICE MANAGEMENT</p>
                                    <MultipleNavigateContainer name='서비스'>
                                        <SingleNavigate name='예약 / 수행이력' icon='fas fa-hospital' link='/service/list' />
                                        <SingleNavigate name='예약 대행' icon='far fa-clock' link='/reserve/todo' />
                                        <SingleNavigate name='상품 목록 및 관리' icon='fas fa-boxes' link='/product/list' />
                                        <SingleNavigate name='리뷰 목록' icon='fas fa-align-left' link='/reserve/review/list' />
                                    </MultipleNavigateContainer>
                                    <MultipleNavigateContainer name='결제 관련'>
                                        <SingleNavigate name='포인트 지급/사용 내역' icon='fas fa-coins' link='/point/list' />
                                        <SingleNavigate name='이용가격 목록' icon='fas fa-wallet' link='/payment/list' />
                                        <SingleNavigate name='회원 결제카드 목록' icon='fas fa-credit-card' link='/card/list' />
                                        {/* <SingleNavigate name='(구) 이용가격 안내 목록' icon='fas fa-wallet' link='/message/list' /> */}
                                    </MultipleNavigateContainer>
                                </div>

                                <div
                                    style={{
                                        textAlign: 'center',
                                        color: 'black',
                                        paddingBottom: '40px',
                                    }}
                                >
                                    <p style={{ fontWeight: 'bold', marginBottom: '-4px', fontSize: '16px' }}>USER & MATE MANAGEMENT</p>
                                    <MultipleNavigateContainer name='회원 관리'>
                                        <InnerNaivgate name='일반사용자' link='/user/normal/list' icon='fas fa-user' />
                                        <SingleNavigate name='이메일 인증코드 목록' icon='far fa-envelope' link='/email/code' />
                                        <InnerNaivgate name='메이트' link='/user/mate/list' icon='fas fa-user-tie' />
                                        <SingleNavigate name='메이트 신청서 목록' icon='far fa-user' link='/mateApply/list' />
                                        <br />
                                        <SingleNavigate name='(구) 메이트 신청서 관리' icon='far fa-clock' link='/form/handle' />
                                        <SingleNavigate name='(구) 메이트 신청서 목록' icon='far fa-user' link='/apply/list' />
                                    </MultipleNavigateContainer>
                                </div>

                                <div
                                    style={{
                                        textAlign: 'center',
                                        color: 'black',
                                        paddingBottom: '40px',
                                    }}
                                >
                                    <p style={{ fontWeight: 'bold', marginBottom: '-4px', fontSize: '16px' }}>EVENT MANAGEMENT</p>
                                    <MultipleNavigateContainer name='NewsEvent'>
                                        <SingleNavigate name='목록 및 관리' icon='fas fa-align-left' link='/newsEvent/list' />
                                        <SingleNavigate name='등록' icon='fas fa-boxes' link='/newsEvent/create' />
                                    </MultipleNavigateContainer>

                                    <MultipleNavigateContainer name='쿠폰 관리'>
                                        <SingleNavigate name='쿠폰 사용내역' icon='fas fa-coins' link='/coupon/use/list' />
                                        <SingleNavigate name='쿠폰 등록' icon='fas fa-boxes' link='/coupon/add' />
                                        <SingleNavigate name='쿠폰 목록 및 관리' icon='fas fa-align-left' link='/coupon/list' />
                                    </MultipleNavigateContainer>

                                    {/* <MultipleNavigateContainer name='이벤트'>
                                        <SingleNavigate name='이벤트 목록' icon='fas fa-align-left' link='/event/list' />
                                    </MultipleNavigateContainer> */}
                                </div>

                                <div
                                    style={{
                                        textAlign: 'center',
                                        color: 'black',
                                        paddingBottom: '40px',
                                    }}
                                >
                                    <p style={{ fontWeight: 'bold', marginBottom: '-4px', fontSize: '16px' }}>ETC</p>
                                    {/* <MultipleNavigateContainer name='장기요양 상담신청'>
                                        <SingleNavigate name='신청서 목록' icon='fas fa-align-left' link='/longTermCare/list' />
                                    </MultipleNavigateContainer> */}

                                    <MultipleNavigateContainer name='메시지'>
                                        <InnerNaivgate name='메시징 서비스' icon='fas fa-envelope' link='/messenger' />
                                    </MultipleNavigateContainer>
                                </div>

                                <div
                                    style={{
                                        marginTop: '50px',
                                        color: 'black',
                                        textAlign: 'center',
                                    }}
                                >
                                    <p style={{ fontWeight: 'bold' }}>기타</p>
                                    <NavLink
                                        lang='kr'
                                        exact
                                        to='/logout'
                                        className='nav-item'
                                        activeClassName='active'
                                        style={{ color: 'black', fontWeight: 'bold' }}
                                    >
                                        로그아웃
                                        <span className='underline'></span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        );
    }
}

export const SingleNavigate = ({ name, icon, link }) => {
    return (
        <div className='single-navigate' style={{ paddingLeft: '50px' }}>
            <i className={`icon ${icon}`} style={{ color: 'rgba(0,0,0,0.6)' }} />
            <NavLink className='title' style={{ color: 'rgba(0,0,0,0.6)' }} to={link}>
                {name}
            </NavLink>
        </div>
    );
};

SingleNavigate.propTypes = {
    name: PropTypes.string,
    icon: PropTypes.string,
    link: PropTypes.string,
};

export const MultipleNavigateContainer = ({ name, children }) => {
    return (
        <Collapse bordered={false}>
            <Panel className='title' header={name} style={{ fontWeight: 'bold' }}>
                {children}
            </Panel>
        </Collapse>
    );
};

MultipleNavigateContainer.propTypes = {
    name: PropTypes.string,
    icon: PropTypes.string,
};

export const InnerNaivgate = ({ name, link, icon }) => {
    return (
        <div className='inner-navigate'>
            <i className={`icon ${icon}`} style={{ color: 'rgba(0,0,0,0.6)' }} />
            &ensp;
            <NavLink style={{ color: 'rgba(0,0,0,0.6)' }} to={link}>
                {name}
            </NavLink>
        </div>
    );
};

InnerNaivgate.propTypes = {
    name: PropTypes.string,
    link: PropTypes.string,
    icon: PropTypes.string,
};
