import React from 'react';
import { connect } from 'react-redux';
import StyledLongTermCareListContainer from './list.styled';
import LongTermCareList from './list';
import Title from '../../../Components/Common/pageTitle';

import { getLongTermCareList } from '../../../Reducer/Modules/app';
import { editServicePage } from '../../../Reducer/Modules/service';

class LongTermCareListPage extends React.PureComponent {
    componentDidMount = () => {
        this.props.fetchLongTermCareList();
    };

    render() {
        return (
            <StyledLongTermCareListContainer>
                <Title title='장기요양등급 신청서 목록' />
                <LongTermCareList {...this.props} />
            </StyledLongTermCareListContainer>
        );
    }
}
const mapStateToProps = ({ app, auth, service }) => ({
    app,
    auth,
    service
});

const mapDispatchToProps = dispatch => ({
    fetchLongTermCareList: () => dispatch(getLongTermCareList()),
    editPage: (page) => dispatch(editServicePage(page))
});

export default connect(mapStateToProps, mapDispatchToProps)(LongTermCareListPage);
