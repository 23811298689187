import React from 'react';
import moment from 'moment';
import { Input, message, Button } from 'antd';
import Title from '../../../../Components/Common/pageTitle';
import { TableWrapper, TableContainer, Label, ValueBox } from './info.styled';

import axios from '../../../../Lib/axios';
import { InfoBlock } from '../../../../Components/Common/block';

export default class ReviewInfo extends React.PureComponent {
    state = {
        data: {},
    };

    componentDidMount = () => {
        const { state } = this.props.history.location;
        if (!state || !state.id) return this.props.history.goBack();

        this.fetchInfo(state.id);
    };

    fetchInfo = async id => {
        const { data } = await axios.get('/v1/review/info', {
            params: {
                reviewId: id,
            },
        });

        if (!data || data === 'check parameters') {
            return this.props.history.goBack();
        }

        if (data === 'invalid review') {
            return message.warning('잘못된 게시물 정보입니다.');
        }

        this.setState({ ...data });
    };

    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value.replace(/^ /gi, '') });
    };

    onUpdate = async () => {
        const {
            title,
            review,
            score,
            reply,
            selfmade,
            reserveDate,
            useAgain,
            corpSatisfied,
            corpSatisfiedReason,
            doctorSatisfied,
            doctorSatisfiedReason,
            hope,
            reserveId,
            userId,
        } = this.state;

        if (!reply) {
            return message.error('관리자 댓글 등록에 실패했습니다. 다시한번 확인하세요.');
        }

        const info = {
            title,
            review,
            score,
            reply,
            selfmade,
            reserveDate,
            useAgain,
            corpSatisfied,
            corpSatisfiedReason,
            doctorSatisfied,
            doctorSatisfiedReason,
            hope,
            reserveId,
            userId,
        };

        const { state } = this.props.history.location;

        const { data } = await axios.post('/v1/review/reply', {
            reviewId: state.id,
            info,
        });

        if (data === 'success') {
            setTimeout(() => {
                this.props.history.goBack();
            }, 250);
            return message.success('리뷰 댓글 달기에 성공하였습니다.');
        } else {
            return message.success('리뷰 댓글달기에 실패하였습니다.');
        }
    };

    render() {
        return (
            <>
                <Title title='리뷰 정보' />
                <TableWrapper>
                    <TableContainer>
                        <>
                            <Label style={{ fontSize: '20px', fontWeight: 'bold', paddingBottom: '1rem' }}>작성자 정보</Label>
                            <ValueBox>
                                <Label style={{ fontSize: '14px', fontWeight: 'bold' }}>이름</Label>
                                <InfoBlock value={this.state.name} />
                            </ValueBox>

                            <ValueBox>
                                <Label style={{ fontSize: '14px', fontWeight: 'bold' }}>진료일</Label>
                                <InfoBlock value={moment(this.state.reserveDate).format('YY.MM.DD')} />
                            </ValueBox>

                            <Label style={{ fontSize: '20px', fontWeight: 'bold', paddingBottom: '1rem' }}>리뷰 정보</Label>
                            <ValueBox>
                                <Label style={{ fontSize: '14px', fontWeight: 'bold' }}>제목</Label>
                                <InfoBlock value={this.state.title} />
                            </ValueBox>

                            <ValueBox>
                                <Label style={{ fontSize: '14px', fontWeight: 'bold' }}>내용</Label>
                                <InfoBlock value={this.state.review} />
                            </ValueBox>

                            <ValueBox>
                                <Label style={{ fontSize: '14px', fontWeight: 'bold' }}>서비스 만족도 별점</Label>
                                <InfoBlock value={this.state.score + '점'} />
                            </ValueBox>

                            <Label style={{ fontSize: '20px', fontWeight: 'bold', paddingBottom: '1rem' }}>설문조사내용</Label>
                            <ValueBox>
                                <Label style={{ fontSize: '14px', fontWeight: 'bold' }}>1️⃣ 다음 진료에도 위드메이트를 사용하실건가요?</Label>
                                <InfoBlock value={this.state.useAgain === true ? '네' : '아니오'} />
                            </ValueBox>

                            <ValueBox>
                                <Label style={{ fontSize: '14px', fontWeight: 'bold' }}>2️⃣ 금일 방문하신 의료 기관의 서비스에 만족하시나요?</Label>
                                <InfoBlock value={this.state.corpSatisfied === true ? '네' : '아니오'} />
                            </ValueBox>

                            <ValueBox>
                                <Label style={{ fontSize: '14px', fontWeight: 'bold' }}>✅ 의료기관의 만족스러운 이유</Label>
                                <InfoBlock value={this.state.corpSatisfiedReason} />
                            </ValueBox>

                            <ValueBox>
                                <Label style={{ fontSize: '14px', fontWeight: 'bold' }}>3️⃣ 금일 방문하신 의료기관의 의료진의 서비스에 만족하시나요?</Label>
                                <InfoBlock value={this.state.doctorSatisfied === true ? '네' : '아니오'} />
                            </ValueBox>

                            <ValueBox>
                                <Label style={{ fontSize: '14px', fontWeight: 'bold' }}>✅ 의료진의 이름과 만족하는 이유</Label>
                                <InfoBlock value={this.state.doctorSatisfiedReason} />
                            </ValueBox>

                            <ValueBox>
                                <Label style={{ fontSize: '14px', fontWeight: 'bold' }}>4️⃣ 병원동행서비스의 개선점이 있다면 무엇이라고 생각하시나요?</Label>
                                <InfoBlock value={this.state.hope} />
                            </ValueBox>

                            <Label style={{ fontSize: '20px', fontWeight: 'bold', paddingBottom: '1rem' }}>관리자 댓글</Label>
                            <ValueBox>
                                <InfoBlock value={this.state.reply} />
                            </ValueBox>

                            <ValueBox>
                                <Label style={{ fontSize: '14px', fontWeight: 'bold' }}>댓글 달기</Label>
                                <InfoBlock>
                                    <Input.TextArea type='text' name='reply' value={this.state.reply} onChange={this.handleChange} />
                                </InfoBlock>
                            </ValueBox>

                            <ValueBox>
                                <Button type='primary' onClick={this.onUpdate}>
                                    확인
                                </Button>
                            </ValueBox>
                        </>
                    </TableContainer>
                </TableWrapper>
            </>
        );
    }
}
