import React from 'react';
import {} from 'antd';
import _ from 'lodash';
import { Input, Button, Select, TimePicker, DatePicker, message, Checkbox } from 'antd';
import { UNEMER, ASSIST, HAPPY_CARE } from '../../../../Lib/variables';
import { UserItem, UserContainer } from './create.styled';
import { InfoBlock } from '../../../../Components/Common/block';
import axios from '../../../../Lib/axios';

class ReviewCreate extends React.PureComponent {
    state = {
        services: [],
        form: {
            mateGender: '',
            usingPoint: 0,
            transport: 'common',
            payment: 'transfer',
            contact: '01086188650',
            patient: '예약자',
            sex: '남',
            comment: '잘 부탁드립니다. 서비스 수행 간 안전에 주의해주세요~',
        },
        buyerName: '',
        mateName: '',
        score: null,
        title: '',
        review: '',
    };

    componentDidMount = () => {
        this.fetchService();
    };

    fetchService = async () => {
        const { data } = await axios.get('/v1/product/service/list');
        if (data && Array.isArray(data)) {
            this.setState({ services: data });
        }
    };

    getFilteredList = () => {
        const { user } = this.props.app;
        const { keyword } = this.state;

        if (!user) return [];

        return user.filter(m => {
            if (m.name && keyword && m.name.toLowerCase().includes(keyword.toLowerCase())) return true;
            if (m.contact && keyword && m.contact.toLowerCase().includes(keyword.toLowerCase())) return true;
            return false;
        });
    };

    getMateList = () => {
        const { mate } = this.props.app;
        const { search } = this.state;

        if (!mate) return [];
        if (!search) {
            return mate.filter(m => {
                if (!m.valid || m.blocked.state) return false;
                return true;
            });
        }

        return mate.filter(m => {
            if (!m.valid || m.blocked.state) return false;
            if (m.name && search && m.name.toLowerCase().includes(search.toLowerCase())) return true;
            if (m.contact && search && m.contact.toLowerCase().includes(search.toLowerCase())) return true;
            return false;
        });
    };

    onReserve = async () => {
        const { buyerId, form, mateId, score, title, review, reviewAt, treatmentDate } = this.state;

        const {
            shortStarting,
            destination = '',
            shortDestination,
            comment,
            service,
            addTime,
            starting,
            contact,
            reserveDate,
            meetDate,
            patient,
            relate,
            payment,
            sex,
            transport,
            product,
        } = form;

        if (!buyerId) return message.warning('사용자를 선택해주세요.');
        if (!mateId) return message.warning('담당 메이트를 선택해주세요.');
        if (!product) return message.warning('서비스를 선택해주세요.');
        if (!service) return message.warning('세부 서비스 타입을  선택해주세요.');
        if (!sex) return message.warning('환자의 성별을 선택해주세요.');
        if (!addTime) return message.warning('예상 이용시간을 입력해주세요.');
        if (!destination) return message.warning('목적지를 입력해주세요.');
        if (service === 'unemergency' && !starting) return message.warning('자택 주소를 입력해주세요.');
        if (!transport) return message.warning('이동수단을 선택해주세요.');
        if (!contact) return message.warning('환자분의 연락처를 입력해주세요.');
        if (!reserveDate) return message.warning('진료 일정을 설정해주세요,.');
        if (!meetDate) return message.warning('병원 도착 / 자택 도착 희망 시간을 설정해주세요');
        if (!patient) return message.warning('환자분의 성함을 입력해주세요');
        if (!relate) return message.warning('환자분과의 관계를 선택해주세요');
        if (!payment) return message.warning('결제 방법을 선택해주세요.');
        if (!score) return message.warning('점수를 입력해주세요.');
        if (!title) return message.warning('리뷰 제목을 입력해주세요.');
        if (!review) return message.warning('리뷰 내용을 입력해주세요.');
        if (!treatmentDate) return message.warning('진료 일자를 설정해주세요.');
        if (!reviewAt) return message.warning('리뷰 생성 기준일을 설정해주세요.');

        // 예약
        const { data } = await axios.post('/v1/admin/super/review', {
            userId: buyerId,
            mateId,
            info: form,
            score,
            title,
            review,
            reviewAt,
            treatmentDate
        });

        if (!data) return message.error('서버 장애가 발생하였습니다. 잠시후 다시 시도해주세요.');
        if (data === 'check parameters') return message.warning('빠트린 정보가 없는지 확인해주세요.');
        if (data === 'invalid user') return message.warning('유효하지 않는 회원정보입니다.');
        if (data === 'invalid product') return message.warning('유효하지 않는 상품정보입니다.');
        if (data === 'over using point') return message.warning('보유 포인트 이상을 사용하려합니다');

        if (data === 'success') {
            message.success('예약 완료');
            setTimeout(() => {
                this.props.history.push('/service/list');
            }, 250);
        }
    };

    onStarting = () => {
        new window.daum.Postcode({
            oncomplete: data => {
                const { sido, sigungu, bname, roadAddress, buildingName } = data;
                this.setState(prevState => ({
                    form: {
                        ...prevState['form'],
                        starting: `${roadAddress} (${buildingName})`,
                        shortStarting: `${sido} ${sigungu} ${bname}`,
                    },
                }));
            },
        }).open();
    };

    onDestination = () => {
        new window.daum.Postcode({
            oncomplete: data => {
                const { sido, sigungu, bname, roadAddress, buildingName } = data;
                this.setState(prevState => ({
                    form: {
                        ...prevState['form'],
                        destination: `${roadAddress} (${buildingName})`,
                        shortDestination: `${sido} ${sigungu} ${bname}`,
                    },
                }));
            },
        }).open();
    };

    handleChange = e => {
        const key = e.target.name;
        const value = e.target.value;
        this.setState(prevState => ({
            form: {
                ...prevState['form'],
                [key]: value,
            },
        }));
    };

    render() {
        const { user } = this.props.app;
        const { form, services, keyword = '', search = '', buyerName, mateName } = this.state;

        const filteredList = !keyword ? this.props.app.user : this.getFilteredList();
        const mateList = this.getMateList();

        console.log(this.state);

        return (
            <div className='container'>
                <InfoBlock label='사용자'>
                    <p className='selected-user'>
                        선택된 사용자 {'>'} <span>{buyerName || '선택안됨'}</span>
                    </p>
                    <Input
                        placeholder='이름/연락처로 사용자를 검색해보세요.'
                        value={keyword}
                        onChange={ev => {
                            this.setState({ keyword: ev.target.value });
                        }}
                    />
                    <UserContainer>
                        {!_.isEmpty(filteredList)
                            ? filteredList.map((item, i) => (
                                  <User
                                      active={item._id === form.buyerId}
                                      onClick={() => {
                                          this.setState({ buyerId: item._id, buyerName: item.name });
                                      }}
                                      key={i.toString()}
                                      name={item.name}
                                      phone={item.contact}
                                  />
                              ))
                            : '-'}
                    </UserContainer>
                </InfoBlock>

                <InfoBlock label='담당 메이트'>
                    <p className='selected-user'>
                        선택된 메이트 {'>'} <span>{mateName || '선택안됨'}</span>
                    </p>
                    <Input
                        placeholder='이름/연락처로 메이트를 검색해보세요.'
                        value={search}
                        onChange={ev => {
                            this.setState({ search: ev.target.value });
                        }}
                    />
                    <UserContainer>
                        {!_.isEmpty(mateList)
                            ? mateList.map((item, i) => (
                                  <User
                                      active={item._id === form.mateId}
                                      onClick={() => {
                                          this.setState({ mateId: item._id, mateName: item.name });
                                      }}
                                      key={i.toString()}
                                      name={item.name}
                                      phone={item.contact}
                                  />
                              ))
                            : '-'}
                    </UserContainer>
                </InfoBlock>

                <InfoBlock label='서비스'>
                    <Select
                        style={{ width: '100%' }}
                        placeholder='서비스를 먼저 지정해주세요.'
                        value={(form && form.product) || undefined}
                        onChange={value => {
                            const productIndex = services.findIndex(s => s.id === value);

                            this.setState(prevState => ({
                                form: {
                                    ...prevState['form'],
                                    product: value,
                                    destination: services[productIndex].reservedDestination || '',
                                    shortDestination: services[productIndex].reservedShortDestination || '',
                                    starting: services[productIndex].reservedStarting || '',
                                    shortStarting: services[productIndex].reservedShortStarting || '',
                                },
                            }));
                        }}
                    >
                        {services &&
                            services.length > 0 &&
                            services.map(service => (
                                <Select.Option value={service.id} key={service.id}>
                                    {service.name}
                                </Select.Option>
                            ))}
                    </Select>
                </InfoBlock>

                {form && form.product && (
                    <>
                        <InfoBlock label='서비스 타입'>
                            <Select
                                style={{ width: '100%' }}
                                value={form.service || undefined}
                                placeholder='예약할 서비스 타입을 선택해주세요.'
                                onChange={value => {
                                    this.setState(prevState => ({
                                        form: {
                                            ...prevState['form'],
                                            service: value,
                                        },
                                    }));
                                }}
                            >
                                <Select.Option value='assist'>Basic</Select.Option>
                                <Select.Option value='unemergency'>Pro</Select.Option>
                            </Select>
                        </InfoBlock>

                        <InfoBlock label='환자 성명'>
                            <Input onChange={this.handleChange} name='patient' value={form.patient || null} placeholder='이용자 성함' />
                        </InfoBlock>

                        <InfoBlock label='관계'>
                            <Select
                                style={{ width: '100%' }}
                                value={form.relate || undefined}
                                placeholder='환자분과의 관계를 선택해주세요'
                                onChange={value => {
                                    this.setState(prevState => ({
                                        form: {
                                            ...prevState['form'],
                                            relate: value,
                                        },
                                    }));
                                }}
                            >
                                <Select.Option value='본인'>본인</Select.Option>
                                <Select.Option value='부모'>부모</Select.Option>
                                <Select.Option value='자매'>자매</Select.Option>
                                <Select.Option value='친구'>친구</Select.Option>
                                <Select.Option value='지인'>지인</Select.Option>
                                <Select.Option value='기타'>기타</Select.Option>
                            </Select>
                        </InfoBlock>

                        <InfoBlock label='연락처'>
                            <Input maxLength={14} value={form.contact || null} name='contact' onChange={this.handleChange} placeholder='01012341234' />
                        </InfoBlock>

                        <InfoBlock label='성별'>
                            <Select
                                value={form.sex || undefined}
                                placeholder='성별을 선택해주세요'
                                onChange={value => {
                                    this.setState(prevState => ({
                                        form: {
                                            ...prevState['form'],
                                            sex: value,
                                        },
                                    }));
                                }}
                                style={{ width: '100%' }}
                            >
                                <Select.Option value='남'>남</Select.Option>
                                <Select.Option value='녀'>녀</Select.Option>
                            </Select>
                        </InfoBlock>

                        <InfoBlock label='병원위치 '>
                            <Input
                                disabled={form.service === HAPPY_CARE || form.shortDestination}
                                onClick={this.onDestination}
                                value={form.destination || null}
                                placeholder='ex) 분당구 황새울로 10'
                            />
                        </InfoBlock>
                        {form && form.service === 'unemergency' && (
                            <InfoBlock label='자택주소'>
                                <Input
                                    disabled={form.shortStarting}
                                    onClick={this.onStarting}
                                    value={form.starting || null}
                                    placeholder='ex) 분당구 황새울로 35'
                                />
                            </InfoBlock>
                        )}

                        <InfoBlock label='병원진료 예약일자'>
                            <Input
                                type='datetime-local'
                                value={form.reserveDate || undefined}
                                format='YYYY-MM-DD'
                                style={{ width: '100%' }}
                                onChange={e => {
                                    const date = e.target.value;
                                    this.setState(prevState => ({
                                        form: {
                                            ...prevState['form'],
                                            ['reserveDate']: date,
                                        },
                                    }));
                                }}
                            />
                        </InfoBlock>

                        <InfoBlock label='병원도착 희망일자 / 자택도착 희망일자'>
                            <Input
                                type='datetime-local'
                                value={form.meetDate || undefined}
                                format='YYYY-MM-DD'
                                style={{ width: '100%' }}
                                onChange={e => {
                                    const date = e.target.value;
                                    this.setState(prevState => ({
                                        form: {
                                            ...prevState['form'],
                                            ['meetDate']: date,
                                        },
                                    }));
                                }}
                            />
                        </InfoBlock>

                        <InfoBlock label='예상 이용시간'>
                            <Input
                                maxLength={3}
                                name='addTime'
                                onChange={this.handleChange}
                                value={form.addTime || null}
                                placeholder='3 (숫자만 입력해주세요)'
                            />
                        </InfoBlock>

                        <InfoBlock label='결제 수단'>
                            <Select
                                value={form.payment || undefined}
                                placeholder='결제 수단을 선택해주세요'
                                style={{ width: '100%' }}
                                onChange={value => {
                                    this.setState(prevState => ({
                                        form: {
                                            ...prevState['form'],
                                            payment: value,
                                        },
                                    }));
                                }}
                            >
                                <Select.Option value='transfer'>계좌이체</Select.Option>
                            </Select>
                        </InfoBlock>
                        {form && form.service === 'unemergency' && (
                            <InfoBlock label='이동수단'>
                                <Checkbox
                                    checked={form && form.transport === 'common'}
                                    value='common'
                                    onChange={() => {
                                        this.setState(prevState => ({
                                            form: {
                                                ...prevState['form'],
                                                transport: 'common',
                                            },
                                        }));
                                    }}
                                >
                                    대중교통
                                </Checkbox>
                            </InfoBlock>
                        )}

                        <InfoBlock label='요청사항'>
                            <Input.TextArea
                                type='text'
                                placeholder='요청사항을 입력해주세요'
                                name='comment'
                                value={form && form.comment ? form.comment : null}
                                onChange={this.handleChange}
                            />
                        </InfoBlock>
                    </>
                )}

                <InfoBlock label='리뷰 작성'>
                    <Input
                        type='number'
                        placeholder='별점을 입력해주세요 1~5'
                        style={{ marginBottom: '4px' }}
                        value={this.state.score || undefined}
                        onChange={ev => {
                            this.setState({ score: ev.target.value });
                        }}
                    />
                    <Input
                        typ='text'
                        placeholder='제목을 입력해주세요.'
                        style={{ marginBottom: '4px' }}
                        value={this.state.title || null}
                        onChange={ev => {
                            this.setState({ title: ev.target.value });
                        }}
                    />
                    <Input.TextArea
                        typ='text'
                        placeholder='후기를 입력해주세요.'
                        value={this.state.review || null}
                        onChange={ev => {
                            this.setState({ review: ev.target.value });
                        }}
                    />
                    <label>진료 일자</label>
                    <Input
                        type='datetime-local'
                        placeholder='진료 일자'
                        value={this.state.treatmentDate || undefined}
                        format='YYYY-MM-DD'
                        style={{ width: '100%' }}
                        onChange={e => {
                            const date = e.target.value;
                            this.setState({ treatmentDate: date });
                        }}
                    />
                    <label>리뷰 기준일</label>
                    <Input
                        type='datetime-local'
                        placeholder='리뷰 기준일'
                        value={this.state.reviewAt || undefined}
                        format='YYYY-MM-DD'
                        style={{ width: '100%' }}
                        onChange={e => {
                            const date = e.target.value;
                            this.setState({ reviewAt: date });
                        }}
                    />
                </InfoBlock>

                <Button type='primary' style={{ width: '100%' }} onClick={this.onReserve}>
                    리뷰 추가
                </Button>
            </div>
        );
    }
}

const User = ({ name, phone, onClick, active }) => (
    <UserItem active={`user-wrapper ${active ? 'active' : undefined}`} onClick={onClick}>
        <p>
            {name} ({phone})
        </p>
    </UserItem>
);

export default ReviewCreate;
