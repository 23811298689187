import React from 'react';
import moment from 'moment';
import { Button, message } from 'antd';
import Title from '../../../Components/Common/pageTitle';
import { TableWrapper, TableContainer } from './info.styled';

import axios from '../../../Lib/axios';
import { InfoBlock } from '../../../Components/Common/block';

export default class EventInfo extends React.PureComponent {
    state = {
        data: {},
    };

    componentDidMount = () => {
        const { state } = this.props.history.location;
        if (!state || !state.id) return this.props.history.goBack();

        this.fetchInfo(state.id);
    };

    fetchInfo = async id => {
        const { data } = await axios.get('/v1/event/list/info', {
            params: {
                eventId: id,
            },
        });

        if (!data || data === 'check parameters') {
            return this.props.history.goBack();
        }

        if (data === 'invalid event') {
            return message.warning('잘못된 게시물 정보입니다.');
        }

        this.setState({ ...data });
    };

    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    render() {
        // console.log(this.state);

        return (
            <>
                <Title title='이벤트 신청서 정보' />
                <TableWrapper>
                    <TableContainer>
                        {this.state && (
                            <>
                                <div className='table'>
                                    <Button type='light' onClick={this.prevStep} style={{ float: 'right', marginRight: '10px' }}>
                                        목록으로
                                    </Button>
                                    <InfoBlock label='수령인 (받으실분 성함)' value={this.state.recipient} />
                                    <InfoBlock label='환자 성함' value={this.state.patient} />
                                    <InfoBlock label='보호자 성함' value={this.state.name} />
                                    <InfoBlock label='보호자 연락처' value={this.state.guardContact} />
                                    <InfoBlock label='자택 주소 (받으실분 자택주소)' value={this.state.address + ' ' + this.state.detailed_address} />
                                    <InfoBlock
                                        label='종류'
                                        value={
                                            this.state.porridge_type === 'premium'
                                                ? '수제 보양죽 4가지 맛 세트 (한우사골, 들깨, 흑임자 전복)'
                                                : '단호박 밤 미강죽 4개 세트'
                                        }
                                    />
                                    <InfoBlock label='서비스 예약일' value={moment(this.state.reserveDate).format('YYYY. MM. DD - HH:mm A')} />
                                    <InfoBlock label='신청일' value={moment(this.state.createdAt).format('YYYY. MM. DD - HH:mm A')} />
                                </div>
                            </>
                        )}
                    </TableContainer>
                </TableWrapper>
            </>
        );
    }
}
