import axios from '../axios';

export const login = ({ email, password }) => {
    return axios.post('/v1/admin/login', { id: email, password });
};

export const logout = ({ id, userType }) => {
    return axios.get('/v1/auth/logout', { params: { id, userType } });
};

export const checkAuth = ({ id }) => {
    return axios.get('/v1/admin/isauthorized', { params: { id } });
};

export const userBlock = ({ id, targetId, userType, status }) => {
    return axios.post('/v1/admin/block', { id, targetId, userType, status });
};

export const resetPassword = ({ id }) => {
    return axios.post('/v1/auth/reset/password', { id });
};
