import styled from 'styled-components';

const StyledContainer = styled.div``;

export default StyledContainer;

export const Container = styled.div`
    padding: 20px;
`;

export const TableContainer = styled.div`
    padding: 20px;
    background-color: #ffffff;
`;
