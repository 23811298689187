import React, { PureComponent } from 'react';
import { Switch, Route } from 'react-router-dom';

import List from './List';
import Info from './Info';

class ContractService extends PureComponent {
    render() {
        const { url } = this.props.match;

        return (
            <Switch>
                <Route path={`${url}/service/list`} component={List} />
                <Route path={`${url}/service/info`} component={Info} />
            </Switch>
        );
    }
}
export default ContractService;
