import React from 'react';
import { Input, Button, message } from 'antd';

import { TableWrapper, TableContainer, Label, ValueBox } from './create.styled';

import axios from '../../../Lib/axios';
import Title from '../../../Components/Common/pageTitle';

export default class CouponCreate extends React.PureComponent {
    state = {
        couponName: '',
        appointedCode: '',
        couponInfo: '',
        couponValue: '',
        couponImage: '',
    };

    onAdd = async () => {
        const { couponName, appointedCode, couponInfo, couponValue, photo } = this.state;
        if (!couponName) return message.warning('쿠폰명을 입력해주세요.');
        if (!appointedCode) return message.warning('쿠폰 코드를 입력해주세요.');
        if (!couponInfo) return message.warning('쿠폰 설명을 입력해주세요.');
        if (!couponValue) return message.warning('쿠폰 포인트 값을 입력해주세요.');

        const formData = new FormData();
        formData.append('photo', photo);
        const header = { headers: { 'Content-Type': 'multipart/form-data' } };
        const { data: image } = await axios.post('/v1/coupon/image/upload', formData, header);

        const { data } = await axios.post('/v1/coupon/add', {
            couponName,
            appointedCode,
            couponInfo,
            couponValue,
            photo: image
        });

        if (!data) return message.error('죄송합니다. 일시적인 서버 장애가 발생하였습니다.');
        if (data === 'check parameters') return message.warning('필수 정보들을 입력해주세요.');
        if (data === 'success') {
            message.success('쿠폰 작성완료');
            setTimeout(() => {
                this.props.history.goBack();
            }, 600);
        }
    };

    handleChange = e => {
        this.setState({ [e.target.title]: e.target.value });
    };

    render() {
        return (
            <>
                <Title title='쿠폰 등록' />
                <TableWrapper>
                    <TableContainer>
                        <>
                            <ValueBox>
                                <Label style={{ fontSize: '14px' }}><b>쿠폰명</b></Label>
                                <Input placeholder='쿠폰명을 입력해주세요.' value={this.state.couponName} title='couponName' onChange={this.handleChange} />
                            </ValueBox>

                            <ValueBox>
                                <Label style={{ fontSize: '14px' }}><b>쿠폰 코드</b></Label>
                                <Input placeholder='쿠폰 코드를 입력해주세요.' value={this.state.appointedCode} title='appointedCode' onChange={this.handleChange} />
                            </ValueBox>

                            <ValueBox>
                                <Label style={{ fontSize: '14px' }}><b>설명</b></Label>
                                <Input placeholder='쿠폰 설명을 입력해주세요.' value={this.state.couponInfo} title='couponInfo' onChange={this.handleChange} />
                            </ValueBox>
                            
                            <ValueBox>
                                <Label style={{ fontSize: '14px' }}><b>포인트</b></Label>
                                <Input placeholder='쿠폰 포인트를 입력해주세요.' value={this.state.couponValue} title='couponValue' onChange={this.handleChange} />
                            </ValueBox>

                            <ValueBox>
                                <Label style={{ fontSize: '14px' }}><b>쿠폰 이미지</b></Label>
                                {this.state.photo && (
                                    <div>
                                        <img
                                            style={{ width: '300px', height: '300px', objectFit: 'contain' }}
                                            src={typeof this.state.photo === 'string' ? this.state.photo : URL.createObjectURL(this.state.photo)}
                                            alt='사진'
                                        />
                                    </div>
                                )}
                                <input
                                    type='file'
                                    accept='image/*'
                                    placeholder='쿠폰 이미지를 추가해주세요.'
                                    title='photo'
                                    onChange={ev => {
                                        console.log(ev.target.files);
                                        this.setState({ photo: ev.target.files && ev.target.files.length > 0 ? ev.target.files[0] : '' });
                                    }}
                                />
                            </ValueBox>

                            <ValueBox style={{ textAlign: 'right' }}>
                                <Button type='primary' onClick={this.onAdd}>
                                    등록하기
                                </Button>
                            </ValueBox>
                        </>
                    </TableContainer>
                </TableWrapper>
            </>
        );
    }
}
