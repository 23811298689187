import React from 'react';
import { connect } from 'react-redux';
import StyledNewsEventListContainer from './list.styled';
import NewsEventList from './list';
import Title from '../../../Components/Common/pageTitle';

import { getNewsEventList } from '../../../Reducer/Modules/app';
import { editServicePage } from '../../../Reducer/Modules/service';

class NewsEventListPage extends React.PureComponent {
    componentDidMount = () => {
        this.props.fetchNewsEventList();
    };

    render() {
        return (
            <StyledNewsEventListContainer>
                <Title title='News & Event 목록' />
                <NewsEventList {...this.props} />
            </StyledNewsEventListContainer>
        );
    }
}
const mapStateToProps = ({ app, auth, service }) => ({
    app,
    auth,
    service
});

const mapDispatchToProps = dispatch => ({
    fetchNewsEventList: () => dispatch(getNewsEventList()),
    editPage: (page) => dispatch(editServicePage(page))
});

export default connect(mapStateToProps, mapDispatchToProps)(NewsEventListPage);
