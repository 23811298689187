import React from 'react';
import { connect } from 'react-redux';
import Title from '../../../Components/Common/pageTitle';
import { TableWrapper } from './subtract.styled';
import Subtract from './subtract';
import { getUserList } from '../../../Reducer/Modules/app';

class SubtractContainer extends React.PureComponent {
    componentDidMount = () => {
        const { id } = this.props.auth;
        this.props.fetchList(id);
    };

    render() {
        return (
            <TableWrapper>
                <Title title='포인트 차감' />
                <Subtract {...this.props} />
            </TableWrapper>
        );
    }
}

const mapStateToProps = ({ app, auth }) => ({
    app,
    auth,
});

const mapDispatchToProps = dispatch => ({
    fetchList: ({ page }) => dispatch(getUserList({ page })),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubtractContainer);
