import React from 'react';
import { connect } from 'react-redux';
import StyledPaymentItemContainer from './item.styled';
import PaymentItem from './item';
import Title from '../../../../Components/Common/pageTitle';
import {} from '../../../../Reducer/Modules/app';

class PaymentItemPage extends React.PureComponent {
    componentDidMount = () => {
        const { id } = this.props.auth;
        const { id: targetId } = this.props.match.params;
        // this.props.fetchInfo(id, targetId);
    };

    render() {
        return (
            <StyledPaymentItemContainer>
                <Title title='결제내역 상세내용' />
                <PaymentItem {...this.props} />
            </StyledPaymentItemContainer>
        );
    }
}
const mapStateToProps = ({ app, auth }) => ({
    app,
    auth,
});

const mapDispatchToProps = dispatch => ({
    // fetchInfo: (id, targetId) => dispatch(getServiceRequestInfo({ id, targetId })),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentItemPage);
