import styled from 'styled-components';
const StyledMessengerContainer = styled.div`
.selected-user {
    padding: 0px;
    margin: 0px;
    font-size: 14px;
    border: 1px solid #000000;
    margin-bottom: 4px;
    padding: 4px;
    span {
        font-weight: bold;
    }
}

.user-container {
    max-height: 200px;
    overflow: scroll;

    .user-wrapper {
        background-color: white;
        cursor: pointer;

        &:hover {
            background-color: #dedede;
            transition: all 0.2s;
        }

        &.active {
            background-color: #606060;
            p {
                color: white;
            }
        }

        p {
            height: 32px;
            line-height: 32px;
            text-indent: 12px;
            font-size: 12px;
            color: rgba(0, 0, 0, 0.7);
            margin: 0px;
            padding: 0px;
            border-bottom: 1px solid #e6e6e6;
        }
    }
}
`;

export const MessageType = styled.div`
    border: 1px solid #e9e9e9;
    padding: 20px;
    background-color: white;
    border-radius: 24px;
`;

export const MessageForm = styled.div`
    width: 320px;
    height: 400px;
    background-color: #f1f1f1;
    margin-top: 16px;
    padding: 12px;
`;
export default StyledMessengerContainer;
