import React from "react";
import Modal from "react-modal";
import styled from 'styled-components';
import moment from 'moment';
import { Button, Input, message, Table } from 'antd';

import ReserveStyledContainer from "./reserveList.styled";
import { ReserveInfo } from "../../../../Lib/Api/app";

const customStyles = {

    overlay: {
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(15, 15, 15, 0.79)",
    },
    content: {
        position: "absolute",
        top: "30px",
        height: "90%",
        border: "1px solid #ccc",
        background: "#ffffff",
        overflow: "auto",
        WebkitOverflowScrolling: "touch",
        borderRadius: "4px",
        outline: "none",
        padding: "20px",
    },
}

const reserveColumn = ({ onCheck }) => {
    return [
        {
            title: '번호',
            dataIndex: 'index',
            align: 'center',
            width: '100px'
        },
        {
            title: '환자 이름',
            dataIndex: 'patient',
            align: 'center',
            width: '100px',
            sorter: (a, b) => a.patient.localeCompare(b.patient)
        },
        {
            title: '서비스',
            dataIndex: 'service',
            align: 'center',
            width: '100px',
            render: service => <span>{service === 'assist' ? 'Basic' : service === 'unemergency' ? 'Pro' : '-'}</span>,
        },
        {
            title: '이용날짜',
            dataIndex: 'reserveDate',
            align: 'center',
            width: '100px',
            render: reserveDate => <span>{moment(reserveDate).format('YY.MM.DD') || '0'}</span>
        },
        {
            title: '선택',
            align: 'center',
            width: '100px',
            dataIndex: '_id',
            render: (_id, data) =>
                <Button
                    style={{ width: '70px', height: '30px', color: 'gray' }}
                    onClick={() => { onCheck(data) }}
                >
                    선택
                </Button>
        },
    ]
}

class ReserveListComponent extends React.PureComponent {
    state = {
        reserveInfo: {},
        reserveList: [],
        reserveId: '',
        reserveInfo: null,
    };

    componentWillUnmount = () => {
        this.setState({ reserveInfo: null });
    };

    onChoose = () => {
        const { reserveInfo } = this.state;
        console.log(reserveInfo)

        ReserveInfo({ reserveId: reserveInfo._id }).then(resolve => {
            console.log(data)
            const { data } = resolve;
            if (resolve && resolve.data && resolve.data.message === 'success') {
                if (reserveInfo && reserveInfo.reservedStarting) delete data.starting;
                if (reserveInfo && reserveInfo.environment) delete data.environment;

                this.setState({ reserveInfo: resolve.data.data })
            } else {
                message.info('선택하신 환자 프로필이 존재하지 않습니다.');
            }
        });
    };

    render() {
        return (
            <Modal
                isOpen={this.props.visible}
                ariaHideApp={false}
                // onRequestClose={modalOff} // esc키 또는 background 클릭 시 모달창 닫힘
                style={{ ...customStyles }}
            >
                <ReserveStyledContainer>
                    <div className='inner-container'>
                        <div className='reserve-form'>
                            <div className='form'>
                                <div>
                                    <CloseButton src={'/assets/images/close.png'} onClick={this.props.onClose} style={{ width: '22px', height: '22px' }} />
                                </div>
                                <TitleSection>
                                    <WideModalTitle>최근 예약목록</WideModalTitle>
                                    <AlertText>
                                        * 최근 예약 정보를 선택할 수 있습니다.
                                    </AlertText>
                                </TitleSection>

                                <DivisionLine />

                                <div className='reserveList'>
                                    <Table
                                        dataSource={this.props.list}
                                        columns={reserveColumn({
                                            onCheck: (data) => {
                                                this.props.onUpdateReserve(data);
                                                this.props.onClose();
                                            }
                                        })}
                                        rowKey='_id'
                                        pagination={{
                                            pageSize: 10,
                                            hideOnSinglePage: true,
                                        }}
                                    />
                                </div>

                                <DivisionLine />

                                <ButtonWrap>
                                    <HalfWidthButton style={{ margin: '0 auto' }} bg='#3bc1ff'
                                        onClick={this.props.onClose}
                                    >
                                        닫기
                                    </HalfWidthButton>
                                </ButtonWrap>
                            </div>
                        </div>
                    </div>
                </ReserveStyledContainer >
            </Modal >
        );
    }
};

export default ReserveListComponent;

const CloseButton = styled.img`
    position: absolute;
    top: 16px;
    right: 12px;

    width: 18px;
    height: 18px;

    object-fit: cover;
    cursor: pointer;

    @media (max-width: 480px) {
        width: 16px;
        height: 16px;

        &.desktop-only {
            display: none;
        }
    }
`;

const ButtonWrap = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
`;

const TitleSection = styled.div``;

const WideModalTitle = styled.p`
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: -0.5px;
    text-align: left;
    color: rgba(0, 0, 0, 0.7);

    margin-bottom: 4px;
`;

const AlertText = styled.div`
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.52;
    letter-spacing: -0.3px;
    text-align: left;
    color: #ff5500;
`;

const FullWidthButton = styled.a`
    display: block;
    width: 100%;
    height: 40px;
    line-height: 40px;
    border-radius: 4px;

    cursor: pointer;

    font-size: 13px;
    font-weight: normal;
    text-align: center;
    font-weight: bold;

    ${props =>
        props.border
            ? `
        border: 1px solid ;
    `
            : ''}

    background-color: ${props => (props.bg ? props.bg : props.bg)};
    color: #ffffff;
`;

const HalfWidthButton = styled(FullWidthButton)`
    width: calc(50% - 4px);
`;

const DivisionLine = styled.div`
    width: 100%;
    height: ${props => (props.big ? '32px' : props.x_small ? '8px' : props.small ? '16px' : '24px')};

    @media (max-width: 480px) {
        height: ${props => (props.big ? '24px' : props.x_small ? '4px' : props.small ? '8px' : '16px')};
    }
`;