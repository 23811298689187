import React from 'react';
import { Input, message, Button } from 'antd';
import Title from '../../../Components/Common/pageTitle';
import { TableWrapper, TableContainer, Label, ValueBox } from './info.styled';

import axios from '../../../Lib/axios';
import TextArea from 'antd/lib/input/TextArea';
import { getLongTermCareInfo } from '../../../Lib/Api/app';
import { InfoBlock } from '../../../Components/Common/block';

export default class LongTermCareInfo extends React.PureComponent {
    state = {
        data: {},
    };

    componentDidMount = () => {
        const { state } = this.props.history.location;
        if (!state || !state.id) return this.props.history.goBack();

        this.fetchInfo(state.id);
    };

    fetchInfo = async id => {
        const { data } = await axios.get('/v1/longTermCare/list/info', {
            params: {
                longTermCareId: id,
            },
        });

        if (!data || data === 'check parameters') {
            return this.props.history.goBack();
        }

        if (data === 'invalid longTermCare') {
            return message.warning('잘못된 게시물 정보입니다.');
        }

        this.setState({ ...data });
    };

    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    render() {
        // console.log(this.state);

        return (
            <>
                <Title title='장기요양 상담 신청서 정보' />
                <TableWrapper>
                    <TableContainer>
                        {this.state && (
                            <>
                                <div className='table'>
                                    <InfoBlock label='이름' value={this.state.name} />
                                    <InfoBlock label='나이' value={this.state.age + ' 세'} />
                                    <InfoBlock label='연락처' value={this.state.contact} />
                                    <InfoBlock label='성별' value={this.state.sex} />
                                    <InfoBlock label='지역' value={this.state.region} />
                                </div>
                            </>
                        )}
                    </TableContainer>
                </TableWrapper>
            </>
        );
    }
}
