import React from 'react';
import moment from 'moment';
import { DeleteOutlined } from "@ant-design/icons"

export const payment = [
    {
        title: '예약코드',
        dataIndex: 'reserveCode',
        align: 'center',
        render: reserveCode => <span>{reserveCode || '-'}</span>,
    },
    {
        title: '신청인',
        dataIndex: 'user',
        align: 'center',
        render: user => <span>{user && user ? user.name : '-'}</span>,
    },
    {
        title: '환자 (이용자)',
        dataIndex: 'patient',
        align: 'center',
        render: patient => <span>{patient || '-'}</span>,
    },
    {
        title: '결제금액',
        dataIndex: 'confirmedPrice',
        align: 'center',
        render: confirmedPrice => <span>{confirmedPrice ? confirmedPrice + '원' : '-' }</span>,
    },
    {
        title: '포인트 사용',
        dataIndex: 'usingPoint',
        align: 'center',
        render: usingPoint => <span>{usingPoint ? usingPoint + '원' : '-' }</span>,
    },
    {
        title: '진료일자',
        dataIndex: 'reserveDate',
        align: 'center',
        render: reserveDate => <span>{moment(reserveDate).format('YYYY.MM.DD HH:mm')}</span>,
    },
];

export const card = ({ onDelete }) => {
    return [
        {
            title: '번호',
            dataIndex: 'index',
            align: 'center',
            width: '70px',
        },
        {
            title: '고객 이름',
            dataIndex: 'userName',
            align: 'center',
            render: userName => <span>{userName ? userName : '-'}</span>,
        },
        {
            title: '카드명',
            dataIndex: 'cardName',
            align: 'center',
        },
        {
            title: '카드번호',
            dataIndex: 'cardNum',
            align: 'center',
        },
        {
            title: '등록일',
            dataIndex: 'createdAt',
            align: 'center',
            render: createdAt => <span>{moment(createdAt).format('YY.MM.DD HH:mm') || '0'}</span>
        },
        {
            title: '삭제',
            dataIndex: 'valid',
            align: 'center',
            width: '60px',
            render: (valid, { _id }) =>
                <DeleteOutlined
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        onDelete({ _id });
                    }}
                />
        },
    ]
};

// export const payment = [
//     {
//         title: '서비스',
//         dataIndex: 'service',
//         align: 'center',
//         render: service => <span>{service === 'unemergency' ? 'pro' : 'basic'}</span>,
//     },
//     {
//         title: '신청인',
//         dataIndex: 'user',
//         align: 'center',
//         render: user => <span>{user && user ? user.name : '-'}</span>,
//     },
//     {
//         title: '환자 (이용자)',
//         dataIndex: 'patient',
//         align: 'center',
//         render: patient => <span>{patient || '-'}</span>,
//     },
//     {
//         title: '환자 성별',
//         dataIndex: 'sex',
//         align: 'center',
//     },
//     {
//         title: '자택주소',
//         dataIndex: 'starting',
//         align: 'center',
//     },
//     {
//         title: '병원주소',
//         dataIndex: 'destination',
//         align: 'center',
//     },
//     {
//         title: '이동 수단',
//         dataIndex: 'transport',
//         align: 'center',
//         render: transport => <span>{transport === 'common' ? '대중교통' : '자차이용'}</span>,

//     },
//     {
//         title: '진료일자',
//         dataIndex: 'reserveDate',
//         align: 'center',
//         render: reserveDate => <span>{moment(reserveDate).format('YYYY.MM.DD HH:mm')}</span>,
//     },
//     {
//         title: '접선시각',
//         dataIndex: 'call',
//         align: 'center',
//         render: call => <span>{call && call.timeline ? `${moment(call.timeline.receipt.time).format('YYYY.MM.DD H시 mm분')}` : '-'}</span>,
//     },
//     // {
//     //     title: '병원출발',
//     //     dataIndex: 'call',
//     //     align: 'center',
//     //     render: call => <span>{call && call.timeline ? `${moment(call.timeline.start.time).format('YYYY.MM.DD H시 mm분')}` : '-'}</span>,
//     // },
//     // {
//     //     title: '병원도착',
//     //     dataIndex: 'call',
//     //     align: 'center',
//     //     render: call => <span>{call && call.timeline ? `${moment(call.timeline.arrived.time).format('YYYY.MM.DD H시 mm분')}` : '-'}</span>,
//     // },
//     {
//         title: '진료시작',
//         dataIndex: 'call',
//         align: 'center',
//         render: call => <span>{call && call.timeline ? `${moment(call.timeline.careStart.time).format('YYYY.MM.DD H시 mm분')}` : '-'}</span>,
//     },
//     {
//         title: '진료종료',
//         dataIndex: 'call',
//         align: 'center',
//         render: call => <span>{call && call.timeline ? `${moment(call.timeline.careDone.time).format('YYYY.MM.DD H시 mm분')}` : '-'}</span>,
//     },
//     // {
//     //     title: '자택출발',
//     //     dataIndex: 'call',
//     //     align: 'center',
//     //     render: call => <span>{call && call.timeline ? `${moment(call.timeline.backStart.time).format('YYYY.MM.DD H시 mm분')}` : '-'}</span>,
//     // },
//     {
//         title: '서비스종료',
//         dataIndex: 'call',
//         align: 'center',
//         render: call => <span>{call && call.timeline ? `${moment(call.timeline.complete.time).format('YYYY.MM.DD H시 mm분')}` : '-'}</span>,
//     },
// ];