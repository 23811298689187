import React from 'react';
import { connect } from 'react-redux';
import StyledServiceItemContainer from './item.styled';
import ServiceItem from './item';
import Title from '../../../../Components/Common/pageTitle';
import {
    getReserveInfo,
    reservePaid,
    reserveExecute,
    reserveReward,
    getUserInfo,
    editUserInfo,
    allowMate,
    modifyReserve,
    getMateList,
} from '../../../../Reducer/Modules/app';
import { userBlock } from '../../../../Reducer/Modules/auth';
import { editServicePage } from '../../../../Reducer/Modules/service';

class ServiceItemPage extends React.PureComponent {
    componentDidMount = () => {
        const { id } = this.props.auth;
        // console.log('admin id: ', id);
        const { state } = this.props.location;
        const { id: reserveId } = this.props.match.params;

        const mateId = this.props.auth.id;
        this.props.fetchMateList({ mateId });

        if (state === 'reserve') this.props.fetchReserveInfo({ reserveId });
    };

    render() {
        return (
            <StyledServiceItemContainer>
                <Title title='서비스 상세내용' />
                <ServiceItem {...this.props} />
            </StyledServiceItemContainer>
        );
    }
}
const mapStateToProps = ({ app, auth }) => ({
    app,
    auth,
});

const mapDispatchToProps = dispatch => ({
    fetchInfo: ({ id, userType }) => dispatch(getUserInfo({ id, userType })),
    fetchMateList: id => dispatch(getMateList({ id })),
    fetchReserveInfo: ({ reserveId }) => dispatch(getReserveInfo({ reserveId })),
    onPaid: ({ reserveId, callId, status }) => dispatch(reservePaid({ reserveId, callId, status })),
    onExecute: ({ callId, status, refundInfo = {} }) => dispatch(reserveExecute({ callId, status, refundInfo })),
    onReward: ({ callId, info }) => dispatch(reserveReward({ callId, info })),

    blockUser: ({ id, targetId, userType, status }) => dispatch(userBlock({ id, targetId, userType, status })),
    allowMate: ({ mateId, type, reason }) => dispatch(allowMate({ mateId, type, reason })),
    editUserInfo: ({ type, value }) => dispatch(editUserInfo({ type, value })),

    editPage: page => dispatch(editServicePage(page)),

    modifyReserveInfo: ({ id, reserveId, info }) => dispatch(modifyReserve({ id, reserveId, info })),
});

export default connect(mapStateToProps, mapDispatchToProps)(ServiceItemPage);
