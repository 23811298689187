import React from 'react';
import { Button, notification, Table, message } from 'antd';

import Title from '../../../Components/Common/pageTitle';
import { TableContainer, TableWrapper } from './list.styled';

import axios from '../../../Lib/axios';
import { product } from '../../../Components/Columns/service';

export default class ProductList extends React.PureComponent {
    state = {
        list: [],
    };

    componentDidMount = () => {
        this.fetchList();
    };

    fetchList = async () => {
        const { data } = await axios.get('/v1/product/service/list');
        if (data && Array.isArray(data)) {
            this.setState({ list: data });
        }
    };

    onItem = row => {
        return {
            onClick: () => {
                this.props.history.push({
                    pathname: '/product/info',
                    state: {
                        id: row.id,
                    },
                });
            },
        };
    };

    onServiceDelete = async ({ _id }) => {
        const { id } = this.state;
        console.log('_id: ', _id);

        const confirmed = window.confirm('해당 상품(서비스)를 삭제하시겠습니까?');
        if (!confirmed) return null;

        const { data } = await axios.post('/v1/product/delete/service', { productId: _id });
        if (data === 'success') {
            notification.open({
                message: '상품(서비스)를 삭제하였습니다.',
                description: '상품(서비스)를 삭제하였습니다.',
                type: 'success',
            });
            window.location.reload();
        }
    };

    render() {
        // console.log(this.state);
        return (
            <>
                <Title title='상품(서비스) 목록' />
                <TableWrapper>
                    <TableContainer>
                        <Table
                            rowKey='id'
                            dataSource={this.state.list || []}
                            columns={product({ onDelete: this.onServiceDelete })}
                            onRow={this.onItem}
                            scroll={{ x: 400 }}
                        />
                        <Button
                            type='primary'
                            onClick={() => {
                                this.props.history.push('/product/create');
                            }}
                        >
                            새 상품 추가
                        </Button>
                    </TableContainer>
                </TableWrapper>
            </>
        );
    }
}
