import React from 'react';
import { connect } from 'react-redux';
import StyledContainer from './create.styled';
import Create from './create';
import Title from '../../../Components/Common/pageTitle';
import { addCorp } from '../../../Reducer/Modules/service';

class CorpCreatePage extends React.PureComponent {
    componentDidMount = () => {

    };

    render() {
        return (
            <StyledContainer>
                <Title title='사설 구급업체 추가등록' />
                <Create {...this.props} />
            </StyledContainer>
        );
    }
}
const mapStateToProps = ({ app, auth, service }) => ({
    app,
    auth,
    service
});

const mapDispatchToProps = dispatch => ({
    onAdd: info => dispatch(addCorp({ info }))
});

export default connect(mapStateToProps, mapDispatchToProps)(CorpCreatePage);
