import { createAction, handleActions } from 'redux-actions';
import produce from 'immer';
import * as eventAPI from '../../Lib/Api/event';
import _ from 'lodash';

// middleware
import { createReduxThunk } from '../../Lib/middleware';

const GET_EVENT_LIST = 'event/GET_EVENT_LIST';
const GET_EVENT_LIST_SUCCESS = 'event/GET_EVENT_LIST_SUCCESS';
const GET_EVENT_INFO = 'event/GET_EVENT_INFO';
const GET_EVENT_INFO_SUCCESS = 'event/GET_EVENT_INFO_SUCCESS';

// initalState
const initialState = {
    eventList: [],
    eventInfo: {},
};

export const getEventList = createReduxThunk(GET_EVENT_LIST, eventAPI.getEventList);

// reducer
const eventReducer = handleActions(
    {
        [GET_EVENT_LIST_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft['eventList'] = payload;
            }),

        [GET_EVENT_INFO_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                if (payload && !_.isEmpty(payload)) {
                    draft['eventInfo'] = payload[0];
                }
            }),
    },
    initialState,
);
export default eventReducer;
