import React from 'react';
import { Table } from 'antd';
import { payment as paymentColumn } from '../../../../Components/Columns/payment';

class PaymentList extends React.PureComponent {
    onRow = row => {
        return {
            onClick: () => {
                this.props.history.push({
                    pathname: `/service/info/${row._id}`,
                    state: 'reserve',
                });
            },
        };
    };

    render() {
        const { payment } = this.props.app;
        // console.log(payment);
        return (
            <div className='container'>
                <div className='table'>
                    <Table
                        rowKey='_id'
                        dataSource={payment}
                        columns={paymentColumn}
                        onRow={this.onRow}
                        scroll={{ x: 450 }}
                        pagination={{
                            pageSize: 10,
                        }}
                    />
                </div>
            </div>
        );
    }
}
export default PaymentList;
