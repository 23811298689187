import React from 'react';
import { connect } from 'react-redux';
import StyledUserContainer from './user.styled';
import User from './user';
import Title from '../../../../Components/Common/pageTitle';
import { getUserInfo } from '../../../../Reducer/Modules/app';
import { userBlock } from '../../../../Reducer/Modules/auth';

class UserPage extends React.PureComponent {
    componentDidMount = () => {
        const { state } = this.props.history.location;
        if (state.id) this.props.fetchInfo({ id: state.id, userType: 'user' });
    };

    render() {
        return (
            <StyledUserContainer>
                <Title title='일반 사용자 상세정보' />
                <User {...this.props} />
            </StyledUserContainer>
        );
    }
}

const mapStateToProps = ({ app, auth }) => ({
    app,
    auth,
});

const mapDispatchToProps = dispatch => ({
    fetchInfo: ({ id, userType }) => dispatch(getUserInfo({ id, userType })),
    blockUser: ({ id, targetId, userType, status }) => dispatch(userBlock({ id, targetId, userType, status })),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserPage);
