import React from 'react';
import moment from 'moment';
import { Button } from 'antd';
import { DeleteOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';

export const service = [
    {
        title: '이름',
        dataIndex: 'name',
        width: '120px',
        align: 'center',
    },
    {
        title: '가격',
        dataIndex: 'price',
        align: 'center',
    },
    {
        title: '가격정책',
        dataIndex: 'priceType',
        align: 'center',
        render: priceType => <span>{priceType === 'regular' ? '균일가' : priceType === 'perOur' ? '시간당' : '-'}</span>,
    },
    {
        title: '할인액',
        dataIndex: 'discount',
        align: 'center',
        render: discount => <span>{discount}원</span>,
    },
    {
        title: '할인율',
        dataIndex: 'discountPer',
        align: 'center',
        render: discountPer => <span>{discountPer}%</span>,
    },
    {
        title: '판매여부',
        dataIndex: 'valid',
        align: 'center',
        render: valid => <span>{valid ? '판매중' : '판매중지'}</span>,
    },
];

export const reserve = [
    {
        title: '예약번호',
        dataIndex: 'reserveCode',
        align: 'center',
    },
    {
        title: '신청인',
        dataIndex: 'user',
        align: 'center',
        render: user => <span>{user && user.name ? user.name : '-'}</span>,
    },
    {
        title: '대상',
        dataIndex: 'patient',
        align: 'center',
    },
    {
        title: '서비스',
        dataIndex: 'productInfo',
        align: 'center',
        render: productInfo => <span>{productInfo && productInfo.name ? `${productInfo.name}` : '-'}</span>,
        // filters: [
        //     {
        //         text: 'ㆍBig 5', value: 'Big 5',
        //         children: [
        //             { text: '신촌 세브란스 병원 동행 서비스', value: '신촌 세브란스 병원 동행 서비스' },
        //             { text: '서울대학교 병원 동행 서비스', value: '서울대학교 병원 동행 서비스' },
        //             { text: '삼성 서울 병원 동행 서비스', value: '삼성 서울 병원 동행 서비스' },
        //             { text: '서울 아산 병원 동행 서비스', value: '서울 아산 병원 동행 서비스' },
        //             { text: '서울 성모 병원 동행 서비스', value: '서울 성모 병원 동행 서비스' }
        //         ]
        //     },
        //     {
        //         text: 'ㆍ지역별 병원', value: '지역별 병원',
        //         children: [
        //             { text: '서울특별시 병원 동행 서비스', value: '서울특별시 병원 동행 서비스' },
        //             { text: '경기도 병원 동행 서비스', value: '경기도 병원 동행 서비스' },
        //         ]
        //     },
        //     {
        //         text: 'ㆍ기업 기관별', value: '기업 기관별',
        //         children: [
        //             { text: '아주대학교 요양병원 동행 서비스', value: '아주대학교 요양병원 동행 서비스' },
        //             { text: 'LG 에너지솔루션 임직원 병원 동행 서비스', value: 'LG 에너지솔루션 임직원 병원 동행 서비스' },
        //             { text: '고양 해피 케어 병원 동행 서비스', value: '고양 해피 케어 병원 동행 서비스' },
        //             { text: '위드메이트 협력 기관 서비스', value: '위드메이트 협력 기관 서비스' },
        //             { text: '에리치 회원 전용 서비스', value: '에리치 회원 전용 서비스' }
        //         ]
        //     },
        //     { text: '수면 내시경 보호자 서비스', value: '수면 내시경 보호자 서비스' },
        // ],
        // filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        // onFilter: (value, record) => record.productInfo.name.indexOf(value) === 0,
        // onFilter: (value, record) => record.productInfo.name.includes(value),
        // sorter: (a, b) => a.productInfo.name.localeCompare(b.productInfo.name)
    },
    {
        title: '총 이용시간',
        dataIndex: 'usageTime',
        align: 'center',
        render: usageTime => <span>{usageTime ? usageTime + '시간' : '-'}</span>,
        sorter: (a, b) => a.usageTime - b.usageTime,
    },
    {
        title: '결제금액',
        dataIndex: 'confirmedPrice',
        align: 'center',
        render: confirmedPrice => <span>{confirmedPrice ? confirmedPrice + '원' : '-'}</span>,
    },
    // {
    //     title: '결제상태',
    //     dataIndex: 'paidStatus',
    //     align: 'center',
    //     render: paidStatus => <span>{paidStatus === true ? '결제완료' : '결제대기'}</span>,
    // },
    {
        title: '예약신청일자',
        dataIndex: 'call',
        align: 'center',
        render: call => <span>{moment(call.createdAt).format('YY년 MM월 DD일, HH시 mm분')}</span>,
    },
    {
        title: '진료예약일자',
        dataIndex: 'reserveDate',
        align: 'center',
        render: reserveDate => <span>{moment(reserveDate).format('YY년 MM월 DD일, HH시 mm분')}</span>,
    },
    {
        title: '접수상태',
        dataIndex: 'call',
        align: 'center',
        render: call => <span>{call && call.status ? call.status : '-'}</span>,
        // filters: [
        //     { text: '접수대기', value: '접수대기' },
        //     { text: '예약접수', value: '예약접수' },
        //     { text: '예약완료', value: '예약완료' },
        //     { text: '접수취소', value: '접수취소' },
        //     { text: '서비스 종료', value: '서비스 종료' },
        // ],
        // filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        // onFilter: (value, record) => record.call.status.indexOf(value) === 0,
    },
];

export const reserveCard = [
    {
        title: '신청인',
        dataIndex: 'user',
        align: 'center',
        render: user => <span>{user && user.name ? user.name : '-'}</span>,
    },
    {
        title: '결제수단',
        dataIndex: 'payment',
        align: 'center',
        render: payment => <span>{payment === 'card' ? '카드결제' : '계좌이체'}</span>,
    },
    {
        title: '카드명',
        dataIndex: 'cardName',
        align: 'center',
    },
    {
        title: '카드번호',
        dataIndex: 'cardNum',
        align: 'center',
    },
    {
        title: '유효기간(MM/YY)',
        dataIndex: 'year',
        align: 'center',
        render: (year, { month }) => <span>{month && year ? month + ' / ' + year : '-'}</span>,
    },
    {
        title: '할부기간',
        dataIndex: 'installment',
        align: 'center',
    },
    {
        title: '결제금액',
        dataIndex: 'confirmedPrice',
        align: 'center',
        render: confirmedPrice => <span>{confirmedPrice ? confirmedPrice + '원' : '-'}</span>,
    },
    {
        title: '진료예약일자',
        dataIndex: 'reserveDate',
        align: 'center',
        render: reserveDate => <span>{moment(reserveDate).format('YY년 MM월 DD일, HH시 mm분')}</span>,
    },
];

export const CorpColumns = [
    {
        title: '주소',
        dataIndex: 'addr1',
        align: 'center',
        render: (addr1, { addr2, addr3 }) => (
            <span>
                {addr1} {addr2} {addr3 || ''}
            </span>
        ),
    },
    {
        title: '기관명',
        dataIndex: 'name',
        align: 'center',
    },
    {
        title: '연락처',
        dataIndex: 'contact',
        align: 'center',
    },
    {
        title: '담당자',
        dataIndex: 'manager',
        align: 'center',
    },
];

export const SharedColumn = [
    {
        title: '기관명',
        dataIndex: 'corp',
        align: 'center',
    },
    {
        title: '지역',
        dataIndex: 'address',
        align: 'center',
    },
    {
        title: '담당자',
        dataIndex: 'manager',
        align: 'center',
    },
    {
        title: '전화번호',
        dataIndex: 'contact',
        align: 'center',
    },
    {
        title: '메시지',
        dataIndex: 'message',
        align: 'center',
    },
    {
        title: '신청일',
        dataIndex: 'createdAt',
        align: 'center',
        render: createdAt => <span>{moment(createdAt).format('YYYY-MM-DD HH:mm')}</span>,
    },
];

export const MessageListColumn = [
    {
        title: '수신대상',
        dataIndex: 'to',
        align: 'center',
        width: '100px',
    },
    {
        title: '연락처',
        dataIndex: 'contact',
        align: 'center',
        width: '130px',
    },
    {
        title: '발송내용',
        dataIndex: 'message',
        align: 'center',
    },
    {
        title: '발신시각',
        dataIndex: 'createdAt',
        align: 'center',
        width: '140px',
        render: createdAt => <span>{moment(createdAt).format('YYYY-MM-DD HH:mm')}</span>,
    },
];

export const ETCColumn = [
    // {
    //     title: '대상',
    //     dataIndex: 'partner',
    //     align: 'center',
    //     render: partner => <span>{partner.name || '-'}</span>,
    // },
    {
        title: '신청인',
        dataIndex: 'user',
        align: 'center',
        render: user => <span>{user.name || '-'}</span>,
    },
    {
        title: '신청인 연락처',
        dataIndex: 'userId',
        align: 'center',
        render: (userId, { user }) => <span>{user.contact || '-'}</span>,
    },
    {
        title: '신청시각',
        dataIndex: 'createdAt',
        align: 'center',
        render: createdAt => <span>{moment(createdAt).format('YYYY-MM-DD HH:mm')}</span>,
    },
];

export const product = ({ onDelete }) => {
    return [
        {
            title: '상품명',
            dataIndex: 'name',
            align: 'center',
            render: name => <span>{name || '-'}</span>,
        },
        {
            title: 'Basic',
            dataIndex: 'useBasic',
            align: 'center',
            render: (useBasic, { basicPrice, basicDiscount }) => (
                <span>{useBasic ? `지원중 (시간당 ${basicPrice || 0}원 / ${basicDiscount || 0})원 할인` : '지원안함'}</span>
            ),
        },
        {
            title: 'Pro',
            dataIndex: 'usePro',
            align: 'center',
            render: (usePro, { proPrice, proDiscount }) => (
                <span>{usePro ? `지원중 (시간당 ${proPrice || 0}원 / ${proDiscount || 0}) 원 할인` : '지원안함'}</span>
            ),
        },
        {
            title: '운영 상태',
            dataIndex: 'valid',
            align: 'center',
            render: valid => <span>{valid ? '운영중' : '운영안함'}</span>,
        },
        {
            title: '노출 순서',
            dataIndex: 'listOrder',
            align: 'center',
            render: listOrder => <span>{listOrder}번</span>,
        },
        {
            title: '삭제',
            dataIndex: 'createdAt',
            align: 'center',
            width: '58px',
            render: (createdAt, { _id }) => (
                <DeleteOutlined
                    onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();

                        onDelete({ _id });
                    }}
                />
            ),
        },
    ];
};

export const point = [
    {
        title: '이름',
        dataIndex: 'user',
        align: 'center',
        render: user => <span>{user && user.length > 0 ? user[0].name : '-'}</span>,
    },
    {
        title: '포인트명',
        dataIndex: 'name',
        align: 'center',
        render: name => <span>{name ? name + '원' : '-'}</span>,
    },
    {
        title: '포인트 적립 / 사용',
        dataIndex: 'actionType',
        align: 'center',
        render: actionType => <span>{actionType ? actionType : '-'}</span>,
    },
    {
        title: '금액',
        dataIndex: 'value',
        align: 'center',
        render: value => <span>{value || '0'}</span>,
    },
    {
        title: '잔여 포인트',
        dataIndex: 'leftValue',
        align: 'center',
        render: leftValue => <span>{leftValue || '0'}</span>,
    },
];

export const review = [
    {
        title: '사용자',
        dataIndex: 'user',
        align: 'center',
        render: user => <span>{user ? user.name : '-'}</span>,
    },
    {
        title: '담당 메이트',
        dataIndex: 'reserve',
        align: 'center',
        render: reserve => <span>{(reserve && reserve.call && reserve.call.mate && reserve.call.mate.name) || '-'}</span>,
    },
    {
        title: '평점',
        dataIndex: 'score',
        align: 'center',
        render: score => <span>{score || '0'}</span>,
    },
    {
        title: '리뷰 제목',
        dataIndex: 'title',
        align: 'center',
    },
    {
        title: '리뷰 내용',
        dataIndex: 'review',
        align: 'center',
    },
    {
        title: '진료 일자',
        dataIndex: 'reserveDate',
        align: 'center',
        render: reserveDate => <span>{moment(reserveDate).format('YY.MM.DD HH:mm') || '0'}</span>,
    },
    {
        title: '리뷰등록 일자',
        dataIndex: 'createdAt',
        align: 'center',
        render: createdAt => <span>{moment(createdAt).format('YY.MM.DD HH:mm') || '0'}</span>,
    },
];

export const newsEvent = ({ onDelete }) => {
    return [
        {
            title: '번호',
            dataIndex: 'index',
            align: 'center',
            width: '70px',
        },
        {
            title: '제목',
            dataIndex: 'title',
        },
        {
            title: '등록일',
            dataIndex: 'createdAt',
            align: 'center',
            render: createdAt => <span>{moment(createdAt).format('YY.MM.DD HH:mm') || '0'}</span>,
        },
        {
            title: '삭제',
            dataIndex: 'valid',
            align: 'center',
            width: '60px',
            render: (valid, { _id }) => (
                <DeleteOutlined
                    onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();

                        onDelete({ _id });
                    }}
                />
            ),
        },
    ];
};

export const longTermCare = ({ onDelete }) => {
    return [
        {
            title: '번호',
            dataIndex: 'index',
            align: 'center',
            width: '70px',
        },
        {
            title: '신청인',
            dataIndex: 'name',
            align: 'center',
        },
        {
            title: '연락처',
            dataIndex: 'contact',
            align: 'center',
        },
        {
            title: '신청일',
            dataIndex: 'createdAt',
            align: 'center',
            render: createdAt => <span>{moment(createdAt).format('YY.MM.DD HH:mm') || '0'}</span>,
        },
        {
            title: '삭제',
            dataIndex: 'valid',
            align: 'center',
            width: '60px',
            render: (valid, { _id }) => (
                <DeleteOutlined
                    onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();

                        onDelete({ _id });
                    }}
                />
            ),
        },
    ];
};

export const userUseCoupon = [
    {
        title: '번호',
        dataIndex: 'index',
        align: 'center',
        width: '70px',
    },
    {
        title: '고객이름',
        dataIndex: 'userName',
        align: 'center',
        render: userName => <span>{userName ? userName : '-'}</span>,
    },
    {
        title: '쿠폰명',
        dataIndex: 'couponName',
        align: 'center',
    },
    {
        title: '포인트',
        dataIndex: 'couponValue',
        align: 'center',
    },
    {
        title: '등록일',
        dataIndex: 'addedAt',
        align: 'center',
        render: addedAt => <span>{moment(addedAt).format('YY.MM.DD') || '0'}</span>,
    },
];

export const coupon = ({ onDelete }) => {
    return [
        {
            title: '번호',
            dataIndex: 'index',
            align: 'center',
            width: '70px',
        },
        {
            title: '쿠폰명',
            dataIndex: 'couponName',
            align: 'center',
        },
        {
            title: '쿠폰 코드',
            dataIndex: 'appointedCode',
            align: 'center',
        },
        {
            title: '포인트',
            dataIndex: 'couponValue',
            align: 'center',
        },
        {
            title: '생성일',
            dataIndex: 'createdAt',
            align: 'center',
            render: createdAt => <span>{moment(createdAt).format('YY.MM.DD') || '0'}</span>,
        },
        {
            title: '삭제',
            dataIndex: 'valid',
            align: 'center',
            width: '60px',
            render: (valid, { _id }) => (
                <DeleteOutlined
                    onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();

                        onDelete({ _id });
                    }}
                />
            ),
        },
    ];
};
