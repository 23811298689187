import React from 'react';
import { connect } from 'react-redux';
import StyledContainer from './item.styled';
import Item from './item';
import Title from '../../../Components/Common/pageTitle';

class MessageItemPage extends React.PureComponent {
    componentDidMount = () => {};

    render() {
        return (
            <StyledContainer>
                <Title title='이용가격 안내 상세' />
                <Item {...this.props} />
            </StyledContainer>
        );
    }
}
const mapStateToProps = ({ app, auth, service }) => ({
    app,
    auth,
    service,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(MessageItemPage);
