import React from 'react';
import { notification, Table } from 'antd';
import { longTermCare } from '../../../Components/Columns/service';

import { TableContainer, TableWrapper } from './list.styled';
import { onDeleteLongTermCare } from '../../../Lib/Api/app';

export default class LongTermCareList extends React.PureComponent {

    onRow = (row, index) => {
        return {
            onClick: () => {
                this.props.history.push({
                    pathname: `/longTermCare/info/${row._id}`,
                    state: {
                        id: row._id,
                    },
                });
            },
        };
    };

    onLongTermCareDelete = async ({ _id }) => {
        console.log('_id: ', _id);

        const confirmed = window.confirm('해당 게시물을 삭제하시겠습니까?');
        if (!confirmed) return null;

        onDeleteLongTermCare({ longTermCareId: _id })
            .then(resolve => {
                if (resolve && resolve.data === 'success') {
                    notification.open({
                        message: '게시물을 삭제하였습니다.',
                        description: '게시물을 삭제하였습니다.',
                        type: 'success'
                    });
                    window.location.reload();
            }});
    };

    render() {
        // console.log(this.props.app.longTermCare);
        return (
            <>
                <TableWrapper>
                    <TableContainer>
                        <Table
                            rowKey='id'
                            dataSource={this.props.app.longTermCare.data}
                            columns={longTermCare({ onDelete: this.onLongTermCareDelete })}
                            onRow={this.onRow}
                            pagination={{
                                pageSize: 10
                            }}
                        />
                    </TableContainer>
                </TableWrapper>
            </>
        );
    }
}
