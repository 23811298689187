import styled from 'styled-components';

const StyledContainer = styled.div``;

export default StyledContainer;

export const Container = styled.div`
    padding: 20px;
`;

export const Label  = styled.p`
    margin-bottom: 4px;
`;

export const InputWrapper = styled.div`
    margin-bottom: 12px;
`;