import { createAction, handleActions } from 'redux-actions';
import produce from 'immer';
import * as appAPI from '../../Lib/Api/app';
import _ from 'lodash';
// middleware
import { createReduxThunk } from '../../Lib/middleware';

const LOADING_ON = 'app/LOADING_ON';
const LOADING_OFF = 'app/LOADING_OFF';

const GET_USER_LIST = 'app/GET_USER_LIST';
const GET_USER_LIST_SUCCESS = 'app/GET_USER_LIST_SUCCESS';
const GET_USER_PAGING_LIST = 'app/GET_USER_PAGING_LIST';
const GET_USER_PAGING_LIST_SUCCESS = 'app/GET_USER_PAGING_LIST_SUCCESS';

const GET_USER_COUNT = 'app/GET_USER_COUNT';
const GET_USER_COUNT_SUCCESS = 'app/GET_USER_COUNT_SUCCESS';
const GET_MATE_COUNT = 'app/GET_MATE_COUNT';
const GET_MATE_COUNT_SUCCESS = 'app/GET_MATE_COUNT_SUCCESS';
const GET_RESERVE_COUNT = 'app/GET_RESERVE_COUNT';
const GET_RESERVE_COUNT_SUCCESS = 'app/GET_RESERVE_COUNT_SUCCESS';

const GET_MATE_LIST = 'app/GET_MATE_LIST';
const GET_MATE_LIST_SUCCESS = 'app/GET_MATE_LIST_SUCCESS';
const ALLOW_MATE = 'app/ALLOW_MATE';
const ALLOW_MATE_SUCCESS = 'app/ALLOW_MATE_SUCCESS';
const GET_MATE_INFO = 'app/GET_MATE_INFO';
const GET_MATE_INFO_SUCCESS = 'app/GET_MATE_INFO_SUCCESS';

// mate apply new
const GET_MATEAPPLY_LIST = 'app/GET_MATEAPPLY_LIST';
const GET_MATEAPPLY_LIST_SUCCESS = 'app/GET_MATEAPPLY_LIST_SUCCESS';
const GET_MATEAPPLY_INFO = 'app/GET_MATEAPPLY_INFO';
const GET_MATEAPPLY_INFO_SUCCESS = 'app/GET_MATEAPPLY_INFO_SUCCESS';
const DELETE_MATEAPPLY = 'app/DELETE_MATEAPPLY';
const DELETE_MATEAPPLY_SUCCESS = 'app/DELETE_MATEAPPLY_SUCCESS';

const GET_PATIENT = 'app/GET_PATIENT';
const GET_PATIENT_SUCCESS = 'app/GET_PATIENT_SUCCESS';
const GET_PATIENT_INFO = 'app/GET_PATIENT_INFO';
const GET_PATIENT_INFO_SUCCESS = 'app/GET_PATIENT_INFO_SUCCESS';

const GET_USER_INFO = 'app/GET_USER_INFO';
const GET_USER_INFO_SUCCESS = 'app/GET_USER_INFO_SUCCESS';

const GET_PRODUCT_LIST = 'app/GET_PRODUCT_LIST';
const GET_PRODUCT_LIST_SUCCESS = 'app/GET_PRODUCT_LIST_SUCCESS';
const GET_PRODUCT_INFO = 'app/GET_PRODUCT_INFO';
const GET_PRODUCT_INFO_SUCCESS = 'app/GET_PRODUCT_INFO_SUCCESS';

const GET_PAYMENT_LIST = 'app/GET_PAYMENT_LIST';
const GET_PAYMENT_LIST_SUCCESS = 'app/GET_PAYMENT_LIST_SUCCESS';
// const GET_SERVICE_REQUEST_INFO = 'app/GET_SERVICE_REQUEST_INFO';
// const GET_SERVICE_REQUEST_INFO_SUCCESS = 'app/GET_SERVICE_REQUEST_INFO_SUCCESS';

const RESERVE = 'app/RESERVE';
const RESERVE_SUCCESS = 'app/RESERVE_SUCCESS';
const RESREVE_INFO = 'app/RESREVE_INFO';
const RESREVE_INFO_SUCCESS = 'app/RESREVE_INFO_SUCCESS';

const GET_RESREVE_LIST = 'app/GET_RESREVE_LIST';
const GET_RESREVE_LIST_SUCCESS = 'app/GET_RESREVE_LIST_SUCCESS';
const GET_RESREVE_INFO = 'app/GET_RESREVE_INFO';
const GET_RESREVE_INFO_SUCCESS = 'app/GET_RESREVE_INFO_SUCCESS';
const RESERVE_PAID = 'app/RESERVE_PAID';
const RESERVE_PAID_SUCCESS = 'app/RESERVE_PAID_SUCCESS';
const RESERVE_EXECUTE = 'app/RESERVE_EXECUTE';
const RESERVE_EXECUTE_SUCCESS = 'app/RESERVE_EXECUTE_SUCCESS';
const RESERVE_REWARD = 'app/RESERVE_REWARD';
const RESERVE_REWARD_SUCCESS = 'app/RESERVE_REWARD_SUCCESS';

const GET_APPLY_LIST = 'app/GET_APPLY_LIST';
const GET_APPLY_LIST_SUCCESS = 'app/GET_APPLY_LIST_SUCCESS';

const GET_REVIEW_LIST = 'app/GET_REVIEW_LIST';
const GET_REVIEW_LIST_SUCCESS = 'app/GET_REVIEW_LIST_SUCCESS';
const GET_REVIEW_INFO = 'app/GET_REVIEW_INFO';
const GET_REVIEW_INFO_SUCCESS = 'app/GET_REVIEW_INFO_SUCCESS';

const SERVICE_RESERVE = 'app/SERVICE_RESERVE';
const SERVICE_RESERVE_SUCCESS = 'app/SERVICE_RESERVE_SUCCESS';

const EDIT_USER_INFO = 'app/EDIT_USER_INFO';
const EDIT_PAGING_USER = 'app/EDIT_PAGING_USER';
const EDIT_NEW_RESERVE = 'app/EDIT_NEW_RESERVE';

const EDIT_APPLY_PAGE = 'app/EDIT_APPLY_PAGE';
const EDIT_REVIEW_PAGE = 'app/EDIT_REVIEW_PAGE';

const DELETE_MATE_APPLY = 'app/DELETE_MATE_APPLY';
const DELETE_MATE_APPLY_SUCCESS = 'app/DELETE_MATE_APPLY_SUCCESS';

const GET_AUTH_CODE_LIST = 'app/GET_AUTH_CODE_LIST';
const GET_AUTH_CODE_LIST_SUCCESS = 'app/GET_AUTH_CODE_LIST_SUCCESS';

// newevent
const GET_NEWSEVENT_LIST = 'app/GET_NEWSEVENT_LIST';
const GET_NEWSEVENT_LIST_SUCCESS = 'app/GET_NEWSEVENT_LIST_SUCCESS';
const GET_NEWSEVENT_INFO = 'app/GET_NEWSEVENT_INFO';
const GET_NEWSEVENT_INFO_SUCCESS = 'app/GET_NEWSEVENT_INFO_SUCCESS';
const EDIT_NEWSEVENT = 'app/EDIT_NEWSEVENT';
const EDIT_NEWSEVENT_SUCCESS = 'app/EDIT_NEWSEVENT_SUCCESS';
const DELETE_NEWSEVENT = 'app/DELETE_NEWSEVENT';
const DELETE_NEWSEVENT_SUCCESS = 'app/DELETE_NEWSEVENT_SUCCESS';

// longtermcare
const GET_LONGTERMCARE_LIST = 'app/GET_LONGTERMCARE_LIST';
const GET_LONGTERMCARE_LIST_SUCCESS = 'app/GET_LONGTERMCARE_LIST_SUCCESS';
const GET_LONGTERMCARE_INFO = 'app/GET_LONGTERMCARE_INFO';
const GET_LONGTERMCARE_INFO_SUCCESS = 'app/GET_LONGTERMCARE_INFO_SUCCESS';
const DELETE_LONGTERMCARE = 'app/DELETE_LONGTERMCARE';
const DELETE_LONGTERMCARE_SUCCESS = 'app/DELETE_LONGTERMCARE_SUCCESS';

// card
const GET_CARD_LIST = 'app/GET_CARD_LIST';
const GET_CARD_LIST_SUCCESS = 'app/GET_CARD_LIST_SUCCESS';
const GET_CARD_INFO = 'app/GET_CARD_INFO';
const GET_CARD_INFO_SUCCESS = 'app/GET_CARD_INFO_SUCCESS';
const DELETE_CARD = 'app/DELETE_CARD';
const DELETE_CARD_SUCCESS = 'app/DELETE_CARD_SUCCESS';

const MODIFY_RESERVE = 'app/MODIFY_RESERVE';
const MODIFY_RESERVE_SUCCESS = 'app/MODIFY_RESERVE_SUCCESS';

// coupon
const GET_COUPON_LIST = 'app/GET_COUPON_LIST';
const GET_COUPON_LIST_SUCCESS = 'app/GET_COUPON_LIST_SUCCESS';
const GET_USE_COUPON_LIST = 'app/GET_USE_COUPON_LIST';
const GET_USE_COUPON_LIST_SUCCESS = 'app/GET_USE_COUPON_LIST_SUCCESS';
const GET_COUPON_INFO = 'app/GET_COUPON_INFO';
const GET_COUPON_INFO_SUCCESS = 'app/GET_COUPON_INFO_SUCCESS';
const EDIT_COUPON = 'app/EDIT_COUPON';
const EDIT_COUPON_SUCCESS = 'app/EDIT_COUPON_SUCCESS';
const DELETE_COUPON = 'app/DELETE_COUPON';
const DELETE_COUPON_SUCCESS = 'app/DELETE_COUPON_SUCCESS';

// initalState
const initialState = {
    user: [],
    userCount: [],
    userInfo: {},

    reviews: [],
    reviewInfo: {},
    pagingUser: {
        page: 0,
        list: [],
    },
    newReserve: {
        service: 'unemergency',
        sex: '남',
        payment: 'card',
    },

    mate: [],
    mateCount: [],
    mateInfo: {},

    mateApplyList: [],
    mateApplyInfo: {},

    patientList: [],
    patientInfo: {},

    newsEvent: [],
    newsEventInfo: {},

    longTermCare: [],
    longTermCareInfo: {},

    cardList: [],
    cardInfo: {},

    coupon: [],
    useCoupon: [],
    couponInfo: {},

    payment: [],
    paymentInfo: {},

    product: [],
    productInfo: {},

    serviceRequest: [],
    serviceRequestInfo: [],

    reserve: [],
    reserveInfo: {},
    reserveList: [],
    reserveCount: [],

    applyList: [],
    applyInfo: {},
    loading: false,
    applyPage: 1,
    reviewPage: 1,

    codeList: [],
};

export const getUserCount = createReduxThunk(GET_USER_COUNT, appAPI.getUserCount);
export const getMateCount = createReduxThunk(GET_MATE_COUNT, appAPI.getMateCount);
export const getReserveCount = createReduxThunk(GET_RESERVE_COUNT, appAPI.getReserveCount);

export const getUserList = createReduxThunk(GET_USER_LIST, appAPI.getUserList);
export const getMateList = createReduxThunk(GET_MATE_LIST, appAPI.getMateList);
export const getMateInfo = createReduxThunk(GET_MATE_INFO, appAPI.getMateInfo);
export const getPatientInfo = createReduxThunk(GET_PATIENT_INFO, appAPI.getPatientInfo);

// mate apply new
export const getMateApplyList = createReduxThunk(GET_MATEAPPLY_LIST, appAPI.getMateApplyList);
export const getMateApplyInfo = createReduxThunk(GET_MATEAPPLY_INFO, appAPI.getMateApplyInfo);
export const deleteMateApply_v2 = createReduxThunk(DELETE_MATEAPPLY, appAPI.onDeleteMateApply_v2);

export const getUserPagingList = createReduxThunk(GET_USER_PAGING_LIST, appAPI.getPagingUserList);
export const allowMate = createReduxThunk(ALLOW_MATE, appAPI.allowMate);
export const getUserInfo = createReduxThunk(GET_USER_INFO, appAPI.getUserInfo);

export const getProductList = createReduxThunk(GET_PRODUCT_LIST, appAPI.getProductList);
export const getProductInfo = createReduxThunk(GET_PRODUCT_INFO, appAPI.getProductInfo);

export const getPaymentList = createReduxThunk(GET_PAYMENT_LIST, appAPI.getPaidList);
// export const getServiceRequestInfo = createReduxThunk(GET_SERVICE_REQUEST_INFO, appAPI.getServiceRequestInfo);
export const getReserveList = createReduxThunk(GET_RESREVE_LIST, appAPI.getReserveList);
export const getReserveInfo = createReduxThunk(GET_RESREVE_INFO, appAPI.getReserveInfo);

export const ReserveInfo = createReduxThunk(RESREVE_INFO, appAPI.ReserveInfo);

export const reservePaid = createReduxThunk(RESERVE_PAID, appAPI.reservePaid);
export const reserveExecute = createReduxThunk(RESERVE_EXECUTE, appAPI.reserveExecute);
export const reserveReward = createReduxThunk(RESERVE_REWARD, appAPI.reserveReward);
export const serviceReserve = createReduxThunk(SERVICE_RESERVE, appAPI.serviceReserve);

export const getReviewList = createReduxThunk(GET_REVIEW_LIST, appAPI.getReviewList);
export const getReviewInfo = createReduxThunk(GET_REVIEW_INFO, appAPI.getReviewInfo);

export const getApplyList = createReduxThunk(GET_APPLY_LIST, appAPI.getApplyList);
export const editUserInfo = createAction(EDIT_USER_INFO, input => input);
export const editPagingUser = createAction(EDIT_PAGING_USER, input => input);
export const editNewReserve = createAction(EDIT_NEW_RESERVE, input => input);

export const editApplyPage = createAction(EDIT_APPLY_PAGE, input => input);
export const editReviewPage = createAction(EDIT_REVIEW_PAGE, input => input);

export const deleteMateApply = createReduxThunk(DELETE_MATE_APPLY, appAPI.deleteMateApply);

export const getAuthCodeList = createReduxThunk(GET_AUTH_CODE_LIST, appAPI.getAuthCodeList);

// news & event
export const getNewsEventList = createReduxThunk(GET_NEWSEVENT_LIST, appAPI.getNewsEventList);
export const getNewsEventInfo = createReduxThunk(GET_NEWSEVENT_INFO, appAPI.getNewsEventInfo);
export const onEditNewsEvent = createReduxThunk(EDIT_NEWSEVENT, appAPI.onEditNewsEvent);
export const deleteNewsEvent = createReduxThunk(DELETE_NEWSEVENT, appAPI.onDeleteNewsEvent);

export const getLongTermCareList = createReduxThunk(GET_LONGTERMCARE_LIST, appAPI.getLongTermCareList);
export const getLongTermCareInfo = createReduxThunk(GET_LONGTERMCARE_INFO, appAPI.getLongTermCareInfo);
export const deleteLongTermCare = createReduxThunk(DELETE_LONGTERMCARE, appAPI.onDeleteLongTermCare);

// card
export const getCardList = createReduxThunk(GET_CARD_LIST, appAPI.getCardList);
export const getCardInfo = createReduxThunk(GET_CARD_INFO, appAPI.getCardInfo);
export const deleteCard = createReduxThunk(DELETE_CARD, appAPI.onDeleteCard);

// coupon
export const getCouponList = createReduxThunk(GET_COUPON_LIST, appAPI.getCouponList);
export const getUseCouponList = createReduxThunk(GET_USE_COUPON_LIST, appAPI.getUseCouponList);
export const getCouponInfo = createReduxThunk(GET_COUPON_INFO, appAPI.getCouponInfo);
export const onEditCoupon = createReduxThunk(EDIT_COUPON, appAPI.onEditCoupon);
export const onDeleteCoupon = createReduxThunk(DELETE_COUPON, appAPI.onDeleteCoupon);

export const modifyReserve = createReduxThunk(MODIFY_RESERVE, appAPI.modifyReserve);

// reducer
const appReducer = handleActions(
    {
        [EDIT_APPLY_PAGE]: (state, { payload }) =>
            produce(state, draft => {
                draft.applyPage = payload;
            }),

        [EDIT_REVIEW_PAGE]: (state, { payload }) =>
            produce(state, draft => {
                draft.reviewPage = payload;
            }),

        [LOADING_ON]: (state, action) =>
            produce(state, draft => {
                draft.loading = true;
            }),
        [LOADING_OFF]: (state, action) =>
            produce(state, draft => {
                draft.loading = false;
            }),
        [GET_USER_LIST_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft['user'] = payload;
            }),

        [GET_USER_COUNT_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft['userCount'] = payload;
            }),
        [GET_MATE_COUNT_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft['mateCount'] = payload;
            }),
        [GET_RESERVE_COUNT_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft['reserveCount'] = payload;
            }),
        [GET_USER_PAGING_LIST_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                if (payload && Array.isArray(payload) && payload.length > 0) {
                    draft['pagingUser']['list'] = draft['pagingUser']['list'].concat(payload);
                    draft['pagingUser']['page'] = draft['pagingUser']['page'] + 1;
                }
            }),
        [GET_MATE_LIST_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft['mate'] = payload;
            }),

        [GET_PATIENT_INFO_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft['patientInfo'] = payload;
            }),
        [GET_PATIENT_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                if (payload && payload.message === 'success') {
                    draft['patientList'] = payload.data;
                }
            }),

        [RESERVE_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                if (payload && payload.message === 'success') {
                    draft['reserveList'] = payload.data;
                }
            }),
        [RESREVE_INFO_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft['reserveInfo'] = payload;
            }),

        [ALLOW_MATE_SUCCESS]: (state, { payload }) => produce(state, draft => {}),

        [GET_USER_INFO_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft['userInfo'] = payload;
            }),
        [GET_MATE_INFO_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft['mateInfo'] = payload;
            }),

        [GET_PRODUCT_LIST_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft['product'] = payload;
            }),
        [GET_PRODUCT_INFO_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                if (payload && !_.isEmpty(payload)) {
                    draft['productInfo'] = payload[0];
                }
            }),
        [GET_PAYMENT_LIST_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft['payment'] = payload;
            }),
        [SERVICE_RESERVE_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                console.log(payload);
            }),
        [GET_RESREVE_LIST_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft['reserve'] = payload;
            }),
        [GET_RESREVE_INFO_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft['reserveInfo'] = payload;
            }),
        [RESERVE_PAID_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                if (payload && payload === 'success') {
                    window.location.reload();
                }
            }),
        [RESERVE_EXECUTE_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                if (payload && payload === 'success') {
                    window.location.reload();
                }
            }),
        [RESERVE_REWARD_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                if (payload && payload === 'success') {
                    window.location.reload();
                }
            }),
        [GET_APPLY_LIST_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                if (payload && payload.length > 0) {
                    draft['applyList'] = payload.map(p => ({
                        ...p,
                        form: JSON.parse(p.form),
                    }));
                }
            }),
        [GET_REVIEW_LIST_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                if (payload && payload.length > 0) {
                    draft['reviews'] = payload;
                }
            }),

        [GET_REVIEW_INFO_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                if (payload && payload.length > 0) {
                    draft['reviewInfo'] = payload;
                }
            }),
        [EDIT_USER_INFO]: (state, { payload }) =>
            produce(state, draft => {
                if (payload.type === 'clear') {
                    draft['userInfo'] = {};
                } else {
                    draft['userInfo'][payload.type] = payload.value;
                }
            }),
        [EDIT_PAGING_USER]: (state, { payload }) =>
            produce(state, draft => {
                draft['pagingUser'] = {
                    page: 0,
                    list: [],
                };
            }),
        [EDIT_NEW_RESERVE]: (state, { payload }) =>
            produce(state, draft => {
                if (payload.type === 'clear') {
                    draft['newReserve'] = {
                        service: 'unemergency',
                        sex: '남',
                        payment: 'card',
                    };
                } else {
                    draft['newReserve'][payload.type] = payload.value;
                }
            }),

        [DELETE_MATE_APPLY_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                if (payload && payload === 'success') {
                    // draft['patientList'] = '';
                    // window.location.href = '/user/mypage/profile';
                }
            }),

        [GET_AUTH_CODE_LIST_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft['codeList'] = payload;
            }),

        [GET_NEWSEVENT_LIST_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft['newsEvent'] = payload;
            }),

        [GET_NEWSEVENT_INFO_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                if (payload && !_.isEmpty(payload)) {
                    draft['newsEventInfo'] = payload[0];
                }
            }),

        [EDIT_NEWSEVENT_SUCCESS]: (state, { payload, meta }) =>
            produce(state, draft => {
                if (payload && payload === 'success') {
                    const list = draft['newsEvent'];
                    draft['newsEvent'] = list.map(info => {
                        if (info._id === meta.newsEventId) {
                            const { title, content, photo, url, url2, urlSource, urlSource2 } = meta.info;
                            info['title'] = title;
                            info['content'] = content;
                            info['photo'] = photo;
                            info['url'] = url;
                            info['url2'] = url2;
                            info['urlSource'] = urlSource;
                            info['urlSource2'] = urlSource2;

                            return info;
                        }
                    });
                }
            }),

        [DELETE_NEWSEVENT_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                if (payload && payload === 'success') {
                }
            }),

        [GET_LONGTERMCARE_LIST_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft['longTermCare'] = payload;
            }),

        [GET_LONGTERMCARE_INFO_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                if (payload && !_.isEmpty(payload)) {
                    draft['longTermCareInfo'] = payload[0];
                }
            }),

        [DELETE_LONGTERMCARE_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                if (payload && payload === 'success') {
                }
            }),

        [MODIFY_RESERVE_SUCCESS]: (state, { payload, meta }) =>
            produce(state, draft => {
                if (payload && payload === 'success') {
                    const list = draft['reserve'];
                    draft['reserve'] = list.map(info => {
                        if (info._id === meta.reserveId) {
                            const { service, productId, patient, guardCount, contact, reserveDate, meetDate } = meta.info;
                            info['service'] = service;
                            info['productId'] = productId;
                            info['patient'] = patient;
                            info['guardCount'] = guardCount;
                            info['contact'] = contact;
                            info['reserveDate'] = reserveDate;
                            info['meetDate'] = meetDate;

                            return info;
                        }
                    });
                }
            }),

        [GET_CARD_LIST_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft['cardList'] = payload;
            }),

        [GET_CARD_INFO_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                if (payload && !_.isEmpty(payload)) {
                    draft['cardInfo'] = payload[0];
                }
            }),

        [DELETE_CARD_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                if (payload && payload === 'success') {
                }
            }),

        // coupon
        [GET_COUPON_LIST_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft['coupon'] = payload;
            }),

        [GET_USE_COUPON_LIST_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft['useCoupon'] = payload;
            }),

        [GET_COUPON_INFO_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                if (payload && !_.isEmpty(payload)) {
                    draft['couponInfo'] = payload[0];
                }
            }),

        [EDIT_COUPON_SUCCESS]: (state, { payload, meta }) =>
            produce(state, draft => {
                if (payload && payload === 'success') {
                    const list = draft['coupon'];
                    draft['coupon'] = list.map(info => {
                        if (info._id === meta.couponId) {
                            const { couponName, appointedCode, couponInfo, couponValue, photo } = meta.info;
                            info['couponName'] = couponName;
                            info['appointedCode'] = appointedCode;
                            info['couponInfo'] = couponInfo;
                            info['couponValue'] = couponValue;
                            info['photo'] = photo;

                            return info;
                        }
                    });
                }
            }),

        [DELETE_COUPON_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                if (payload && payload === 'success') {
                }
            }),

        // mate apply new
        [GET_MATEAPPLY_LIST_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft['mateApplyList'] = payload;
            }),

        [GET_MATEAPPLY_INFO_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                if (payload && !_.isEmpty(payload)) {
                    draft['mateApplyInfo'] = payload[0];
                }
            }),

        [DELETE_MATEAPPLY_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                if (payload && payload === 'success') {
                }
            }),
    },
    initialState,
);

export default appReducer;
