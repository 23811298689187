import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import AOS from 'aos';
import jwt from 'jsonwebtoken';
import moment from 'moment';

// styles
import './Styles';
import 'aos/dist/aos.css';

// antd-styles > set in /config-overrides.js
import 'antd/dist/antd.css';

// components
import Home from './Pages/Home';
import Auth from './Pages/Auth';
import Register from './Pages/Auth/Register';

import User from './Pages/User';
import Service from './Pages/Service';
import Payment from './Pages/Payment';
import Reserve from './Pages/Reserve';
import Form from './Pages/Form';
import FormList from './Pages/FormList';
import FormInfo from './Pages/FormInfo';
import Product from './Pages/Product';
import Point from './Pages/Point';

import Email from './Pages/Email';
import NewsEvent from './Pages/NewsEvent';
import LongTermCare from './Pages/LongTermCare';
import Card from './Pages/Card';
import Coupon from './Pages/Coupon';
import MateApply from './Pages/MateApply';

import Event from './Pages/Event';

import VehicleShare from './Pages/VehicleShare';
import PriceMessage from './Pages/PriceMesage';
import EmergencyCorp from './Pages/EmergencyCorp';
import Contract from './Pages/ContractReserve';
import ETC from './Pages/ContractEtc';
import Messeger from './Pages/Messeger';

import Logout from './Pages/Logout';
import NotFound from './Pages/NotFound';

// templates
import Nav from './Components/Templates/Nav';

import { onEditAuthForm, checkAuth } from './Reducer/Modules/auth';

class App extends React.PureComponent {
    componentDidMount = () => {
        AOS.init({ disable: 'mobile' });

        const { history } = this.props;
        const id = localStorage.getItem('id');
        const email = localStorage.getItem('admin');
        const name = localStorage.getItem('name');
        const photo = localStorage.getItem('photo');

        if (!id) {
            this.props.editAuth({ type: 'id', text: '' });
            localStorage.removeItem('admin', 'name', 'id');
            history.push('/auth');
        } else {
            this.props.editAuth({ type: 'id', text: id });
            this.props.editAuth({ type: 'admin', text: email });
            this.props.editAuth({ type: 'name', text: name });
            this.props.editAuth({ type: 'photo', text: photo });
            this.props.checkAuth({ id });
        }
    };

    render() {
        const { auth } = this.props;
        // console.log(this.props);

        return (
            <div id='app-container' className='app-container'>
                <Switch>
                    <Route path='/auth' component={Auth} />
                    <Route path='/register' component={Register} />
                    {auth && auth.id ? (
                        <>
                            <Nav />
                            <div className='main-area'>
                                <Route path='/' exact component={Home} />
                                <Route path='/user' component={User} />
                                <Route path='/service' component={Service} />
                                <Route path='/contract' component={Contract} />
                                <Route path='/etc' component={ETC} />
                                <Route path='/reserve' component={Reserve} />
                                <Route path='/payment' component={Payment} />
                                <Route path='/form/handle' component={Form} />
                                <Route path='/apply/list' component={FormList} />
                                <Route path='/apply/info' component={FormInfo} />
                                <Route path='/messenger' component={Messeger} />
                                <Route path='/product' component={Product} />
                                <Route path='/point' component={Point} />

                                <Route path='/message' component={PriceMessage} />
                                <Route path='/share' component={VehicleShare} />
                                <Route path='/corp' component={EmergencyCorp} />

                                <Route path='/email/code' component={Email} />
                                <Route path='/newsEvent' component={NewsEvent} />
                                <Route path='/mateApply' component={MateApply} />

                                <Route path='/longTermCare' component={LongTermCare} />
                                <Route path='/card' component={Card} />
                                <Route path='/coupon' component={Coupon} />

                                <Route path='/event' component={Event} />

                                <Route exact path='/logout' component={Logout} />
                            </div>
                        </>
                    ) : null}
                    {/* {this.props.app.loading === false ? <Route component={NotFound} /> : null} */}
                </Switch>
            </div>
        );
    }
}

const mapStateToProps = ({ auth, app }) => ({
    auth,
    app,
});

const mapDispatchToProps = dispatch => ({
    editAuth: info => dispatch(onEditAuthForm(info)),
    checkAuth: ({ id }) => dispatch(checkAuth({ id })),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
