import React from 'react';
import { Table, Input } from 'antd';
import { user } from '../../../../Components/Columns/user';

import { BlockWrapper } from './normalList.styled';

class NormalList extends React.PureComponent {
    state = {
        keyword: '',
    };
    onRow = (row, index) => {
        return {
            onClick: () => {
                this.props.history.push({
                    pathname: `/user/normal/info/${row._id}`,
                    state: {
                        id: row._id,
                    },
                });
            },
        };
    };

    onChangePage = page => {
        // console.log(page);
        this.props.editPage(page);
    };

    getFilteredList = () => {
        const { user } = this.props.app;
        const { keyword } = this.state;

        if (!user) return [];

        return user.filter(m => {
            if (m.name && keyword && m.name.toLowerCase().includes(keyword.toLowerCase())) return true;
            if (m.contact && keyword && m.contact.toLowerCase().includes(keyword.toLowerCase())) return true;
            return false;
        });
    };

    render() {
        const { keyword } = this.state;
        const filteredList = !keyword ? this.props.app.user : this.getFilteredList();
        // console.log(filteredList);
        return (
            <div className='container'>
                <BlockWrapper>
                    <Input
                        placeholder='이름/연락처로 사용자를 검색해보세요.'
                        value={keyword}
                        onChange={ev => {
                            this.setState({ keyword: ev.target.value });
                        }}
                    />
                </BlockWrapper>

                <div className='table'>
                    <Table
                        rowKey='_id'
                        dataSource={filteredList}
                        columns={user}
                        onRow={this.onRow}
                        pagination={{
                            pageSize: 10,
                            current: this.props.auth.userPage,
                            onChange: this.onChangePage,
                        }}
                        scroll={{ x: 300 }}
                    />
                </div>
            </div>
        );
    }
}
export default NormalList;
