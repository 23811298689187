import { DEV } from '../variables';
import axios from '../axios';

export const getShareList = () => {
    return axios.get('/v1/share/list');
};

export const getMessageList = () => {
    return axios.get('/v1/message/list');
};

export const getCorpList = () => {
    return axios.get('/v1/corp/list');
};

export const addCorp = ({ info }) => {
    return axios.post('/v1/corp/add', { ...info });
};

export const contractReserveList = () => {
    return axios.get('/v1/partner/reserve/list');
};

export const etcReserveList = () => {
    return axios.get('/v1/partner/special/request');
};
