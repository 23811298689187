import React from 'react';
import ReactJson from 'react-json-view';
import { Button, notification, message } from 'antd';

class UserPage extends React.PureComponent {
    blockHandler = () => {
        const { id } = this.props.auth;
        const { userInfo } = this.props.app;
        const { blocked } = userInfo;

        this.props
            .blockUser({ id, targetId: userInfo._id, userType: 'user', status: !blocked.state })
            .then(resolve => {
                if (resolve === 'not exist') {
                    notification.open({
                        message: '유효하지 않은 사용자',
                        description: '탈퇴하였거나 존재하지 않는 사용자 입니다.',
                    });
                }
                if (resolve === 'success') {
                    message.success(`${!blocked.state === true ? '차단' : '차단해제'}를 완료하였습니다.`);
                    setTimeout(() => {
                        this.props.history.goBack();
                    }, 1000);
                }
            })
            .catch(error => {
                notification.open({
                    message: '사용자 차단 실패',
                    description: '서버상의 오류로 사용자 차단에 실패하였습니다.',
                });
            });
    };

    render() {
        const { userInfo } = this.props.app;

        return (
            <div style={{ padding: '20px' }}>
                <div className='container' style={{ backgroundColor: 'white' }}>
                    {userInfo.photo ? <img src={userInfo.photo} alt='profile' /> : null}
                    <ReactJson src={userInfo} />
                    <div className='button-wrapper'>
                        <Button type='primary' style={{ width: '100%' }} onClick={this.blockHandler}>
                            {userInfo.blocked && userInfo.blocked.state ? '차단해제' : '차단하기'}
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

export default UserPage;
