import React from 'react';
import { connect } from 'react-redux';
import StyledMessengerContainer from './messenger.styled';
import Messenger from './messenger';
import Title from '../../Components/Common/pageTitle';
import { getUserList, getMateList } from '../../Reducer/Modules/app';

class MessengerPage extends React.PureComponent {
    componentDidMount = () => {
        const { id } = this.props.auth;
        this.props.fetchList(id);
        this.props.fetchMateList(id);
    };

    render() {
        return (
            <StyledMessengerContainer>
                <Title title='메시지 서비스' />
                <Messenger {...this.props} />
            </StyledMessengerContainer>
        );
    }
}
const mapStateToProps = ({ app, auth }) => ({
    app,
    auth,
});

const mapDispatchToProps = dispatch => ({
    fetchList: ({ page }) => dispatch(getUserList({ page })),
    fetchMateList: id => dispatch(getMateList({ id })),
});

export default connect(mapStateToProps, mapDispatchToProps)(MessengerPage);
