import React from 'react';
import { Button, notification, Table } from 'antd';
import { newsEvent } from '../../../Components/Columns/service';

import { TableContainer, TableWrapper } from './list.styled';
import axios from 'axios';
import { onDeleteNewsEvent } from '../../../Lib/Api/app';

export default class NewsEventList extends React.PureComponent {
    onRow = (row, index) => {
        return {
            onClick: () => {
                this.props.history.push({
                    pathname: `/newsEvent/info/${row._id}`,
                    state: {
                        id: row._id,
                    },
                });
            },
        };
    };

    onNewsEventDelete = async ({ _id }) => {
        console.log('_id: ', _id);

        const confirmed = window.confirm('해당 게시물을 삭제하시겠습니까?');
        if (!confirmed) return null;

        onDeleteNewsEvent({ newsEventId: _id }).then(resolve => {
            if (resolve && resolve.data === 'success') {
                notification.open({
                    message: '게시물을 삭제하였습니다.',
                    description: '게시물을 삭제하였습니다.',
                    type: 'success',
                });
                window.location.reload();
            }
        });
    };

    render() {
        // console.log(this.props.app);
        return (
            <>
                <TableWrapper>
                    <TableContainer>
                        <Table
                            rowKey='id'
                            dataSource={this.props.app.newsEvent.data}
                            columns={newsEvent({ onDelete: this.onNewsEventDelete })}
                            onRow={this.onRow}
                            pagination={{
                                pageSize: 10,
                            }}
                        />

                        <Button
                            type='primary'
                            onClick={() => {
                                this.props.history.push('/newsEvent/create');
                            }}
                        >
                            새 게시물 등록
                        </Button>
                    </TableContainer>
                </TableWrapper>
            </>
        );
    }
}
