import React from 'react';
import { Button, Table } from 'antd';
import { InnerContainer } from './list.styled';

import { review } from '../../../../Components/Columns/service';

export default class ListPage extends React.PureComponent {
    //     onRow = row => {
    //         return {
    //             onClick: () => {
    //                 window.alert(`
    // ${row.selfmade ? '.' : ''}
    // [다음 진료에도 위드메이트를 사용하실건가요?]
    // > ${row.useAgain === true ? '네' : row.useAgain === false ? '아니오' : '응답 안함'}

    // [금일 방문하신 의료 기관의 서비스에 만족하시나요?]
    // > ${row.corpSatisfied === true ? '네' : row.corpSatisfied === false ? '아니오' : '응답 안함'}

    // [이유는 무엇인가요?]
    // > ${row.corpSatisfiedReason ? row.corpSatisfiedReason : '응답 안함'}

    // [금일 방문하신 의료 기관의 의료진은 누구이고, 서비스에 만족하시나요?]
    // > ${row.doctorSatisfied === true ? '네' : row.doctorSatisfied === false ? '아니오' : '응답 안함'}
    // > ${row.doctorSatisfiedReason ? row.doctorSatisfiedReason : '응답 안함'}

    // [병원 동행 서비스의 개선점이 있다면 무엇이라고 생각하시나요?]
    // > ${row.hope ? row.hope : '응답 안함'}
    //                 `);
    //             },
    //         };
    //     };

    onRow = (row, index) => {
        return {
            onClick: () => {
                this.props.history.push({
                    pathname: `/reserve/review/info/${row._id}`,
                    state: {
                        id: row._id,
                    },
                });
            },
        };
    };

    onChangePage = page => {
        // console.log(page);
        this.props.editPage(page);
    };

    render() {
        const { app } = this.props;

        return (
            <InnerContainer>
                <div className='table'>
                    <Table
                        rowKey={'_id'}
                        onRow={this.onRow}
                        dataSource={app.reviews}
                        columns={review}
                        pagination={{
                            pageSize: 10,
                            current: this.props.app.reviewPage,
                            onChange: this.onChangePage,
                        }}
                        scroll={{ x: 600 }}
                    />
                </div>
                <br />
                <Button
                    type='primary'
                    onClick={() => {
                        this.props.history.push('/reserve/review/create');
                    }}
                >
                    리뷰 체크
                </Button>
            </InnerContainer>
        );
    }
}
