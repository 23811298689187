import React from 'react';
import { connect } from 'react-redux';
import StyledContainer from './list.styled';
import List from './list';
import Title from '../../../Components/Common/pageTitle';
import { getMessageList } from '../../../Reducer/Modules/service';

class MessageListPage extends React.PureComponent {
    componentDidMount = () => {
        this.props.fetchList();
    };

    render() {
        return (
            <StyledContainer>
                <Title title='이용가격 안내 목록' />
                <List {...this.props} />
            </StyledContainer>
        );
    }
}
const mapStateToProps = ({ app, auth, service }) => ({
    app,
    auth,
    service,
});

const mapDispatchToProps = dispatch => ({
    fetchList: () => dispatch(getMessageList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MessageListPage);
