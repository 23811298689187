import React from 'react';
import { connect } from 'react-redux';

// component
import Nav from './nav';

// styles
import NavStyledContainer from './nav.styled';

// actions
// import {} from '../../Redux/Modules/';

class NavContainer extends React.PureComponent {
    render() {
        return (
            <NavStyledContainer>
                <Nav {...this.props} />
            </NavStyledContainer>
        );
    }
}

const mapStateToProps = ({}) => ({
    somethingState: '',
});

const mapStateToDispatch = dispatch => ({
    somethingAction: () => dispatch('actions'),
});

export default NavContainer;
// export default connect(mapStateToProps, mapStateToDispatch)(NavContainer);
