import React from 'react';
import { Input, Checkbox, Select, message, Button, Radio } from 'antd';
import Title from '../../../Components/Common/pageTitle';
import { TableWrapper, TableContainer, Label, ValueBox } from './info.styled';

import axios from '../../../Lib/axios';
import TextArea from 'antd/lib/input/TextArea';
import { onEditCoupon } from '../../../Lib/Api/app';

export default class CouponManage extends React.PureComponent {
    state = {
        data: {},
    };

    componentDidMount = () => {
        const { state } = this.props.history.location;
        if (!state || !state.id) return this.props.history.goBack();

        this.fetchInfo(state.id);
    };

    fetchInfo = async id => {
        const { data } = await axios.get('/v1/coupon/list/info', {
            params: {
                couponId: id,
            },
        });

        if (!data || data === 'check parameters') {
            return this.props.history.goBack();
        }

        if (data === 'invalid coupon') {
            return message.warning('잘못된 게시물 정보입니다.');
        }

        this.setState({ ...data });
    };

    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    onUpdate = async () => {
        const { couponName, appointedCode, couponInfo, couponValue, photo } = this.state;
        if (!couponName) return message.warning('쿠폰명을 입력해주세요.');
        if (!appointedCode) return message.warning('쿠폰 코드를 입력해주세요.');
        if (!couponInfo) return message.warning('쿠폰 설명을 입력해주세요.');
        if (!couponValue) return message.warning('쿠폰 포인트 값을 입력해주세요.');

        let img = photo;
        if (typeof photo !== 'string') {
            const formData = new FormData();
            formData.append('photo', photo);
            const header = { headers: { 'Content-Type': 'multipart/form-data' } };
            const { data: image } = await axios.post('/v1/coupon/image/upload', formData, header);
            img = image;
        }

        const info = { couponName, appointedCode, couponInfo, couponValue, photo: img }

        onEditCoupon({ couponId: this.state._id, info })
            .then(resolve => {
                if (resolve && resolve.data === 'success') {
                    message.success('쿠폰 업데이트 완료');
                    setTimeout(() => {
                        this.props.history.goBack();
                    }, 600);
                }
            })
            .catch(error => {
                message.error('서버장애가 발생하였습니다. 관리자에게 문의해주세요.');
            });
    };

    render() {
        return (
            <>
                <Title title='쿠폰 정보' />
                <TableWrapper>
                    <TableContainer>
                        {this.state && (
                            <>
                                <ValueBox>
                                    <Label style={{ fontSize: '14px' }}><b>쿠폰명</b></Label>
                                    <Input placeholder='쿠폰명을 입력해주세요.' value={this.state.couponName} name='couponName' onChange={this.handleChange} />
                                </ValueBox>

                                <ValueBox>
                                    <Label style={{ fontSize: '14px' }}><b>쿠폰 코드</b></Label>
                                    <Input placeholder='쿠폰 코드를 입력해주세요.' value={this.state.appointedCode} name='appointedCode' onChange={this.handleChange} />
                                </ValueBox>

                                <ValueBox>
                                    <Label style={{ fontSize: '14px' }}><b>설명</b></Label>
                                    <Input placeholder='쿠폰 설명을 입력해주세요.' value={this.state.couponInfo} name='couponInfo' onChange={this.handleChange} />
                                </ValueBox>

                                <ValueBox>
                                    <Label style={{ fontSize: '14px' }}><b>포인트</b></Label>
                                    <Input placeholder='쿠폰 포인트를 입력해주세요.' value={this.state.couponValue} name='couponValue' onChange={this.handleChange} />
                                </ValueBox>

                                <ValueBox>
                                    <Label style={{ fontSize: '14px' }}><b>쿠폰 이미지</b></Label>
                                    {this.state.photo && (
                                        <div>
                                            <img
                                                style={{ width: '200px', height: '200px', objectFit: 'contain' }}
                                                src={typeof this.state.photo === 'string' ? this.state.photo : URL.createObjectURL(this.state.photo)}
                                                alt='사진'
                                            />
                                        </div>
                                    )}
                                    <input
                                        type='file'
                                        accept='image/*'
                                        placeholder='쿠폰 이미지를 추가해주세요.'
                                        name='photo'
                                        onChange={ev => {
                                            console.log(ev.target.files);
                                            this.setState({ photo: ev.target.files && ev.target.files.length > 0 ? ev.target.files[0] : '' });
                                        }}
                                    />
                                </ValueBox>

                                <ValueBox>
                                    <Button type='primary' onClick={this.onUpdate}>
                                        현재 상태로 변경 및 저장
                                    </Button>
                                </ValueBox>
                            </>
                        )}
                    </TableContainer>
                </TableWrapper>
            </>
        );
    }
}
