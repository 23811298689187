import React from 'react';
import { connect } from 'react-redux';
import StyledInfoContainer from './info.styled';
import Info from './info';
import Title from '../../../Components/Common/pageTitle';
import {} from '../../../Reducer/Modules/service';

class InfoContainer extends React.PureComponent {
    componentDidMount = () => {};

    render() {
        return (
            <StyledInfoContainer>
                <Title title='해피케어 - 낙상방지 시설 설치 서비스 신청 상세내역' />
                <Info {...this.props} />
            </StyledInfoContainer>
        );
    }
}

const mapStateToProps = ({ app, auth, service }) => ({
    app,
    service,
    auth,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(InfoContainer);
