import React from 'react';
import { connect } from 'react-redux';
import StyledMateListContainer from './mateList.styled';
import MateList from './mateList';
import Title from '../../../../Components/Common/pageTitle';
import { getMateList } from '../../../../Reducer/Modules/app';
import { editMatePage } from '../../../../Reducer/Modules/auth';

class MateListPage extends React.PureComponent {
    componentDidMount = () => {
        const { id } = this.props.auth;
        console.log(id);
        this.props.fetchList(id);
    };

    render() {
        return (
            <StyledMateListContainer>
                <Title title='메이트 목록' />
                <MateList {...this.props} />
            </StyledMateListContainer>
        );
    }
}
const mapStateToProps = ({ app, auth }) => ({
    app,
    auth,
});

const mapDispatchToProps = dispatch => ({
    fetchList: id => dispatch(getMateList({ id })),
    editPage: page => dispatch(editMatePage(page)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MateListPage);
