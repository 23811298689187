import React from 'react';

export const InfoBlock = ({ label, value, children }) => (
    <div style={{ paddingBottom: '20px', marginBottom: '20px', borderBottom: '1px solid #e6e6e6' }}>
        <p style={{ margin: 0, padding: 0, marginBottom: '8px', color: 'rgba(0,0,0,0.4)', fontSize: '10px' }}>{label}</p>
        <p style={{ margin: 0, padding: 0, color: 'rgba(0,0,0,0.87)', fontSize: '14px' }}>{value}</p>
        {children}
    </div>
);

export const InfoBlock_ = ({ label, value, children }) => (
    <div style={{ paddingBottom: '10px', marginBottom: '10px' }}>
        <p style={{ margin: 0, padding: 0, marginBottom: '8px', color: 'rgba(0,0,0,0.4)', fontSize: '10px' }}>{label}</p>
        <p style={{ margin: 0, padding: 0, color: 'rgba(0,0,0,0.87)', fontSize: '14px' }}>{value}</p>
        {children}
    </div>
);