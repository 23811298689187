import React, { PureComponent } from 'react';
import { Switch, Route } from 'react-router-dom';

import Todo from './ReservePage/Todo';
import ReviewList from './Review/List';
import ReviewInfo from './Review/Info';
import ReviewCreate from './Review/Create';

class Reserve extends PureComponent {
    render() {
        const { url } = this.props.match;

        return (
            <Switch>
                <Route path={`${url}/todo`} component={Todo} />
                <Route path={`${url}/review/info`} component={ReviewInfo} />
                <Route path={`${url}/review/list`} component={ReviewList} />
                <Route path={`${url}/review/create`} component={ReviewCreate} />
            </Switch>
        );
    }
}
export default Reserve;
