import React from 'react';
import { connect } from 'react-redux';
import StyledNewsEventContainer from './create.styled';
import Create from './create';

class CreateContainer extends React.PureComponent {
    componentDidMount = () => {
        const { id } = this.props.auth;
        // this.props.fetchList(id);
    };

    render() {
        return (
            <StyledNewsEventContainer>
                <Create {...this.props} />
            </StyledNewsEventContainer>
        );
    }
}

const mapStateToProps = ({ app, auth }) => ({
    app,
    auth,
});

const mapDispatchToProps = dispatch => ({
    // fetchList: ({ page }) => dispatch(getUserList({ page })),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateContainer);
