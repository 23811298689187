import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

// reducers
import auth from './Modules/auth';
import app from './Modules/app';
import service from './Modules/service';
import event from './Modules/event';

const reducers = combineReducers({ app, auth, service, event });
const store = createStore(reducers, applyMiddleware(thunk));
export default store;
