import React from 'react';
import { Button, Checkbox, Input, Typography, notification } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

export default class AuthPage extends React.PureComponent {
    state = {
        showPassword: false,
        rememberLogin: false,
        email: '',
        password: '',
    };

    componentDidMount() {
        const rememberLogin = localStorage.getItem('rememberLogin');
        if (rememberLogin) {
            const auth = JSON.parse(localStorage.getItem('auth'));
            if (auth) {
                // auth 객체가 null이 아닌 경우에만 실행
                this.props.editForm({ type: 'auth', auth }); // Redux 스토어에 저장
                this.setState({
                    rememberLogin: true,
                    email: auth.email,
                    password: auth.password,
                });
            }
        }
    }

    toggleShowPassword = () => {
        this.setState({ showPassword: !this.state.showPassword });
    };

    toggleRememberLogin = () => {
        const { rememberLogin, email, password } = this.state;
        localStorage.setItem('rememberLogin', !rememberLogin);

        if (!rememberLogin) {
            localStorage.setItem('auth', JSON.stringify({ email, password }));
        } else {
            localStorage.removeItem('auth');
        }

        this.setState({ rememberLogin: !rememberLogin });
    };

    handleClick = e => {
        this.props.history.push('/register');
    };

    onChange = ({ text, type }) => {
        this.props.editForm({ type, text });
    };

    onLogin = () => {
        const { email, password } = this.props.auth;
        this.props
            .login({ email, password })
            .then(resolve => {
                if (resolve === 'not exist') {
                    return this.props.editForm({ type: 'alert', text: '존재하지않는 계정입니다.' });
                }
                if (resolve === 'wrong password') {
                    return this.props.editForm({ type: 'alert', text: '잘못된 비밀번호입니다.' });
                }
                if (this.state.rememberLogin) {
                    localStorage.setItem('auth', JSON.stringify({ email, password }));
                }
                notification.open({
                    message: '로그인 성공',
                    description: '위드메이트 어드민에 로그인하셨습니다.',
                    type: 'success',
                });
            })
            .catch(error => {
                console.log('Failed login: ', error);
                notification.open({
                    message: '로그인 실패',
                    description: '로그인에 실패하셨습니다. 로그인 정보를 다시한번 확인하세요.',
                    type: 'error',
                });
            });
    };

    render() {
        const { rememberLogin } = this.state;
        return (
            <div className='auth-container'>
                <div className='auth-innerContainer'>
                    <div className='auth-form'>
                        <Typography className='title'>
                            <img className='logo' src='https://s3.ap-northeast-2.amazonaws.com/withmate.photo/withmate_new_logo.png' alt='logo' />
                            <p className='login-title'>로그인</p>
                        </Typography>
                        <div className='form-box'>
                            <div className='form-row'>
                                <label>이메일</label>
                                <Input
                                    name='email'
                                    value={this.props.auth.email}
                                    autoComplete='on'
                                    autoSave='on'
                                    onChange={ev => {
                                        this.onChange({
                                            type: 'email',
                                            text: ev.target.value,
                                        });
                                    }}
                                />
                            </div>
                            <div className='form-row'>
                                <label>비밀번호</label>
                                <Input.Password
                                    type='password'
                                    name='password'
                                    value={this.props.auth.password}
                                    autoComplete='on'
                                    autoSave='on'
                                    iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                    onChange={ev => {
                                        this.onChange({
                                            type: 'password',
                                            text: ev.target.value,
                                        });
                                    }}
                                    onPressEnter={this.onLogin}
                                />
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div className='form-bottom'>
                                    <Checkbox checked={rememberLogin} onChange={this.toggleRememberLogin}>
                                        로그인 정보 기억
                                    </Checkbox>
                                </div>
                                <div className='form-bottom'>
                                    <a className='forgot-pw' onClick={this.handleClick} style={{ color: '#3bc1ff', fontSize: '15px' }}>
                                        관리자계정 가입
                                    </a>
                                </div>
                            </div>

                            {this.props.auth && this.props.auth.alert ? <p className='alert'>{this.props.auth.alert}</p> : null}
                            <div className='button-wrapper'>
                                <Button className='login-btn' onClick={this.onLogin}>
                                    로그인
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
