import styled from 'styled-components';

const HomeStyledContainer = styled.div`
    .container {
        position: fixed;
        left: 280px;

        .main {
            .home-title {
                font-size: 25px;
            }

            .home-message {
                font-size: 20px;
            }
        }
    }

    @media (max-width: 480px) {
        .container {
            position: fixed;
            left: 70px;
        }
    }
`;

export default HomeStyledContainer;

export const DashboardContainer = styled.div`
    display: flex;
    // flex-wrap: wrap;
    justify-content: center;
`;

export const DashboardTile = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 400px;
    margin: 10px;
    border: 2px solid black;
    border-radius: 5px;
    background-color: white;
    color: black;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;

    .dashboard-form {
        height: auto;

        .dashboard-title {
            text-align: center;
        }

        .dashboard-chart {
            padding-top: 20px;
            height: auto;
        }
    }

    @media (max-width: 832px) {
        .dashboard-form {
            width: auto;
        }
    }
`;
