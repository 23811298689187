import React from 'react';
import ReactJson from 'react-json-view';
import moment from 'moment';
import { Button, Modal, Input, message, Select } from 'antd';
import _ from 'lodash';
import axios from '../../Lib/axios';

import { MessageType, MessageForm } from './messenger.styled';
import { InfoBlock } from '../../Components/Common/block';
import { Label, ValueBox } from '../Point/Create/create.styled';

class Meesenger extends React.PureComponent {
    state = {
        contact: null,
        msg: '',
        defaultMessage: `위드메이트 서비스 알림\n`,
        contactList: [],
        mValue: '',
    };

    onSend = async () => {
        const { contact, msg, defaultMessage, contactList, buyerId, mateId } = this.state;
        // if (!contact || contact.length < 11) {
        //     return message.warning('휴대전화번호를 확인해주세요. (11자리 이상)');
        // }

        if (!msg || msg.length < 5) {
            return message.warning('메시지를 입력해주세요. (5자리 이상)');
        }

        // const fullMessage = defaultMessage + msg;
        await axios.post('/v1/common/send/sms', {
            message: msg,
            // contact,
            contactList,
            buyerId,
            mateId
        });

        this.setState(
            {
                msg: '',
                // contact: null,
                contactList: [],
                buyerId,
                mateId
            },
            () => {
                message.success('전송완료');
                window.location.reload();
            },
        );
    };

    getFilteredList = () => {
        const { user } = this.props.app;
        const { keyword } = this.state;

        if (!user) return [];

        return user.filter(m => {
            if (m.name && keyword && m.name.toLowerCase().includes(keyword.toLowerCase())) return true;
            if (m.contact && keyword && m.contact.toLowerCase().includes(keyword.toLowerCase())) return true;
            return false;
        });
    };

    getFilteredMateList = () => {
        const { mate } = this.props.app;
        const { search } = this.state;

        if (!mate) return [];

        return mate.filter(m => {
            if (m.name && search && m.name.toLowerCase().includes(search.toLowerCase())) return true;
            if (m.contact && search && m.contact.toLowerCase().includes(search.toLowerCase())) return true;
            return false;
        });
    };

    handleRemove = (index) => {
        const { contactList } = this.state;

        this.setState({
            contactList: [
                ...contactList.slice(0, index),
                ...contactList.slice(index + 1, contactList.length)
            ]
        });
    }
    render() {
        const { keyword = '', search = '', buyerName, mateName, index } = this.state;

        const filteredList = !keyword ? this.props.app.user : this.getFilteredList();
        const filteredMateList = !search ? this.props.app.mate : this.getFilteredMateList();

        // const contactList2 = this.state.contactList.map(
        //     (contact, index) => (<li key={index} onDoubleClick={() => this.handleRemove(index)}>{contact}</li>)
        // );

        return (
            <div className='container'>
                <MessageType>
                    <h3>문자 메시지 발송</h3>
                    <p>(서비스 종료된 고객에게 수동으로 입금 확인 알림 보낼 수 있는 기능 {'<<'} 등 자유롭게 사용하시면 됩니다)</p>
                    <br />

                    <ValueBox>
                        <Label>문자 메시지 수신대상</Label>
                        <Input
                            type='text'
                            placeholder='연락처를 -없이 입력해주세요'
                            value={this.state.contact || undefined}
                            onChange={ev => {
                                this.setState({ contact: ev.target.value });
                            }}
                            onPressEnter={() => {
                                if (!this.state.contact) return null;

                                this.setState({
                                    contactList: this.state.contactList.concat([this.state.contact]),
                                    contact: '',
                                });
                            }}
                        />
                        <Select
                            style={{ width: '100%' }}
                            value={this.state.contactList || undefined}
                            placeholder='문자 메시지 발송 대상을 "선택" 해주세요. (사용자)'
                            onChange={value => {
                                this.setState({ contactList: value });
                            }}
                            mode='multiple'
                        >
                            {filteredList &&
                                filteredList.length > 0 &&
                                filteredList.map((u, index) => (
                                    <Select.Option key={'user-row-' + index.toString()} value={u.contact}>
                                        {u.name} ({u.contact})
                                    </Select.Option>
                                ))}
                        </Select>
                    </ValueBox>
                    <br />

                    {/* <Input
                        type='text'
                        placeholder='연락처를 -없이 입력해주세요'
                        value={this.state.contact || undefined}
                        onChange={ev => {
                            this.setState({ contact: ev.target.value });
                        }}
                        onPressEnter={() => {
                            if (!this.state.contact) return null;

                            this.setState({
                                contactList: this.state.contactList.concat([this.state.contact]),
                                contact: '',
                            });
                        }}
                    />
                    <InfoBlock>
                        <p className='selected-user'>
                            &ensp;입력된 고객 {'>'} <span>{(contactList2 || '입력된 고객 없음')}</span>

                        </p>
                    </InfoBlock> */}

                    <Label>문자 메시지 입력</Label>
                    <Input.TextArea
                        style={{ height: '120px' }}
                        value={this.state.msg || undefined}
                        onChange={ev => {
                            this.setState({ msg: ev.target.value });
                        }}
                    />
                    <MessageForm>
                        <p>{this.state.defaultMessage}</p>
                        <div>
                            {this.state.msg &&
                                this.state.msg.split('\n').map((word, index) => (
                                    <span key={'msg-word-' + index.toString()}>
                                        {word}
                                        <br />
                                    </span>
                                ))}
                        </div>
                    </MessageForm>
                </MessageType>
                <Button type='primary' onClick={this.onSend}>
                    문자 메시지 전송하기
                </Button>
            </div>
        );
    }
}

const User = ({ name, phone, onClick, active }) => (
    <div className={`user-wrapper ${active ? 'active' : undefined}`} onClick={onClick}>
        <p>
            {name} ({phone})
        </p>
    </div>
);

const Mate = ({ name, phone, onClick, active }) => (
    <div className={`user-wrapper ${active ? 'active' : undefined}`} onClick={onClick}>
        <p>
            {name} ({phone})
        </p>
    </div>
);

export default Meesenger;
