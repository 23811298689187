import React from 'react';
import { Switch, Route } from 'react-router-dom';

// pages
import NotFound from '../NotFound';
import ServiceList from './ServicePages/List';
import ServiceItem from './ServicePages/Item';

class Service extends React.PureComponent {
    render() {
        return (
            <Switch>
                <Route exact path='/service/list' component={ServiceList} />
                <Route exact path='/service/info/:id' component={ServiceItem} />
                <Route component={NotFound} />
            </Switch>
        );
    }
}
export default Service;
