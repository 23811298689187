import { createAction, handleActions } from 'redux-actions';
import produce from 'immer';
import * as serviceAPI from '../../Lib/Api/service';
import _ from 'lodash';
// middleware
import { createReduxThunk } from '../../Lib/middleware';

const LOADING_ON = 'app/LOADING_ON';
const LOADING_OFF = 'app/LOADING_OFF';

const GET_SHARE_REQUEST_LIST = 'app/GET_SHARE_REQUEST_LIST';
const GET_SHARE_REQUEST_LIST_SUCCESS = 'app/GET_SHARE_REQUEST_LIST_SUCCESS';

const GET_CORP_LIST = 'app/GET_CORP_LIST';
const GET_CORP_LIST_SUCCESS = 'app/GET_CORP_LIST_SUCCESS';

const GET_MESSAGE_LIST = 'app/GET_MESSAGE_LIST';
const GET_MESSAGE_LIST_SUCCESS = 'app/GET_MESSAGE_LIST_SUCCESS';

const ADD_CORP = 'app/ADD_CORP';
const ADD_CORP_SUCCESS = 'app/ADD_CORP_SUCCESS';

const GET_RESERVE_LIST = 'service/GET_RESERVE_LIST';
const GET_RESERVE_LIST_SUCCESS = 'service/GET_RESERVE_LIST_SUCCESS';

const EDIT_SERVICE_PAGE = 'service/EDIT_SERVICE_PAGE';

const GET_SPECIAL_REQUEST_LIST = 'service/GET_SPECIAL_REQUEST_LIST';
const GET_SPECIAL_REQUEST_LIST_SUCCESS = 'service/GET_SPECIAL_REQUEST_LIST_SUCCESS';

// initalState
const initialState = {
    shareList: [],
    messageList: [],
    corpList: [],
    loading: false,
    reserveList: [],
    speicalRequests: [],
    servicePage: 1
};

export const getShareList = createReduxThunk(GET_SHARE_REQUEST_LIST, serviceAPI.getShareList);
export const getCorpList = createReduxThunk(GET_CORP_LIST, serviceAPI.getCorpList);
export const getMessageList = createReduxThunk(GET_MESSAGE_LIST, serviceAPI.getMessageList);
export const getReserveList = createReduxThunk(GET_RESERVE_LIST, serviceAPI.contractReserveList);
export const getSpecialRequestList = createReduxThunk(GET_SPECIAL_REQUEST_LIST, serviceAPI.etcReserveList);

export const editServicePage = createAction(EDIT_SERVICE_PAGE, input => input);

export const addCorp = createReduxThunk(ADD_CORP, serviceAPI.addCorp);

// reducer
const appReducer = handleActions(
    {
        [EDIT_SERVICE_PAGE]: (state, { payload }) =>
            produce(state, draft => {
                draft.servicePage = payload;
            }),

        [LOADING_ON]: (state, action) =>
            produce(state, draft => {
                draft.loading = true;
            }),
        [LOADING_OFF]: (state, action) =>
            produce(state, draft => {
                draft.loading = false;
            }),
        [GET_SHARE_REQUEST_LIST_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft['shareList'] = payload;
            }),

        [GET_CORP_LIST_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft['corpList'] = payload;
            }),
        [GET_RESERVE_LIST_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft['reserveList'] = payload;
            }),
        [GET_SPECIAL_REQUEST_LIST_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft['speicalRequests'] = payload;
            }),

        [GET_MESSAGE_LIST_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                draft['messageList'] = payload;
            }),
        [ADD_CORP_SUCCESS]: (state, { payload }) =>
            produce(state, draft => {
                console.log(payload);
            }),
    },
    initialState,
);

export default appReducer;
