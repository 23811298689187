import styled from 'styled-components';

const ProfileStyledContainer = styled.div`

    @media (max-width: 480px) {
        min-height: initial;
        height: 100%;
        padding-bottom: 0px;
        padding-top: 5px;
    }

    .inner-container {
        padding-bottom: 0px;
        padding-top: 0px;

        @media (max-width: 480px) {
            padding: 0px;
        }

        .profile-form {

            .title-wrapper {
                display: flex;
                justify-content: flex-start;
                margin-bottom: 16px;

                p {
                    flex: 1;
                    font-size: 32px;
                    font-weight: bold;
                    letter-spacing: -0.7px;
                    line-height: 48px;
                    color: rgba(0, 0, 0, 0.7);
                    margin: 0px;
                    padding: 0px;
                }

                span {
                    font-size: 14px;
                    font-weight: bold;
                    text-align: right;
                }
            }

            .form {
                label {
                    font-size: 12px;
                    letter-spacing: -0.15px;
                    line-height: 20px;
                    color: rgba(0, 0, 0, 0.7);
                    margin-bottom: 4px;
                }

                input {
                    height: 44px;
                    border: solid 1px #e6e6e6;
                    padding: 10px;

                    &.ant-input {
                        font-size: 16px;
                        line-height: 28px;
                        letter-spacing: -0.2px;
                        color: rgba(0, 0, 0, 0.7);

                        &::placeholder {
                            font-size: 14px;
                        }
                    }
                }

                .type-button-wrapper {
                    width: 100%;
                    height: 48px;

                    background-color: white;
                    border: 1px solid #e6e6e6;
                    border-radius: 4px;

                    display: flex;
                    align-items: center;

                    > .type-button {
                        width: 50%;
                        height: 100%;
                        line-height: 48px;

                        text-align: center;
                        cursor: pointer;

                        font-size: 16px;
                        letter-spacing: -0.2px;
                        color: rgba(0, 0, 0, 0.2);
                    }

                    > .type-button.active {
                        background: rgba(0, 0, 0, 0.7);
                        border-radius: 4px;
                        color: #f9f9f9;
                    }
                }

                button {
                    width: 100%;
                    height: 46px;
                    margin-top: 4px;
                    margin-bottom: 16px;

                    font-size: 14px;
                    font-weight: bold;
                    letter-spacing: -0.2px;
                    text-align: center;
                    color: #ffffff;
                }

                .link-wrapper {
                    display: flex;
                    justify-content: center;
                    margin-bottom: 8px;
                    .text {
                        font-size: 14px;
                        letter-spacing: -0.3px;
                        text-align: center;
                        color: rgba(0, 0, 0, 0.7);
                        line-height: 21px;
                    }

                    .link {
                        cursor: pointer;
                        font-size: 14px;
                        font-weight: bold;
                        letter-spacing: -0.3px;
                        text-align: center;

                        margin-left: 8px;
                    }
                }
            }

            .form-user-type {
                height: 36px;
                margin-bottom: 20px;
                border-radius: 4px;
                border: 1px solid #e6e6e6;
                display: flex;
                align-items: center;
                overflow: hidden;

                .type {
                    flex: 1;
                    height: 100%;
                    line-height: 36px;
                    background-color: #ffffff;
                    cursor: pointer;

                    &:first-child {
                        border-right: 1px solid #e6e6e6;
                    }

                    p {
                        font-size: 11px;
                        font-weight: bold;
                        text-align: center;
                        color: #606060;
                    }

                    &.active {
                        background-color: #606060;

                        p {
                            color: #ffffff;
                        }
                    }
                }
            }
        }
    }

    .ant-upload-drag-container {
        .title {
            margin-top: 12px;
            font-size: 14px;
            letter-spacing: -0.3px;
            text-align: center;
            color: rgba(0, 0, 0, 0.8);
        }

        .reco {
            font-size: 12px;
            letter-spacing: -0.3px;
            text-align: center;
            color: #a7a7a7;
        }
    }
`;

export default ProfileStyledContainer;