import React from 'react';
import { connect } from 'react-redux';

import StyledPaymentListContainer from './list.styled';
import ReviewLIst from './list';
import Title from '../../../../Components/Common/pageTitle';

// actions
import { editReviewPage, getReviewList } from '../../../../Reducer/Modules/app';

class ReviewListPage extends React.PureComponent {
    componentDidMount = () => {
        this.props.fetchList();
    };

    render() {
        return (
            <StyledPaymentListContainer>
                <Title title='리뷰 목록' />
                <ReviewLIst {...this.props} />
            </StyledPaymentListContainer>
        );
    }
}
const mapStateToProps = ({ app, auth }) => ({
    app,
    auth,
});

const mapDispatchToProps = dispatch => ({
    fetchList: () => dispatch(getReviewList()),
    editPage: (page) => dispatch(editReviewPage(page))
});

export default connect(mapStateToProps, mapDispatchToProps)(ReviewListPage);
