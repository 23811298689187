import styled from 'styled-components';
const StyledNormalListContainer = styled.div``;

export const BlockWrapper = styled.div`
    width: 100%;
    padding: 24px;
    background-color: white;
`;

export default StyledNormalListContainer;
