import React from 'react';
import { Button, Table } from 'antd';

import Title from '../../../Components/Common/pageTitle';
import { TableContainer, TableWrapper } from './list.styled';

import axios from '../../../Lib/axios';
import { point } from '../../../Components/Columns/service';

export default class PointList extends React.PureComponent {
    state = {
        list: [],
    };

    componentDidMount = () => {
        this.fetchList();
    };

    fetchList = async () => {
        const { data } = await axios.get('/v2/user/users/point/history');
        if (data && Array.isArray(data)) {
            this.setState({ list: data });
        }
    };

    onItem = row => {
        return {
            onClick: () => {
                this.props.history.push({
                    pathname: '/point/info',
                    state: {
                        id: row.user[0]._id,
                    },
                });
            },
        };
    };

    render() {
        console.log(this.state);
        return (
            <>
                <TableWrapper>
                    <TableContainer>
                        <Table 
                            rowKey='id' 
                            dataSource={this.state.list || []} 
                            columns={point} 
                            onRow={this.onItem} 
                        />
                        <br />
                        <div style={{ display: 'flex', justifyContent: 'right' }}>
                            <Button
                                type='primary'
                                onClick={() => {
                                    this.props.history.push('/point/create');
                                }}
                                style={{ marginRight: '20px', backgroundColor: '#3bc1ff', border: '0px' }}
                            >
                                포인트 지급하기
                            </Button>
                            <Button
                                type='primary'
                                onClick={() => {
                                    this.props.history.push('/point/subtract');
                                }}
                                style={{ backgroundColor: '#3bc1ff', border: '0px' }}
                            >
                                포인트 차감하기
                            </Button>
                        </div>
                    </TableContainer>
                </TableWrapper>
            </>
        );
    }
}
