import React from 'react';
import { connect } from 'react-redux';
import StyledNormalListContainer from './normalList.styled';
import NormalList from './normalList';
import Title from '../../../../Components/Common/pageTitle';
import { getUserList } from '../../../../Reducer/Modules/app';
import { editUserPage } from '../../../../Reducer/Modules/auth';

class NormalListPage extends React.PureComponent {
    componentDidMount = () => {
        const { id } = this.props.auth;
        this.props.fetchList(id);
    };

    render() {
        return (
            <StyledNormalListContainer>
                <Title title='사용자 목록' />
                <NormalList {...this.props} />
            </StyledNormalListContainer>
        );
    }
}
const mapStateToProps = ({ app, auth }) => ({
    app,
    auth,
});
const mapDispatchToProps = dispatch => ({
    fetchList: id => dispatch(getUserList({ id })),
    editPage: page => dispatch(editUserPage(page)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NormalListPage);
