import React from 'react';
import { Switch, Route } from 'react-router-dom';

// pages
import List from './List';

class UserPages extends React.PureComponent {
    render() {
        const { url } = this.props.match;
        return (
            <Switch>
                <Route exact path={`${url}/list`} component={List} />
            </Switch>
        );
    }
}
export default UserPages;
 