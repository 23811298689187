import React from 'react';
import { BarChart, Bar, XAxis, Tooltip } from 'recharts';
import { COMPANY } from '../../Lib/variables';
import { DashboardContainer, DashboardTile } from './home.styled';

export default class HomePage extends React.PureComponent {
    render() {
        const data = [
            { name: '서비스', count: this.props.app.reserveCount.count },
            { name: '사용자', count: this.props.app.userCount.count },
            { name: '메이트', count: this.props.app.mateCount.count },
            { name: '메이트 신청', count: this.props.app.mateApplyList.count },
            { name: 'NewsEvent', count: this.props.app.newsEvent.count },
        ];

        return (
            <div className='container'>
                <div className='main'>
                    <p className='home-title'>
                        <b>{COMPANY}</b> 관리자페이지
                    </p>
                    <p className='home-message'>
                        <b>{this.props.auth.name}</b>님 환영합니다.
                    </p>

                    <div style={{ height: '100px' }} />
                    <DashboardContainer>
                        <DashboardTile>
                            <div className='dashboard-form'>
                                <div className='dashboard-title'>
                                    <span>위드메이트 대시보드</span>
                                </div>
                                <div className='dashboard-chart'>
                                    {data.every(item => item.count !== undefined) && (
                                        <BarChart className='barChart' width={600} height={300} data={data}>
                                            <Tooltip wrapperStyle={{ width: 130, backgroundColor: '#ccc' }} />

                                            <Bar className='bar' dataKey='count' fill='#8884d8' label={{ position: 'inside' }}>
                                                {/* <LabelList dataKey='count' position='inside' /> */}
                                            </Bar>
                                            <XAxis dataKey='name' tick={{ fontSize: 16 }} />
                                        </BarChart>
                                    )}
                                </div>
                            </div>
                        </DashboardTile>
                    </DashboardContainer>
                </div>
            </div>
        );
    }
}
