import React from 'react';
import { Input, Button, message, Checkbox } from 'antd';

import axios from '../../Lib/axios';
import Title from '../../Components/Common/pageTitle';
import { InfoContainer, Wrapper, FlexWrapper, FlexBox } from './info.styled';

class ApplyInfo extends React.PureComponent {
    state = {
        allow: {
            name: '',
            contact: '',
            email: '',
            description: '',
            photo: '',
            sex: '남',
        },
        deny: {
            name: '',
            contact: '',
        },
    };

    allowHandler = ({ type, value }) => {
        this.setState({
            allow: {
                ...this.state.allow,
                [type]: value,
            },
        });
    };

    denyHandler = ({ type, value }) => {
        this.setState({
            deny: {
                ...this.state.deny,
                [type]: value,
            },
        });
    };

    onAllow = async () => {
        const { info } = this.props.location.state;
        const { name, contact, email, description, photo, sex } = this.state.allow;
        const { data: result } = await axios.post('/v1/admin/mate/allow/register', {
            name,
            contact,
            email,
            description,
            photo,
            applyId: info._id,
            sex,
        });

        if (result === 'duplicate') {
            return message.info('이미 존재하는 계정이 있습니다.');
        }

        if (result === 'check parameters') {
            return message.info('입력값을 확인해주세요.');
        }

        await axios.put('/v1/admin/mate/apply/check', {
            applyId: info._id,
        });

        message.success('승인완료');
        setTimeout(() => {
            this.props.history.push('/apply/list');
        }, 1000);
    };

    onDeny = async () => {
        const { info } = this.props.location.state;
        const { name, contact } = this.state.deny;
        const { data: result } = await axios.post('/v1/admin/mate/deny/register', {
            name,
            contact,
        });

        await axios.put('/v1/admin/mate/apply/check', {
            applyId: info._id,
        });

        message.success('거절완료');
        setTimeout(() => {
            this.props.history.goBack();
        }, 1000);
    };

    render() {
        const { info } = this.props.location.state;
        const form = typeof info.form === 'string' ? JSON.parse(info.form) : info.form;
        console.log(info);

        return (
            <InfoContainer>
                <Title title='지원자 정보' />
                <Wrapper>
                    <div>
                        <p>* 치매관련 간병 경력</p>
                        <p> 답변) {form.careCareer}</p>
                    </div>
                    {form.info.map((item, index) => (
                        <div key={'qna-item' + index.toString()}>
                            <p>
                                {index + 1}. {item.question}
                            </p>
                            <p> 답변) {item.value}</p>
                        </div>
                    ))}
                    <div>
                        {form.photoFst ? <img src={form.photoFst} /> : null}
                        {form.photoFst ? <span>{form.photoFst}</span> : null}
                    </div>
                    <div>
                        {form.photoSnd ? <img src={form.photoSnd} /> : null}
                        {form.photoSnd ? <span>{form.photoSnd}</span> : null}
                    </div>
                    <div>{form.fileFst ? <a href={form.fileFst}>파일 다운로드 - 1</a> : null}</div>
                    <div>{form.filedSnd ? <a href={form.filedSnd}>파일 다운로드 - 2</a> : null}</div>
                </Wrapper>

                <FlexWrapper>
                    <FlexBox>
                        <h4>메이트 가입시키기</h4>
                        <Input
                            type='text'
                            placeholder='지원자의 이름을 입력해주세요.'
                            value={this.state.allow.name}
                            onChange={ev => {
                                this.allowHandler({ type: 'name', value: ev.target.value });
                            }}
                        />
                        <Input
                            type='text'
                            placeholder='지원자의 연락처를 입력해주세요.'
                            value={this.state.allow.contact}
                            onChange={ev => {
                                this.allowHandler({ type: 'contact', value: ev.target.value.trim() });
                            }}
                        />
                        <Input
                            type='text'
                            placeholder='지원자의 이메일을 입력해주세요.'
                            value={this.state.allow.email}
                            onChange={ev => {
                                this.allowHandler({ type: 'email', value: ev.target.value.trim() });
                            }}
                        />
                        <Input
                            type='text'
                            placeholder='지원자의 (프로필)사진의 URL을 입력해주세요.'
                            value={this.state.allow.photo}
                            onChange={ev => {
                                this.allowHandler({ type: 'photo', value: ev.target.value });
                            }}
                        />
                        <Input
                            type='text'
                            placeholder='지원자의 소개글이 있으면 입력해주세요.'
                            value={this.state.allow.description}
                            onChange={ev => {
                                this.allowHandler({ type: 'description', value: ev.target.value });
                            }}
                        />
                        <div style={{ marginTop: '2px', marginBottom: '2px' }}>
                            <Checkbox
                                value='남'
                                checked={this.state.allow.sex === '남'}
                                onClick={ev => {
                                    this.allowHandler({ type: 'sex', value: '남' });
                                }}
                            >
                                남
                            </Checkbox>
                            <Checkbox
                                value='녀'
                                checked={this.state.allow.sex === '녀'}
                                onClick={ev => {
                                    this.allowHandler({ type: 'sex', value: '녀' });
                                }}
                            >
                                녀
                            </Checkbox>
                        </div>

                        <Button type='primary' onClick={this.onAllow}>
                            가입시키기
                        </Button>
                    </FlexBox>
                    <FlexBox>
                        <h4>메이트 거절하기</h4>
                        <Input
                            type='text'
                            placeholder='지원자의 이름을 입력해주세요.'
                            value={this.state.deny.name}
                            onChange={ev => {
                                this.denyHandler({ type: 'name', value: ev.target.value });
                            }}
                        />
                        <Input
                            type='text'
                            placeholder='지원자의 연락처를 입력해주세요.'
                            value={this.state.deny.contact}
                            onChange={ev => {
                                this.denyHandler({ type: 'contact', value: ev.target.value });
                            }}
                        />
                        <Button type='primary' onClick={this.onDeny}>
                            거절하기
                        </Button>
                    </FlexBox>
                </FlexWrapper>
            </InfoContainer>
        );
    }
}

export default ApplyInfo;
