import styled from 'styled-components';
const StyledReviewCreateContainer = styled.div``;

export const UserItem = styled.div`
    width: 100%;
    height: 30px;
    line-height: 30px;
    font-size: 13px;
    color: rgba(0, 0, 0, 0.7);
    cursor: pointer;
    border-bottom: 1px solid #e9e9e9;

    ${props => props.active && `background-color: #red;`}
`;

export const UserContainer = styled.div`
    width: 100%;
    max-height: 200px;
    overflow-y: scroll;

    padding: 16px;
    background-color: #ffffff;
`;

export default StyledReviewCreateContainer;
