import React from 'react';
import { connect } from 'react-redux';
import StyledReviewInfoContainer from './info.styled';
import ReviewInfo from './info';
import { getReviewInfo } from '../../../../Reducer/Modules/app';

class ReviewInfoPage extends React.PureComponent {
    componentDidMount = () => {
        const { id } = this.props.auth;
        const { state } = this.props.location;
        const { id: reviewId } = this.props.match.params;

        // console.log(reviewId, state);

        if (state === 'review') this.props.fetchReviewInfo({ reviewId });
    };

    render() {
        return (
            <StyledReviewInfoContainer>
                <ReviewInfo {...this.props} />
            </StyledReviewInfoContainer>
        );
    }
}
const mapStateToProps = ({ app, auth }) => ({
    app,
    auth,
});

const mapDispatchToProps = dispatch => ({
    fetchReviewInfo: ({ reviewId }) => dispatch(getReviewInfo({ reviewId })),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReviewInfoPage);
