import React from 'react';
import { Button, Input, notification, Table } from 'antd';
import { mateApply } from '../../../Components/Columns/user';

import { TableContainer, TableWrapper } from './list.styled';
import { onDeleteMateApply_v2 } from '../../../Lib/Api/app';

import { UserOutlined } from '@ant-design/icons';

export default class MateApplyList extends React.PureComponent {
    state = {
        keyword: '',
    };

    onRow = (row, index) => {
        return {
            onClick: () => {
                this.props.history.push({
                    pathname: `/mateApply/info/${row._id}`,
                    state: {
                        id: row._id,
                    },
                });
            },
        };
    };

    onChangePage = page => {
        // console.log(page);
        this.props.editPage(page);
    };

    onMateApplyDelete = async ({ _id }) => {
        console.log('_id: ', _id);

        const confirmed = window.confirm('해당 메이트 지원서를 삭제하시겠습니까?');
        if (!confirmed) return null;

        onDeleteMateApply_v2({ mateApplyId: _id }).then(resolve => {
            if (resolve && resolve.data === 'success') {
                notification.open({
                    message: '메이트 지원서를 삭제하였습니다.',
                    description: '메이트 지원서를 삭제하였습니다.',
                    type: 'success',
                });
                window.location.reload();
            }
        });
    };

    getList = () => {
        // const { mateApplyList } = this.props.app;
        // console.log(mateApplyList);
        const applyList = this.props.app.mateApplyList.data;
        // console.log(applyList);
        const { keyword } = this.state;

        let list = [];

        if (Array.isArray(applyList)) {
            list = applyList.filter(
                item =>
                    (item && item.name && item.name.toLowerCase().includes(keyword)) ||
                    (item && item.contact && item.contact.toLowerCase().includes(keyword)) ||
                    (item && item.email && item.email.toLowerCase().includes(keyword)) ||
                    (item && item.connection && item.connection.toLowerCase().includes(keyword)),
            );
        }

        return list;
    };

    handleTabClick = key => {
        this.setState({
            showReservationSummary: false,
            reservationSummary: {},
        });
    };

    render() {
        // console.log(this.props.app);
        const list = this.getList();
        return (
            <>
                <TableWrapper>
                    <TableContainer>
                        <div className='table'>
                            <Input
                                id='search-input'
                                value={this.state.keyword}
                                onChange={ev => {
                                    const el = document.getElementById('search-input');
                                    const event = new KeyboardEvent('keypress', { isTrusted: true, key: 'Enter', code: 'Enter', keyCode: 13 });

                                    const res = el.dispatchEvent(event);

                                    this.setState({ keyword: ev.target.value });
                                }}
                                placeholder='이름, 연락처, 이메일, 가입경로를 검색하세요.'
                                style={{ marginBottom: '24px' }}
                            />

                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <h3>
                                    <b>메이트 신청서 목록</b>
                                </h3>

                                {this.state.showReservationSummary && (
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                            float: 'inline-end',
                                            width: 'fit-content',
                                            border: '1px solid #9fd800',
                                        }}
                                    >
                                        <div style={{ padding: '5px 10px' }}>
                                            <p style={{ fontWeight: 'bold' }}>서비스예약 요약정보</p>
                                            <div style={{ display: 'flex' }}>
                                                <p style={{ paddingRight: '10px' }}>✅이름: {this.state.reservationSummary.name}</p>
                                                <p style={{ paddingRight: '10px' }}>📨이메일: {this.state.reservationSummary.email}</p>
                                                <p>📞연락처: {this.state.reservationSummary.contact}</p>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>

                            <Table
                                rowKey='id'
                                dataSource={list}
                                columns={[
                                    {
                                        title: '정보확인',
                                        dataIndex: 'valid',
                                        align: 'center',
                                        width: '58px',
                                        render: (text, record) => (
                                            <Button
                                                style={{ border: 'none' }}
                                                onClick={e => {
                                                    e.stopPropagation();

                                                    this.setState({
                                                        showReservationSummary: true,
                                                        reservationSummary: {
                                                            name: record.name,
                                                            email: record.email,
                                                            contact: record.contact,
                                                        },
                                                    });
                                                }}
                                            >
                                                <UserOutlined />
                                            </Button>
                                        ),
                                    },
                                    ...mateApply({ onDelete: this.onMateApplyDelete }),
                                ]}
                                onRow={this.onRow}
                                pagination={{
                                    pageSize: 10,
                                    current: this.props.service.servicePage,
                                    onChange: this.onChangePage,
                                }}
                            />
                        </div>
                    </TableContainer>
                </TableWrapper>
            </>
        );
    }
}
