import React from 'react';
import { Table, Input } from 'antd';
import { reserve as reserveColumn } from '../../../Components/Columns/service';

class ServiceList extends React.PureComponent {
    state = {
        keyword: '',
    };

    onRowReserve = (row, index) => {
        return {
            onClick: () => {
                this.props.history.push({
                    pathname: `/contract/service/info`,
                    state: {
                        info: row,
                    },
                });
            },
        };
    };

    getList = () => {
        const { reserveList } = this.props.service;
        const { keyword } = this.state;

        const list = reserveList.filter(
            item =>
                (item && item.reserveCode && item.reserveCode.toLowerCase().includes(keyword)) ||
                (item && item.patient && item.patient.toLowerCase().includes(keyword)) ||
                (item && item.user && item.user.toLowerCase().includes(keyword)),
        );

        return list;
    };

    render() {
        const list = this.getList();

        return (
            <div className='container'>
                <Input
                    value={this.state.keyword}
                    onChange={ev => {
                        this.setState({ keyword: ev.target.value });
                    }}
                    placeholder='예약번호, 신청인, 대상으로 검색하세요.'
                    style={{ marginBottom: '24px' }}
                />

                <div className='table'>
                    <Table rowKey='_id' dataSource={list} columns={reserveColumn} onRow={this.onRowReserve} />
                </div>
            </div>
        );
    }
}

export default ServiceList;
