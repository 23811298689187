import React from 'react';
import { Table, Input } from 'antd';

import { Container, TableContainer } from './list.styled';
import { SharedColumn } from '../../../Components/Columns/service';

class List extends React.PureComponent {
    render() {
        const { service } = this.props;
        console.log(service);
        return (
            <Container>
                <TableContainer>
                    <Table dataSource={service.shareList} columns={SharedColumn} rowKey='_id' />
                </TableContainer>
            </Container>
        );
    }
}
export default List;
