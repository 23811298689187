import React from 'react';
import { Input, Checkbox, Select, message, Button, Radio } from 'antd';
import Title from '../../../Components/Common/pageTitle';
import { TableWrapper, TableContainer, Label, ValueBox } from './info.styled';

import axios from '../../../Lib/axios';

export default class ProductManage extends React.PureComponent {
    state = {
        data: {},
    };

    componentDidMount = () => {
        const { state } = this.props.history.location;
        if (!state || !state.id) return this.props.history.goBack();

        this.fetchInfo(state.id);
    };

    fetchInfo = async id => {
        const { data } = await axios.get('/v1/product/service/info', {
            params: {
                productId: id,
            },
        });

        if (!data || data === 'check parameters') {
            return this.props.history.goBack();
        }

        if (data === 'invalid product') {
            return message.warning('잘못된 상품정보입니다.');
        }

        this.setState({ ...data, type: data.reservedDestination ? 'destination' : data.reservedStarting ? 'starting' : 'normal' });
    };

    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    onUpdate = async () => {
        const { name, description, logo, basicPrice, useBasic, proPrice, usePro, listOrder, type } = this.state;
        if (!name) return message.warning('상품명을 입력해주세요.');
        if (!description) return message.warning('설명을 입력해주세요.');
        if (useBasic && type !== 'starting') {
            if (!basicPrice) return message.warning('Basic 가격을 입력해주세요.');
        }
        if (usePro) {
            if (!proPrice) return message.warning('Pro 가격을 입력해주세요.');
        }
        if (!listOrder) return message.warning('상품 노출 순서를 입력해주세요.');

        let img = logo;
        if (typeof logo !== 'string') {
            const formData = new FormData();
            formData.append('photo', logo);
            const header = { headers: { 'Content-Type': 'multipart/form-data' } };
            const { data: image } = await axios.post('/v1/common/image/upload', formData, header);
            img = image;
        }

        const { data } = await axios.put('/v1/product/edit/service', {
            id: this.state.id,
            info: {
                ...this.state,
                logo: img,
            },
        });
        if (!data) return message.error('죄송합니다. 일시적인 서버 장애가 발생하였습니다.');
        if (data === 'check parameters') return message.warning('필수 정보들을 입력해주세요.');
        if (data === 'not exist product') return message.warning('존재하지 않는 상품입니다.');
        if (data === 'success') {
            message.success('상품 업데이트 완료');
            setTimeout(() => {
                this.props.history.goBack();
            }, 600);
        }
    };

    render() {
        console.log(this.state);

        return (
            <>
                <Title title='서비스 정보 및 관리' />
                <TableWrapper>
                    <TableContainer>
                        {this.state && this.state.id && (
                            <>
                                <ValueBox>
                                    <Select
                                        value={this.state.type || undefined}
                                        style={{ width: '400px' }}
                                        onChange={value => {
                                            this.setState({
                                                type: value,
                                                useBasic: false,
                                                usePro: false,
                                                basicPrice: null,
                                                basicDiscount: null,
                                                proPrice: null,
                                                proDiscount: null,
                                            });
                                        }}
                                    >
                                        <Select.Option value='normal'>기본 (자유 설정)</Select.Option>
                                        <Select.Option value='starting'>출발지 고정 (출발지 설정 필수)</Select.Option>
                                        <Select.Option value='destination'>목적지 고정 (목적지 설정 필수)</Select.Option>
                                    </Select>
                                </ValueBox>

                                <ValueBox>
                                    <Label>상품명</Label>
                                    <Input placeholder='상품명을 입력해주세요.' value={this.state.name} name='name' onChange={this.handleChange} />
                                </ValueBox>

                                <ValueBox>
                                    <Label>로고</Label>
                                    <input
                                        type='file'
                                        accept='image/*'
                                        placeholder='로고를 추가해주세요.'
                                        name='logo'
                                        onChange={ev => {
                                            console.log(ev.target);
                                            console.log(ev.target.files);
                                            this.setState({ logo: ev.target.files && ev.target.files.length > 0 ? ev.target.files[0] : '' });
                                        }}
                                    />
                                    {this.state.logo && (
                                        <div>
                                            <img
                                                style={{ width: '50px', height: '50px', objectFit: 'contain' }}
                                                src={typeof this.state.logo === 'string' ? this.state.logo : URL.createObjectURL(this.state.logo)}
                                                alt='로고'
                                            />
                                        </div>
                                    )}
                                </ValueBox>

                                <ValueBox>
                                    <Label>설명</Label>
                                    <Input
                                        placeholder='상품 관련 설명을 입력해주세요.'
                                        value={this.state.description}
                                        name='description'
                                        onChange={this.handleChange}
                                    />
                                </ValueBox>

                                {this.state.type && this.state.type === 'starting' && (
                                    <ValueBox>
                                        <Label>고정 출발지</Label>
                                        <Input
                                            placeholder='고정 출발지를 입력해주세요.'
                                            value={this.state.reservedStarting}
                                            name='reservedStarting'
                                            onChange={this.handleChange}
                                        />
                                    </ValueBox>
                                )}

                                {this.state.type && this.state.type === 'starting' && (
                                    <ValueBox>
                                        <Label>짧은 고정 출발지 (~동)</Label>
                                        <Input
                                            placeholder='짧은 고정 출발지를 입력해주세요.'
                                            value={this.state.reservedShortStarting}
                                            name='reservedShortStarting'
                                            onChange={this.handleChange}
                                        />
                                    </ValueBox>
                                )}

                                {this.state.type && this.state.type === 'destination' && (
                                    <ValueBox>
                                        <Label>고정 목적지</Label>
                                        <Input
                                            placeholder='고정 목적지 입력해주세요.'
                                            value={this.state.reservedDestination}
                                            name='reservedDestination'
                                            onChange={this.handleChange}
                                        />
                                    </ValueBox>
                                )}

                                {this.state.type && this.state.type === 'destination' && (
                                    <ValueBox>
                                        <Label>짧은 고정 목적지</Label>
                                        <Input
                                            placeholder='짧은 고정 목적지 입력해주세요.'
                                            value={this.state.reservedShortDestination}
                                            name='reservedShortDestination'
                                            onChange={this.handleChange}
                                        />
                                    </ValueBox>
                                )}

                                <ValueBox>
                                    <Label>지원 상품</Label>

                                    <Checkbox
                                        checked={this.state.useBasic}
                                        name='useBasic'
                                        onChange={ev => {
                                            this.setState({ useBasic: ev.target.checked });
                                        }}
                                    >
                                        Basic
                                    </Checkbox>
                                    <Checkbox
                                        checked={this.state.usePro}
                                        name='usePro'
                                        onChange={ev => {
                                            this.setState({ usePro: ev.target.checked });
                                        }}
                                    >
                                        Pro
                                    </Checkbox>
                                </ValueBox>
                                {!this.state.useBasic ? null : (
                                    <>
                                        <ValueBox>
                                            <Label>Basic 가격 (시간당)</Label>
                                            <Input
                                                type='number'
                                                placeholder='ex. 13000'
                                                value={this.state.basicPrice}
                                                name='basicPrice'
                                                onChange={this.handleChange}
                                            />
                                        </ValueBox>

                                        <ValueBox>
                                            <Label>Basic 할인액 (총액)</Label>
                                            <Input
                                                type='number'
                                                placeholder='ex. 500'
                                                value={this.state.basicDiscount}
                                                name='basicDiscount'
                                                onChange={this.handleChange}
                                            />
                                        </ValueBox>
                                    </>
                                )}

                                {!this.state.usePro ? null : (
                                    <>
                                        <ValueBox>
                                            <Label>Pro 가격 (시간당)</Label>
                                            <Input
                                                type='number'
                                                placeholder='ex. 170000'
                                                value={this.state.proPrice}
                                                name='proPrice'
                                                onChange={this.handleChange}
                                            />
                                        </ValueBox>

                                        <ValueBox>
                                            <Label>Pro 할인액 (총액)</Label>
                                            <Input
                                                type='number'
                                                placeholder='ex. 1000'
                                                value={this.state.proDiscount}
                                                name='proDiscount'
                                                onChange={this.handleChange}
                                            />
                                        </ValueBox>
                                    </>
                                )}

                                <ValueBox>
                                    <Label>노출 순서</Label>
                                    <Input
                                        type='number'
                                        placeholder='숫자가 작은순서대로 노출됩니다 (1 ~ N)'
                                        value={this.state.listOrder}
                                        name='listOrder'
                                        onChange={this.handleChange}
                                    />
                                </ValueBox>

                                <ValueBox>
                                    <Label>상품 분류 태그 (태그별로 그룹핑할 수 있습니다)</Label>
                                    <Input
                                        type='text'
                                        placeholder='태그명을 입력해주세요'
                                        value={this.state.tagging}
                                        name='tagging'
                                        onChange={this.handleChange}
                                        onPressEnter={() => {
                                            if (!this.state.tagging) return null;

                                            this.setState({
                                                tag: this.state.tag.concat([this.state.tagging]),
                                                tagging: '',
                                            });
                                        }}
                                    />
                                    <h5 style={{ color: 'red' }}>
                                        {(this.state.tag && this.state.tag.length > 0 && this.state.tag.join(', ')) || '태그 없음'}
                                    </h5>
                                    <Button
                                        type='default'
                                        onClick={() => {
                                            this.setState({ tagging: '', tag: [] });
                                        }}
                                    >
                                        태그 재설정
                                    </Button>
                                </ValueBox>

                                <ValueBox>
                                    <Label>상품 운영 on/off</Label>
                                    <Radio.Group
                                        value={this.state.valid}
                                        onChange={ev => {
                                            this.setState({ valid: ev.target.value });
                                        }}
                                    >
                                        <Radio value={true}>ON</Radio>
                                        <Radio value={false}>OFF</Radio>
                                    </Radio.Group>
                                </ValueBox>

                                <ValueBox>
                                    <Button type='primary' onClick={this.onUpdate}>
                                        현재 상태로 변경 및 저장
                                    </Button>
                                </ValueBox>
                            </>
                        )}
                    </TableContainer>
                </TableWrapper>
            </>
        );
    }
}
