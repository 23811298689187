import React from 'react';
import { connect } from 'react-redux';
import StyledCardListContainer from './list.styled';
import CardList from './list';
import Title from '../../../Components/Common/pageTitle';

import { getCardList, getReserveList } from '../../../Reducer/Modules/app';
import { editServicePage } from '../../../Reducer/Modules/service';

class CardListPage extends React.PureComponent {
    componentDidMount = () => {
        this.props.fetchCardList();
        this.props.fetchReserveList();
    };

    render() {
        return (
            <StyledCardListContainer>
                <Title title='고객 결제카드 목록' />
                <CardList {...this.props} />
            </StyledCardListContainer>
        );
    }
}
const mapStateToProps = ({ app, auth, service }) => ({
    app,
    auth,
    service
});

const mapDispatchToProps = dispatch => ({
    fetchCardList: () => dispatch(getCardList()),
    fetchReserveList: () => dispatch(getReserveList()),
    editPage: (page) => dispatch(editServicePage(page))
});

export default connect(mapStateToProps, mapDispatchToProps)(CardListPage);
