import React from 'react';
import ReactJson from 'react-json-view';
import moment from 'moment';
import { Button, Modal, Input, Select, message } from 'antd';
import _ from 'lodash';

import { InfoBlock } from '../../../Components/Common/block';
import { UNEMER, DEV } from '../../../Lib/variables';
import axios from '../../../Lib/axios';

class ServiceItem extends React.PureComponent {
    state = {
        modal: false,
        refundInfo: {},
        rewardModal: false,
        rewardInfo: {},
        selectedMate: DEV ? '5f295dca75c4130aa089c210' : '5fa6535e11c62f2c5b5b8cf5',
        reason: '',
    };

    onSetMate = async () => {
        const { info: reserveInfo } = this.props.location.state;

        const { data } = await axios.put('/v1/partner/confirm/reserve', {
            mateId: this.state.selectedMate,
            callId: reserveInfo.callId,
        });

        if (data === 'success') {
            message.success('완료');
            setTimeout(() => {
                this.props.history.goBack();
            }, 1000);
        }
    };

    reserveDeny = async () => {
        const { info: reserveInfo } = this.props.location.state;

        const { data } = await axios.put('/v1/partner/deny/reserve', {
            reason: this.state.reason,
            callId: reserveInfo.callId,
            reserveId: reserveInfo._id,
        });

        if (data === 'success') {
            message.success('완료');
            setTimeout(() => {
                this.props.history.goBack();
            }, 1000);
        }
    };

    render() {
        const { info: reserveInfo } = this.props.location.state;
        console.log(reserveInfo);
        const overtime = reserveInfo.overtime && reserveInfo.overtime.over ? reserveInfo.overtime.over : 0;
        const status = reserveInfo && !_.isEmpty(reserveInfo.call) ? reserveInfo.call[0].status : '-';

        if (_.isEmpty(reserveInfo.call) || _.isEmpty(reserveInfo.call[0].reserveId))
            return (
                <div className='container'>
                    <div>
                        <p>업데이트 전 발생한 비정상적인 접수내역입니다.(~ 2.10)</p>
                    </div>
                </div>
            );

        return (
            <div className='container'>
                <div className='table'>
                    <InfoBlock label='예약번호' value={reserveInfo && reserveInfo.reserveCode ? reserveInfo.reserveCode : '-'} />
                    <InfoBlock label='진행상태' value={status} />
                    <InfoBlock
                        label='서비스 타입'
                        value={
                            reserveInfo.service === UNEMER
                                ? '병원 동행 Pro'
                                : reserveInfo.service === 'assist'
                                ? '병원 동행 Basic'
                                : reserveInfo.service === 'happycare'
                                ? '해피케어 서비스'
                                : '-'
                        }
                    />
                    <InfoBlock label='담당 메이트' value={_.isEmpty(reserveInfo.call[0].mate) ? '-' : reserveInfo.call[0].mate[0].name} />
                    <InfoBlock label='예약자 성명' value={reserveInfo.user} />
                    <InfoBlock label='예약자 연락처' value={reserveInfo.contact} />
                    <InfoBlock label='환자' value={reserveInfo.patient} />
                    <InfoBlock label='병명' value={reserveInfo.sick || '-'} />
                    <InfoBlock label='성별' value={reserveInfo.sex || '-'} />
                    <InfoBlock label='총 예약시간' value={reserveInfo.usageTime + '시간'} />
                    <InfoBlock label='병원위치' value={reserveInfo.starting} />
                    <InfoBlock label='자택주소' value={reserveInfo.destination || '-'} />
                    <InfoBlock
                        label='이동 수단'
                        value={
                            reserveInfo.transport === 'withcar'
                                ? '차량호출'
                                : reserveInfo.transport === 'own'
                                ? '자차 이용'
                                : '대중교통 이용 (혹은 미선택 - 구버전)'
                        }
                    />
                    <InfoBlock label='사후 리포트' value={reserveInfo.report ? '사후 리포트 요청' : '요청 안함'} />
                    <InfoBlock label='비고' value={reserveInfo.comment || '-'} />
                    <InfoBlock label='예약일자' value={moment(reserveInfo.reserveDate).format('YYYY. MM. DD - hh:mm A')} />

                    <div className='button-wrapper'>
                        <div>
                            <Select style={{ width: '100%', marginBottom: '12px' }} value={this.state.selectedMate}>
                                <Select.Option value={this.state.selectedMate}>위트메이트 담당자</Select.Option>
                            </Select>
                        </div>
                        <Button style={{ backgroundColor: '#3bc1ff', color: 'white', marginRight: '20px' }} onClick={this.onSetMate}>
                            서비스 담당자 지정과 함께 접수완료처리{' '}
                        </Button>

                        <p style={{ fontSize: '12px', marginTop: '28px' }}>※ 주의. 최종 선택한 버튼에 따라 상태가 결정됩니다.</p>
                    </div>
                    <div style={{ marginTop: '40px' }}>
                        <Input
                            type='text'
                            placeholder='예약 불가 사유를 입력해주세요.'
                            value={this.state.reason}
                            onChange={ev => {
                                this.setState({ reason: ev.target.value });
                            }}
                        />
                        <div>
                            [예약 취소 알림]
                            <br />
                            고객님께서 접수하신 해피케어 서비스가 {this.state.reason}으로 인해 취소되었습니다.
                            <br />
                            서비스 이용에 불편을 드려 대단히 죄송합니다.
                        </div>
                        <Button type='primary' onClick={this.reserveDeny}>
                            예약 불가
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}
export default ServiceItem;
