import React from 'react';
import { connect } from 'react-redux';
import StyledEventListContainer from './list.styled';
import EventList from './list';
import Title from '../../../Components/Common/pageTitle';

import { getEventList } from '../../../Reducer/Modules/event';
import { editServicePage } from '../../../Reducer/Modules/service';

class EventListPage extends React.PureComponent {
    componentDidMount = () => {
        this.props.fetchEventList();
    };

    render() {
        return (
            <StyledEventListContainer>
                <Title title='이벤트 신청 목록' />
                <EventList {...this.props} />
            </StyledEventListContainer>
        );
    }
}
const mapStateToProps = ({ app, auth, service, event }) => ({
    app,
    auth,
    service,
    event,
});

const mapDispatchToProps = dispatch => ({
    fetchEventList: () => dispatch(getEventList()),
    editPage: page => dispatch(editServicePage(page)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EventListPage);
